import { GetAddedCardFunc, ProductFees } from "../../../payment";
import { getStripe, PaymentType } from "../../../../common/payment";
import { fetchProductFees } from "../../../payment/api/fetchProductFees";
import moment from "moment";
import { getCardDisplayName } from "../../../payment/util/getCardDisplayName";
import {
    StripeCardCvcElement,
    StripeCardExpiryElement,
    StripeCardNumberElement,
    StripeCardNumberElementChangeEvent,
    StripeElementChangeEvent,
} from "@stripe/stripe-js";
import {
    getStripeAddedCardForPaymentOperation,
    getStripeAddedCardOperation,
    showStripeAddCardOperation,
} from "../operations";
import { timeoutAndError } from "../../../../common/shared";
import { HostedField, hostedFieldOptions } from "../../../paymentMethods/types";
import { stripeElementOptions, StripeHostedField, StripeNewCardPaymentMethod } from "../types";
import { ShowAddCardFunc } from "../../types";
import { registerKeyboardClosed, registerKeyboardOpen } from "../../../../common/keyboard";
import { fetchPaymentMethodSessionToken } from "../../../paymentMethods/api/fetchPaymentMethodSessionToken";
import { setHostedFieldValidity } from "../../../paymentMethods/actions/setHostedFieldValidity";

export const showStripeAddCard: ShowAddCardFunc = (dispatch, newCardPaymentMethod, inParty) => {
    return showStripeAddCardOperation.invoke(async () => {
        const { apiKey } = newCardPaymentMethod as StripeNewCardPaymentMethod;

        const initializeHostedFields = async () => {
            const stripe = await getStripe(apiKey);
            const elements = stripe.elements();

            const cardNumberElement: StripeCardNumberElement = elements.create(StripeHostedField.Number, {
                style: stripeElementOptions.style,
                placeholder: hostedFieldOptions.placeholders.cardNumber,
            });

            const cardExpiryElement: StripeCardExpiryElement = elements.create(StripeHostedField.Expiry, {
                style: stripeElementOptions.style,
                placeholder: hostedFieldOptions.placeholders.expiry,
            });

            const cardCvcElement: StripeCardCvcElement = elements.create(StripeHostedField.CVV, {
                style: stripeElementOptions.style,
                placeholder: hostedFieldOptions.placeholders.cvv,
            });

            let lastBrand = "unknown";
            cardNumberElement.on("change", ({ complete, error, brand }: StripeCardNumberElementChangeEvent) => {
                dispatch(setHostedFieldValidity(HostedField.Number, complete, !!error));
                if (brand !== lastBrand) {
                    if (brand === "amex") {
                        cardCvcElement.update({ placeholder: hostedFieldOptions.placeholders.cvvAmex });
                    } else {
                        cardCvcElement.update({ placeholder: hostedFieldOptions.placeholders.cvv });
                    }
                    lastBrand = brand;
                }
            });

            cardExpiryElement.on("change", ({ complete, error }: StripeElementChangeEvent) =>
                dispatch(setHostedFieldValidity(HostedField.Expiry, complete, !!error))
            );

            cardCvcElement.on("change", ({ complete, error }: StripeElementChangeEvent) =>
                dispatch(setHostedFieldValidity(HostedField.CVV, complete, !!error))
            );

            const mountCardNumberElement = () =>
                new Promise<unknown>((resolve) => {
                    cardNumberElement.on("ready", resolve);
                    cardNumberElement.mount(`#${HostedField.Number}`);
                });

            const mountCardExpiryElement = () =>
                new Promise<unknown>((resolve) => {
                    cardExpiryElement.on("ready", resolve);
                    cardExpiryElement.mount(`#${HostedField.Expiry}`);
                });

            const mountCardCvcElement = () =>
                new Promise<unknown>((resolve) => {
                    cardCvcElement.on("ready", resolve);
                    cardCvcElement.mount(`#${HostedField.CVV}`);
                });

            await Promise.all([mountCardNumberElement(), mountCardExpiryElement(), mountCardCvcElement()]);

            cardNumberElement.on("focus", registerKeyboardOpen);
            cardNumberElement.on("blur", registerKeyboardClosed);

            cardExpiryElement.on("focus", registerKeyboardOpen);
            cardExpiryElement.on("blur", registerKeyboardClosed);

            cardCvcElement.on("focus", registerKeyboardOpen);
            cardCvcElement.on("blur", registerKeyboardClosed);

            return {
                stripe,
                cardNumberElement,
            };
        };

        const { stripe, cardNumberElement } = await Promise.race([
            initializeHostedFields(),
            timeoutAndError(hostedFieldOptions.initializeTimeout, "Timed out initializing Stripe hosted fields"),
        ]);

        const getAddedCard: GetAddedCardFunc = (isPayment: boolean) =>
            (isPayment ? getStripeAddedCardForPaymentOperation : getStripeAddedCardOperation).invoke(async () => {
                if (!isPayment) {
                    const clientSecret = await fetchPaymentMethodSessionToken(newCardPaymentMethod);

                    const result = await stripe.confirmCardSetup(clientSecret, {
                        payment_method: {
                            card: cardNumberElement,
                        },
                    });

                    if (result.error) {
                        throw new Error(result.error.message);
                    }

                    if (!result.setupIntent.payment_method) {
                        throw new Error("No payment method");
                    }

                    return {
                        paymentMethod: {
                            ...newCardPaymentMethod,
                            paymentType: PaymentType.ADDEDCARD,
                            displayName: "",
                            token: result.setupIntent.payment_method,
                            maskedNumber: "",
                            expirationDate: "",
                            productId: "",
                            processingFee: 0,
                            processingFeeBaseAmount: 0,
                            remember: false,
                        },
                    };
                }

                const result = await stripe.createPaymentMethod({
                    type: "card",
                    card: cardNumberElement,
                });

                if (result.error) {
                    throw new Error(result.error.message);
                }

                const { id, card } = result.paymentMethod;
                const { brand, exp_month, exp_year, last4, funding, country } = card!;

                const expiry = moment(`${exp_month}-${exp_year}`, "M-YYYY");

                // Convert brand/funding to the me&u internal product id so we can retrieve processing fees
                let productId = brand.toUpperCase();
                if (funding?.toUpperCase() === "DEBIT") {
                    productId += "-DEBIT";
                }

                let productFees: ProductFees | undefined;
                if (isPayment) {
                    productFees = await fetchProductFees(
                        dispatch,
                        newCardPaymentMethod.paymentGateway,
                        productId,
                        newCardPaymentMethod.currency,
                        country,
                        inParty
                    );
                }

                return {
                    paymentMethod: {
                        ...newCardPaymentMethod,
                        paymentType: PaymentType.ADDEDCARD,
                        displayName: getCardDisplayName(brand),
                        token: id,
                        maskedNumber: `•••• ${last4}`,
                        expirationDate: expiry.format("MM/YY"),
                        productId,
                        processingFee: productFees?.feePercentage ?? 0,
                        processingFeeBaseAmount: productFees?.feeBaseAmount ?? 0,
                        remember: false,
                    },
                };
            }, dispatch);

        return getAddedCard;
    }, dispatch);
};
