import { AppDispatch, AppState } from "../..";
import { createGroupTabOperation } from "../operations";
import {
    ActiveGroupTabData,
    GroupTabErrorType,
    IncreaseGroupTabInfo,
    OpenGroupTabInfo,
    UpdateTabLimitInfo,
} from "../types";
import { actionCreators as wizardActionCreators } from "../reducers/groupTabWizard";
import { actionCreators as activeTabActionCreators } from "../reducers/activeGroupTab";
import { actionCreators as paymentActionCreators } from "../../payment/reducers";
import { ProblemDetailsError } from "src/features/order/orderApi/ProblemDetailError";
import { modalMessages } from "src/features/modalMessage/messages";
import { showModalMessage } from "src/features/modalMessage/actions/show";
import { getPaymentInfo } from "../../payment/actions/handlePayment";
import { resetModalMessage } from "../../modalMessage/actions/reset";
import { beginOpenOrUpdateGroupTabApi, openOrUpdateGroupTabApi } from "../API/openOrUpdateGroupTabApi";
import { fetchProfile } from "../../accountmenu";
import { paymentGatewayBehaviours } from "../../paymentGateways";
import { getParty } from "../../order";
import { getActiveGroupTabData, getTabTypeName } from "../selectors/activeGroupTab";
import { getGroupTabsWizard, getMenuPackageByWizardId } from "../selectors/groupTabWizardSelectors";
import { PaymentFunc } from "../../payment";
import { HandledError } from "../../../common/error";

export const openOrUpdateGroupTab = async (
    dispatch: AppDispatch,
    getState: () => AppState,
    groupTabInfo: OpenGroupTabInfo | IncreaseGroupTabInfo | UpdateTabLimitInfo
) => {
    const state = getState();
    const party = getParty(state);
    const activeGroupTab = getActiveGroupTabData(state);

    if ("paymentGateway" in groupTabInfo) {
        const { paymentBehaviour } = paymentGatewayBehaviours[groupTabInfo.paymentGateway];

        if (paymentBehaviour?.handleExternalPayment && !party?.isDemo && !activeGroupTab?.isDemo) {
            const beginPaymentResult = await beginOpenOrUpdateGroupTabApi(groupTabInfo, !!party);

            groupTabInfo.externalPaymentInfo = {
                paymentId: beginPaymentResult.paymentId,
            };

            const externalPaymentResult = await paymentBehaviour.handleExternalPayment(
                dispatch,
                getState,
                beginPaymentResult
            );

            // Used to test scenarios where a Stripe payment completes but the connection
            // is interrupted before payAndSubmitOrderInSequence can be called
            if ("debugDelayExternalPayment" in window && window["debugDelayExternalPayment"] === true) {
                window.alert("External payment complete");
            }

            if (externalPaymentResult) {
                groupTabInfo.externalPaymentInfo = {
                    ...groupTabInfo.externalPaymentInfo,
                    ...externalPaymentResult,
                };
            } else {
                groupTabInfo.externalPaymentInfo.error = {
                    code: "app_error",
                    isConsumerFriendly: false,
                };
            }
        }
    }

    return await openOrUpdateGroupTabApi(groupTabInfo, !!party);
};

export const createGroupTabAction: PaymentFunc = (result, completePayment, validate) => {
    return createGroupTabOperation.getThunk(async (dispatch: AppDispatch, getState: () => AppState) => {
        dispatch(resetModalMessage());
        const state = getState();
        const { tabName, tabLimit, packageId, tabType } = getGroupTabsWizard(state);
        const tabTypeName = getTabTypeName(state);

        const packageDetails = getMenuPackageByWizardId(state);

        try {
            result = await validate(result);

            const getOpenGroupTabInfo = async () => {
                const paymentInfo = await getPaymentInfo(dispatch, getState, result);

                const openGroupTabInfo: OpenGroupTabInfo = {
                    ...paymentInfo,
                    tabName: tabName!,
                    limit: tabLimit!,
                    packageId,
                    packageName: packageDetails?.displayName,
                    type: tabType,
                };

                return openGroupTabInfo;
            };

            let openGroupTabInfo = await getOpenGroupTabInfo();
            let groupTab: ActiveGroupTabData;

            try {
                groupTab = await openOrUpdateGroupTab(dispatch, getState, openGroupTabInfo);
            } catch (err) {
                if (
                    !(err instanceof ProblemDetailsError) ||
                    err.problemDetails.type !== GroupTabErrorType.MUST_USE_EXTENDED_VALIDATION
                ) {
                    throw err;
                }

                result = await validate(result, true);
                openGroupTabInfo = await getOpenGroupTabInfo();
                groupTab = await openOrUpdateGroupTab(dispatch, getState, openGroupTabInfo);
            }

            dispatch(fetchProfile());
            dispatch(activeTabActionCreators.setGroupTabData(groupTab));
            dispatch(paymentActionCreators.clearPaymentMethods());
            dispatch(wizardActionCreators.completeGroupTabWizard());
            dispatch(activeTabActionCreators.trackGroupTabStarted(groupTab));
        } catch (err) {
            if (err instanceof ProblemDetailsError) {
                if (err.problemDetails.type !== GroupTabErrorType.PAYMENT_FAILED) {
                    dispatch(showModalMessage(modalMessages.knownFailureGroupTab(err.problemDetails, tabTypeName)));
                    throw new HandledError(err.problemDetails.detail);
                }
            } else {
                console.log("failed to create group tab:", err);
            }
            throw err;
        } finally {
            await completePayment?.();
        }
    });
};
