import { AppDispatch } from "src/features";
import { applePay, googlePay } from "src/common/experience";
import { PaymentMethod, PaymentType } from "src/common/payment";
import { initializeCustomGooglePayButtonForVaultOperation, vaultDevicePaymentMethodOperation } from "../operations";
import { fetchPaymentMethodSessionToken } from "src/features/paymentMethods/api/fetchPaymentMethodSessionToken";
import { showModalMessage } from "src/features/modalMessage/actions/show";
import { modalMessages } from "src/features/modalMessage/messages";
import { InitializeDevicePaymentMethodButtonActions } from "src/common/experience/interface";

export async function vaultDevicePaymentMethod(
    paymentMethod: PaymentMethod,
    cancelPromise: Promise<void>,
    dispatch: AppDispatch
) {
    const getPaymentSessionToken = async () => {
        return await fetchPaymentMethodSessionToken(paymentMethod);
    };

    const operation =
        paymentMethod.paymentType === PaymentType.GOOGLEPAY
            ? initializeCustomGooglePayButtonForVaultOperation
            : vaultDevicePaymentMethodOperation;

    return await operation.invoke(async () => {
        try {
            const actions: InitializeDevicePaymentMethodButtonActions = {
                getPaymentSessionToken,
                trackEvent: (action) => vaultDevicePaymentMethodOperation.trackEvent(action, dispatch),
                spinnerActions: {
                    start: () => dispatch(vaultDevicePaymentMethodOperation.actionCreators.begin()),
                    stop: () => dispatch(vaultDevicePaymentMethodOperation.actionCreators.reset()),
                },
            };

            switch (paymentMethod.paymentType) {
                case PaymentType.APPLEPAY:
                    return (
                        (await Promise.race([
                            applePay.vaultPaymentMethod(paymentMethod, actions, cancelPromise),
                            cancelPromise,
                        ])) || null
                    );
                case PaymentType.GOOGLEPAY:
                    return (
                        (await Promise.race([
                            googlePay.vaultPaymentMethod(paymentMethod, actions, cancelPromise),
                            cancelPromise,
                        ])) || null
                    );
                default:
                    return null;
            }
        } catch (err) {
            dispatch(showModalMessage(modalMessages.vaultDeviceCardFailed()));
            return null;
        }
    }, dispatch);
}
