import { Menu } from "../../menudata";
import { Props, MenuCard } from "../components/MenuCard";
import { connect } from "react-redux";
import { getVisibleMenuData } from "../selectors";
import { AppState } from "../..";

export interface OwnProps {
    menuId: string;
    menu: Menu;
    onBeforeNavigate?: () => void;
}

function mapStateToProps(state: AppState, props: OwnProps): Props {
    const { menuId, menu, onBeforeNavigate } = props;

    const categories = menu.categories.map((id) => getVisibleMenuData(state)!.categories[id]);

    return {
        menuId,
        menu,
        categories,
        onBeforeNavigate,
    };
}

export const MenuCardContainer = connect(mapStateToProps)(MenuCard);
