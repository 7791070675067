import { PartySwitchedAction, TypeKeys as PartyTypeKeys } from "../../order/reducers/party";
import { PaymentMethod, PaymentType, SelectablePaymentMethod } from "../../../common/payment";
import { OperationCompletedAction } from "../../operations";
import { AddedCardResult, TipType } from "../types";
import { trackedEvent } from "src/common/events/reduxEventTracking";
import { StartPaymentFlowAction, TypeKeys as PaymentFlowTypeKeys } from "./paymentFlow";

export interface CustomTip {
    tipAmount?: number;
    displayTipAmount?: string;
    tipType: TipType;
}

export interface OpenTabPaymentMethodInfo {
    paymentMethod: SelectablePaymentMethod;
    additionalFraudProtectionData?: string;
}

export interface PaymentState {
    tipLevel: number | null;
    customTip?: CustomTip;
    paymentMethods: PaymentMethod[] | null;
    addedCard: AddedCardResult | null;
    selectedPaymentMethod: SelectablePaymentMethod | null;
    addCardFormActive: boolean;
    preselectedPaymentMethod: PaymentMethod | null;
    openTabInfo: OpenTabPaymentMethodInfo | null;
    showTipModal: boolean;
    showSecuredPaymentModal: boolean;
}

export enum TypeKeys {
    UPDATE_TIP_LEVEL = "PARTY/UPDATE_TIP_LEVEL",
    CUSTOM_TIP_AMOUNT = "PARTY/CUSTOM_TIP_AMOUNT",
    RESET_CUSTOM_TIP_AMOUNT = "PARTY/REST_CUSTOM_TIP_AMOUNT",
    CLEAN_CUSTOM_TIP_SYMBOL = "PARTY/CLEAN_CUSTOM_TIP_SYMBOL",
    RESET_TIPS = "PARTY/RESET_TIPS",
    GET_PAYMENT_METHODS_COMPLETE = "PARTY/GET_PAYMENT_METHODS_COMPLETE",
    CLEAR_PAYMENT_METHODS = "PARTY/CLEAR_PAYMENT_METHODS",
    ADD_CARD = "PARTY/ADD_CARD",
    CLEAR_ADDED_CARD = "PARTY/CLEAR_ADDED_CARD",
    SELECT_PAYMENT_METHOD = "PARTY/SELECT_PAYMENT/METHOD",
    PRESELECT_PAYMENT_METHOD = "PARTY/PRESELECT_PAYMENT/METHOD",
    SET_ADD_CARD_FORM_ACTIVE = "PARTY/SET_ADD_CARD_FORM_ACTIVE",
    PAYMENT_METHOD_SELECTED = "PARTY/PAYMENT_METHOD_SELECTED",
    TIP_SELECTED = "PARTY/TIP_SELECTED",
    SET_OPEN_TAB_PAYMENT_METHOD = "PARTY/SET_OPEN_TAB_PAYMENT_METHOD",
    REMOVE_GROUP_TAB_PAYMENT_METHOD = "PARTY/REMOVE_GROUP_TAB_PAYMENT_METHOD",
    SHOW_TIP_MODAL = "PARTY/SHOW_TIP_MODAL",
    SHOW_SECURED_PAYMENT_MODAL = "PARTY/SHOW_SECURED_PAYMENT_MODAL",
}

export const actionCreators = {
    updateTipLevel: (tipLevel: number) => ({ type: TypeKeys.UPDATE_TIP_LEVEL, tipLevel }),
    setCustomTipAmount: (tipType: TipType, tipAmount?: number, displayTipAmount?: string) => ({
        type: TypeKeys.CUSTOM_TIP_AMOUNT,
        tipType,
        tipAmount,
        displayTipAmount,
    }),
    cleanCustomTipSymbol: () => ({ type: TypeKeys.CLEAN_CUSTOM_TIP_SYMBOL }),
    resetCustomTipAmount: () => ({ type: TypeKeys.RESET_CUSTOM_TIP_AMOUNT }),
    resetTips: () => ({ type: TypeKeys.RESET_TIPS }),
    getPaymentMethodsComplete: (paymentMethods: PaymentMethod[]) => ({
        type: TypeKeys.GET_PAYMENT_METHODS_COMPLETE,
        paymentMethods,
    }),
    clearPaymentMethods: () => ({ type: TypeKeys.CLEAR_PAYMENT_METHODS }),
    selectPaymentMethod: (paymentMethod: SelectablePaymentMethod | null) => ({
        type: TypeKeys.SELECT_PAYMENT_METHOD,
        paymentMethod,
    }),
    preselectPaymentMethod: (paymentMethod: PaymentMethod | null) => ({
        type: TypeKeys.PRESELECT_PAYMENT_METHOD,
        paymentMethod,
    }),
    addCard: (addedCard: AddedCardResult) => ({ type: TypeKeys.ADD_CARD, addedCard }),
    clearAddedCard: () => ({ type: TypeKeys.CLEAR_ADDED_CARD }),
    setAddCardFormActive: (active: boolean) => ({ type: TypeKeys.SET_ADD_CARD_FORM_ACTIVE, active }),
    setOpenTabPaymentMethod: (paymentMethod: SelectablePaymentMethod) => ({
        type: TypeKeys.SET_OPEN_TAB_PAYMENT_METHOD,
        paymentMethod,
    }),
    removeGroupTabPaymentMethod: () => ({ type: TypeKeys.REMOVE_GROUP_TAB_PAYMENT_METHOD }),
    trackTipSelected: (tip_amount: number, tip_percentage: number, customTip: boolean, tipType?: TipType) =>
        trackedEvent({
            type: TypeKeys.TIP_SELECTED,
            tip_amount,
            tip_percentage,
            tip_selected: customTip ? "custom" : "predefined",
            tip_type: TipType[tipType ?? TipType.PERCENTAGE].toLowerCase(),
        }),
    showTipModal: (show: boolean) => ({ type: TypeKeys.SHOW_TIP_MODAL, show }),
    showSecuredPaymentModal: (show: boolean) => ({ type: TypeKeys.SHOW_SECURED_PAYMENT_MODAL, show }),
};

export type UpdateTipLevelAction = { type: TypeKeys.UPDATE_TIP_LEVEL; tipLevel: number };
export type SetCustomTipAmountAction = {
    type: TypeKeys.CUSTOM_TIP_AMOUNT;
    tipType: TipType;
    tipAmount?: number;
    displayTipAmount?: string;
};
export type CleanCustomTipSymbolAction = { type: TypeKeys.CLEAN_CUSTOM_TIP_SYMBOL };
export type ResetCustomTipAmountAction = { type: TypeKeys.RESET_CUSTOM_TIP_AMOUNT };
export type ResetTipsAction = { type: TypeKeys.RESET_TIPS };
export type GetPaymentMethodsCompleteAction = {
    type: TypeKeys.GET_PAYMENT_METHODS_COMPLETE;
    paymentMethods: PaymentMethod[];
};
export type ClearPaymentMethodsAction = { type: TypeKeys.CLEAR_PAYMENT_METHODS };
export type SelectPaymentMethodAction = {
    type: TypeKeys.SELECT_PAYMENT_METHOD;
    paymentMethod: SelectablePaymentMethod | null;
};
export type PreselectPaymentMethodAction = {
    type: TypeKeys.PRESELECT_PAYMENT_METHOD;
    paymentMethod: PaymentMethod | null;
};
export type AddCardAction = { type: TypeKeys.ADD_CARD; addedCard: AddedCardResult };
export type ClearAddedCardAction = { type: TypeKeys.CLEAR_ADDED_CARD };
export type SetAddCardFormActiveAction = { type: TypeKeys.SET_ADD_CARD_FORM_ACTIVE; active: boolean };
export type SetOpenTabPaymentMethodAction = {
    type: TypeKeys.SET_OPEN_TAB_PAYMENT_METHOD;
    paymentMethod: SelectablePaymentMethod;
};
export type RemoveGroupTabPaymentMethodAction = { type: TypeKeys.REMOVE_GROUP_TAB_PAYMENT_METHOD };
export type ShowTipModalAction = { type: TypeKeys.SHOW_TIP_MODAL; show: boolean };
export type ShowSecuredPaymentModal = { type: TypeKeys.SHOW_SECURED_PAYMENT_MODAL; show: boolean };

export type TotalTableAction =
    | UpdateTipLevelAction
    | CleanCustomTipSymbolAction
    | SetCustomTipAmountAction
    | ResetCustomTipAmountAction
    | ResetTipsAction
    | GetPaymentMethodsCompleteAction
    | ClearPaymentMethodsAction
    | SelectPaymentMethodAction
    | PreselectPaymentMethodAction
    | PartySwitchedAction
    | AddCardAction
    | ClearAddedCardAction
    | SetAddCardFormActiveAction
    | SetOpenTabPaymentMethodAction
    | RemoveGroupTabPaymentMethodAction
    | OperationCompletedAction
    | StartPaymentFlowAction
    | ShowTipModalAction
    | ShowSecuredPaymentModal;

const initialState: PaymentState = {
    tipLevel: null,
    paymentMethods: null,
    selectedPaymentMethod: null,
    addedCard: null,
    addCardFormActive: false,
    preselectedPaymentMethod: null,
    openTabInfo: null,
    showTipModal: false,
    showSecuredPaymentModal: false,
};

export function reducer(state: PaymentState = initialState, action: TotalTableAction): PaymentState {
    if (action.type === TypeKeys.UPDATE_TIP_LEVEL) {
        return {
            ...state,
            tipLevel: action.tipLevel,
            customTip: undefined,
        };
    }

    if (action.type === TypeKeys.CUSTOM_TIP_AMOUNT) {
        if (action.tipType !== state.customTip?.tipType) {
            return {
                ...state,
                customTip: {
                    tipAmount: action.tipAmount,
                    displayTipAmount: action.tipAmount?.toString(),
                    tipType: action.tipType,
                },
            };
        }
        return {
            ...state,
            customTip: {
                tipAmount: action.tipAmount,
                displayTipAmount: action.displayTipAmount,
                tipType: action.tipType,
            },
        };
    }

    if (action.type === TypeKeys.CLEAN_CUSTOM_TIP_SYMBOL && state.customTip) {
        return {
            ...state,
            customTip: {
                ...state.customTip,
                displayTipAmount: state.customTip.tipAmount?.toString(),
            },
        };
    }

    if (action.type === TypeKeys.RESET_CUSTOM_TIP_AMOUNT) {
        return {
            ...state,
            customTip: undefined,
        };
    }

    if (action.type === TypeKeys.RESET_TIPS) {
        return {
            ...state,
            tipLevel: null,
            customTip: undefined,
            showTipModal: false,
        };
    }

    if (action.type === TypeKeys.GET_PAYMENT_METHODS_COMPLETE) {
        return {
            ...state,
            paymentMethods: action.paymentMethods,
            selectedPaymentMethod: null,
            addedCard: null,
            openTabInfo: null,
        };
    }

    if (action.type === TypeKeys.CLEAR_PAYMENT_METHODS) {
        return {
            ...state,
            paymentMethods: null,
            selectedPaymentMethod: null,
            addedCard: null,
            openTabInfo: null,
        };
    }

    if (action.type === TypeKeys.SELECT_PAYMENT_METHOD) {
        return {
            ...state,
            selectedPaymentMethod: action.paymentMethod,
        };
    }

    if (action.type === TypeKeys.PRESELECT_PAYMENT_METHOD) {
        return {
            ...state,
            preselectedPaymentMethod: action.paymentMethod,
        };
    }

    if (action.type === PartyTypeKeys.SWITCHED && action.previousParty?.id !== action.party.id) {
        const { tipLevel } = state;
        return {
            ...initialState,
            tipLevel,
        };
    }

    if (action.type === TypeKeys.ADD_CARD) {
        return {
            ...state,
            addedCard: action.addedCard,
            selectedPaymentMethod: action.addedCard.paymentMethod,
        };
    }

    if (action.type === TypeKeys.CLEAR_ADDED_CARD && state.addedCard) {
        return {
            ...state,
            addedCard: null,
            selectedPaymentMethod: null,
            openTabInfo: null,
        };
    }

    if (action.type === TypeKeys.SET_ADD_CARD_FORM_ACTIVE) {
        return {
            ...state,
            addCardFormActive: action.active,
        };
    }

    if (action.type === TypeKeys.SET_OPEN_TAB_PAYMENT_METHOD) {
        return {
            ...state,
            openTabInfo: {
                paymentMethod: action.paymentMethod,
                additionalFraudProtectionData:
                    action.paymentMethod.paymentType === PaymentType.ADDEDCARD
                        ? state.addedCard?.additionalFraudProtectionData
                        : undefined,
            },
            selectedPaymentMethod: action.paymentMethod,
        };
    }

    if (action.type === PaymentFlowTypeKeys.PAYMENT_FLOW_START && state.openTabInfo) {
        return {
            ...state,
            openTabInfo: null,
            selectedPaymentMethod:
                state.selectedPaymentMethod && state.selectedPaymentMethod === state.openTabInfo?.paymentMethod
                    ? null
                    : state.selectedPaymentMethod,
        };
    }

    if (action.type === TypeKeys.REMOVE_GROUP_TAB_PAYMENT_METHOD) {
        return {
            ...state,
            paymentMethods: state.paymentMethods?.filter((p) => p.paymentType !== PaymentType.GROUPTAB) ?? null,
        };
    }

    if (action.type === TypeKeys.SHOW_TIP_MODAL) {
        return {
            ...state,
            showTipModal: action.show,
        };
    }

    if (action.type === TypeKeys.SHOW_SECURED_PAYMENT_MODAL) {
        return {
            ...state,
            showSecuredPaymentModal: action.show,
        };
    }

    return state;
}
