import { ApplePayPaymentMethod } from "../../payment";
import { device } from "./device";
import { AnyAction } from "redux";
import { paymentGatewayBehaviours } from "./paymentGateways/behaviours";
import { InitializeDevicePaymentMethodButtonActions } from "src/common/experience/interface";

export const applePay = {
    isAvailable: async (applePayPaymentMethod: ApplePayPaymentMethod, trackEvent: (action: AnyAction) => void) => {
        if (!device.isIOS) return false;

        const available = await paymentGatewayBehaviours[applePayPaymentMethod.paymentGateway].applePay?.getAvailable(
            applePayPaymentMethod,
            trackEvent
        );

        return !!available;
    },
    initializeButton: (
        applePayPaymentMethod: ApplePayPaymentMethod,
        total: number,
        actions: InitializeDevicePaymentMethodButtonActions,
        cancelPromise: Promise<void>
    ) =>
        paymentGatewayBehaviours[applePayPaymentMethod.paymentGateway].applePay!.initializeButton(
            applePayPaymentMethod,
            total,
            actions,
            cancelPromise
        ),
    vaultPaymentMethod: () => Promise.resolve(null),
    canVaultPaymentMethod: () => false,
    completePayment: (applePayPaymentMethod: ApplePayPaymentMethod, token?: string) =>
        paymentGatewayBehaviours[applePayPaymentMethod.paymentGateway].applePay!.completePayment?.(
            applePayPaymentMethod,
            token
        ) ?? Promise.resolve(),
};
