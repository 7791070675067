import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { orderAgainActions } from "../reducers";
import { Button, Text, TrackScroll } from "src/sharedComponents";
import { MenuItemImage } from "src/features/menuitem";
import { RoundItem } from "../types";
import { DecreaseIcon, IncreaseIcon } from "src/sharedComponents/assets/icons";
import { TappableSpan } from "src/sharedComponents/common/tappable";
import classNames from "classnames";
import { scrolling } from "src/common/experience";
import { getTopPage, SimpleNavHeader } from "src/common/navigation";
import { StatusBar } from "src/common/statusBar";
import "../assets/AnotherRound.scss";
import { OrderCampaigns } from "src/features/order/reducers/types";
import { campaignsActionCreators } from "src/features/analytics/actions";
import { WaitTimeBadge } from "../../menu/components/WaitTimeBadge";
import { getItemAvailability } from "../../menu/util/helpers";
import { addOrUpdateOrderItem } from "../../order/actions/addOrUpdateOrderItem";
import { EnergyContent } from "../../menuitem/components/EnergyContent";
import { ModalContainer } from "src/common/modal";
import { NetworkConnectedButton } from "src/features/notifications/components/NetworkConnectedButton";
import { ImageSet } from "src/features/menudata";
import { getIsOpenTable } from "src/features/openTable/selectors";
import { getAnotherRoundItems } from "../selectors";

const getAvailableItems = (roundItems?: RoundItem[]) => roundItems?.filter((item) => !!item.quantity && item.available);

export const AnotherRound = () => {
    const isOpenTable = useSelector(getIsOpenTable);
    const anotherRound = useSelector(getAnotherRoundItems);
    const [isOpen, setIsOpen] = useState(false);
    const [isAdded, setIsAdded] = useState(false);
    const [showNavbar, setShowNavbar] = useState(false);
    const [localRound, setLocalRound] = useState<RoundItem[] | undefined>();
    const [roundImage, setRoundImage] = useState<ImageSet | undefined>();
    const showHeaderBounce = useRef<number | undefined>();
    const dispatch = useDispatch();

    const onClose = useCallback(() => {
        dispatch(orderAgainActions.resetAnotherRound());
    }, [dispatch]);

    const addToOrder = useCallback(() => {
        getAvailableItems(localRound)?.forEach((item) => {
            dispatch(
                addOrUpdateOrderItem(
                    item.courseId,
                    item.categoryId,
                    item.itemId,
                    item.notes || null,
                    null,
                    item.variant,
                    item.modifiers || undefined,
                    item.quantity,
                    OrderCampaigns.ANOTHER_ROUND
                )
            );
        });
        setIsAdded(true);
        closeModal();
    }, [localRound, dispatch]);

    useEffect(() => {
        const availableItems = getAvailableItems(anotherRound);

        if (!isOpenTable && availableItems?.length) {
            setIsOpen(true);
            setLocalRound(anotherRound);
            setRoundImage(availableItems.find((item) => !!item.images)?.images);

            const trackingData = anotherRound!.reduce((data, item) => {
                const availability = getItemAvailability(item);

                data[item.itemId] = {
                    quantity: item.available ? item.quantity : 0,
                    availability: availability || true,
                };
                return data;
            }, {});

            dispatch(
                campaignsActionCreators.campaignImpression({
                    campaign_name: OrderCampaigns.ANOTHER_ROUND,
                    data: {
                        current_page: getTopPage(),
                        data: JSON.stringify(trackingData),
                    },
                })
            );
        }
    }, [isOpenTable, anotherRound, dispatch]);

    useEffect(() => {
        return () => {
            window.clearTimeout(showHeaderBounce.current);
            if (!isOpenTable) onClose();
        };
    }, [isOpenTable, onClose]);

    const updateItemQuantity = (id: string, count: number) => {
        setLocalRound((lastRound) =>
            lastRound?.map((round) => ({
                ...round,
                quantity: round.id === id ? round.quantity + count : round.quantity,
            }))
        );
    };

    const changeNavbarVisibility = (shouldShow: boolean) => {
        window.clearTimeout(showHeaderBounce.current);
        showHeaderBounce.current = window.setTimeout(() => {
            setShowNavbar(shouldShow);
        }, 50);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    return (
        <ModalContainer
            isOpen={isOpen}
            className={{
                base: "another-round-modal",
                afterOpen: "another-round-modal--after-open",
                beforeClose: "another-round-page--before-close",
            }}
            overlayClassName={classNames("ReactModal__AnotherRoundModal", isAdded && "item-added")}
            contentLabel="Another Round Modal"
            bodyOpenClassName={"ReactModal__Body--open ReactModal__body__AnotherRoundModal"}
            closeTimeoutMS={isAdded ? 350 : 250}
            onRequestClose={closeModal}
            shouldCloseOnEsc
            shouldCloseOnOverlayClick
            onAfterClose={onClose}
        >
            <StatusBar backgroundColor={showNavbar ? "#fff" : "#000"} />
            <div className="another-round__shield" onClick={closeModal} />
            <div className={classNames("another-round__navbar", showNavbar && "show-navbar")}>
                <SimpleNavHeader customBack={"another-round"} onBack={closeModal} title="Another round?" closeToBack />
            </div>
            <TrackScroll
                updatedOnScroll={
                    !isOpen
                        ? undefined
                        : () => {
                              const shouldShowNavbar =
                                  scrolling.scrollTop(".another-round-modal") > window.innerHeight * (0.45 - 0.17);
                              if (shouldShowNavbar !== showNavbar) {
                                  changeNavbarVisibility(shouldShowNavbar);
                              }
                          }
                }
            >
                <div className="another-round__body">
                    <div className="another-round__header">
                        <div className="another-round__header__round">
                            {!!roundImage && (
                                <MenuItemImage className={`drinkcard__thumb`} images={roundImage} name="thumb" />
                            )}
                        </div>
                        <Text className="another-round__header__title" preset="title-24" mode={["bold", "block"]}>
                            Another round?
                        </Text>
                        <Text preset="g-14">Add your previous drinks to your order.</Text>
                    </div>
                    <div>
                        {localRound?.map((item, index) => {
                            return (
                                <div
                                    key={`another-round-${item.itemId}-${index}`}
                                    className={classNames("another-round__item")}
                                >
                                    <div
                                        className={classNames(
                                            "another-round__item__image",
                                            !item.available && "another-round__item__image--disabled"
                                        )}
                                    >
                                        {item.images && (
                                            <MenuItemImage
                                                className={`drinkcard__thumb`}
                                                images={item.images}
                                                name="thumb"
                                            />
                                        )}
                                    </div>
                                    <div className="another-round__item__text">
                                        <Text className="another-round__item__text__name" preset="g-14" mode="bold">
                                            {item.displayName}
                                        </Text>
                                        {item.available ? (
                                            <>
                                                <Text className="another-round__item__text__variant" preset="g-14">
                                                    {item.variantName}
                                                </Text>
                                                <EnergyContent
                                                    value={item.energyContent}
                                                    className="energy-content--dark-grey"
                                                />
                                            </>
                                        ) : (
                                            <Text
                                                className="another-round__item__text__unavailable"
                                                preset="g-14"
                                                value="Unavailable to order"
                                            />
                                        )}
                                    </div>
                                    <div className="another-round__item__quantity-wait-time">
                                        <div
                                            className={classNames(
                                                "another-round__item__quantity",
                                                !item.available && "another-round__item__quantity--disabled"
                                            )}
                                        >
                                            <TappableSpan
                                                className={classNames(
                                                    "another-round__item__quantity__button",
                                                    (item.quantity < 1 || !item.available) &&
                                                        "another-round__item__quantity--disabled"
                                                )}
                                                onClick={() => updateItemQuantity(item.id, -1)}
                                                disabled={item.quantity < 1 || !item.available}
                                            >
                                                <DecreaseIcon />
                                            </TappableSpan>
                                            <Text
                                                preset="g-16"
                                                mode="bold"
                                                className={classNames(
                                                    "another-round__item__quantity__number",
                                                    (item.quantity < 1 || !item.available) &&
                                                        "another-round__item__quantity--disabled"
                                                )}
                                            >
                                                {item.quantity}
                                            </Text>
                                            <TappableSpan
                                                className={classNames(
                                                    "another-round__item__quantity__button",
                                                    (item.quantity >= 99 || !item.available) &&
                                                        "another-round__item__quantity--disabled"
                                                )}
                                                onClick={() => updateItemQuantity(item.id, 1)}
                                                disabled={item.quantity >= 99 || !item.available}
                                            >
                                                <IncreaseIcon />
                                            </TappableSpan>
                                        </div>
                                        {item.available && !!item.waitTime && (
                                            <div className="another-round__item__wait-time">
                                                <WaitTimeBadge waitTime={item.waitTime} />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="another-round__actions">
                    <NetworkConnectedButton disabled={!getAvailableItems(localRound)?.length} onClick={addToOrder}>
                        Add {localRound?.reduce((count, item) => (item.available ? count + item.quantity : count), 0)}{" "}
                        items
                    </NetworkConnectedButton>
                    <Button mode="solidinverted" onClick={closeModal}>
                        No, thanks
                    </Button>
                </div>
            </TrackScroll>
        </ModalContainer>
    );
};
