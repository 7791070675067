import React, { useCallback, useEffect, useMemo } from "react";
import { CSSTransition } from "react-transition-group";
import { SimpleNavHeader } from "src/common/navigation";
import { StatusBar } from "src/common/statusBar";
import { LocationThemeContainer } from "src/features/location/container/LocationThemeContainer";
import { Text } from "src/sharedComponents";
import { PaymentFlowPages } from "..";
import { ReviewOrderContainer } from "../../order/container/ReviewOrderContainer";

import "../assets/PaymentWizard.scss";
import { TappableDiv } from "src/sharedComponents/common/tappable";
import classNames from "classnames";
import { PaymentMethods } from "../../paymentMethods/components/PaymentMethods";
import { CreateTabPage } from "../../groupTabs/components/CreateTabPage";
import { getNextWizardState } from "../util/getNextWizardState";
import { PaymentFlowStateUpdate, WizardDirection } from "../reducers/paymentFlow";
import { ModalContainer } from "src/common/modal";
import { EditPhonePage } from "../../signup/components/EditPhonePage";
import { waitForKeyboardClose } from "../../../common/keyboard";
import { VerifyPage } from "../../signup/components/VerifyPage";
import { AccountDetails } from "../../accounts/components/AccountDetails";

export interface Props {
    showWizard: boolean;
    edit?: boolean;
    page?: PaymentFlowPages;
    next: (nextWizardState?: PaymentFlowStateUpdate) => void;
    prev: (nextWizardState?: PaymentFlowStateUpdate) => void;
    close: () => void;
    doneEditing: () => void;
    signup: () => void;
    isOpeningTab?: boolean;
    isAnonymous: boolean;
    isUnverified: boolean;
    direction: WizardDirection;
    startPage?: PaymentFlowPages;
    phone: string;
    startOpenTabVerification: (isAnonymous: boolean) => void;
    clearPhone: () => void;
    relaunchPaymentFlow: () => void;
    isTakeaway?: boolean;
    isServiceChanging: boolean;
    onEnterPhone: () => void;
}

export const PaymentWizard = ({
    showWizard,
    page,
    next,
    prev,
    close,
    edit,
    doneEditing,
    signup,
    isOpeningTab,
    isAnonymous,
    isUnverified,
    direction,
    startPage,
    phone,
    startOpenTabVerification,
    clearPhone,
    relaunchPaymentFlow,
    isTakeaway,
    isServiceChanging,
    onEnterPhone,
}: Props) => {
    const transitionClass =
        edit === undefined
            ? direction === WizardDirection.FORWARD
                ? "payment-wizard-transition--push"
                : "payment-wizard-transition--pop"
            : edit
            ? "payment-wizard-transition-edit--push"
            : "payment-wizard-transition-edit--pop";

    useEffect(() => {
        return close;
    }, [close]);

    const handleWizardPrevNext = useCallback(
        async (isNext: boolean, isStartingOpenTabFlow?: boolean) => {
            await waitForKeyboardClose();
            const nextWizardState = getNextWizardState(
                isNext,
                page!,
                isStartingOpenTabFlow || !!isOpeningTab,
                isUnverified
            );
            if (isNext) {
                next(nextWizardState);
            } else {
                prev(nextWizardState);
            }
        },
        [page, isOpeningTab, isUnverified, next, prev]
    );

    const startOpenTabFlow = useCallback(async () => {
        if (isUnverified) {
            startOpenTabVerification(isAnonymous);
        } else {
            await handleWizardPrevNext(true, true);
        }
    }, [isAnonymous, isUnverified, startOpenTabVerification, handleWizardPrevNext]);

    const goNextAndUpdateWizardState = () => handleWizardPrevNext(true);
    const goPrevAndUpdateWizardState = () => handleWizardPrevNext(false);

    const verifyPageAdditionalDescription = useMemo(() => {
        if (isTakeaway) {
            return "To order takeaway please verify your phone number.";
        }
        return undefined;
    }, [isTakeaway]);

    useEffect(() => {
        if (isServiceChanging) {
            close();
        }
    }, [isServiceChanging, close]);

    return (
        <ModalContainer
            isOpen={showWizard}
            className={{
                base: "PaymentWizard-modal payment-wizard-modal",
                afterOpen: "PaymentWizard-modal--after-open payment-wizard-modal--after-open",
                beforeClose: "PaymentWizard-modal--before-close payment-wizard-modal--before-close",
            }}
            bodyOpenClassName="PaymentWizard-modal__Body--open"
            overlayClassName={classNames(
                "PaymentWizard-modal--overlay",
                "ReactModal__PaymentWizard",
                page !== "ReviewOrder" && "keyboard-unfixed"
            )}
            onRequestClose={close}
            shouldCloseOnEsc
            closeTimeoutMS={250}
        >
            <LocationThemeContainer>
                <StatusBar backgroundColor="#fff" />
                <CSSTransition in={page === "Phone"} classNames={transitionClass} timeout={250} unmountOnExit>
                    <div className="payment-wizard">
                        <SimpleNavHeader
                            key="payment-wizard-Phone"
                            closeToBack
                            customBack={`payment-wizard-Phone${phone ? "PrePopulated" : ""}`}
                            onBack={() => {
                                prev();
                                clearPhone();
                            }}
                        />
                        <EditPhonePage
                            title="Enter your phone number"
                            subtitle="We use SMS notifications to send you order updates. We’ll text a code to verify your phone."
                            buttonText="Next"
                            hidePlaceholder
                            hideLegalLinks
                            additionalClassName="scroll-element animated-child"
                            onComplete={onEnterPhone}
                        />
                    </div>
                </CSSTransition>
                <CSSTransition in={page === "AccountDetails"} classNames={transitionClass} timeout={250} unmountOnExit>
                    <div className="payment-wizard">
                        <SimpleNavHeader
                            key="payment-wizard-AccountDetails"
                            closeToBack={true}
                            customBack="payment-wizard-AccountDetails"
                            onBack={edit ? doneEditing : prev}
                            rightElement={
                                isAnonymous && (
                                    <TappableDiv onClick={signup} className="payment-wizard__signup">
                                        <Text preset="g-14" mode="block">
                                            Existing account?{" "}
                                            <Text preset="g-14" mode="bold" className="payment-wizard__signup__action">
                                                Sign in
                                            </Text>
                                        </Text>
                                    </TappableDiv>
                                )
                            }
                        />
                        <AccountDetails
                            onSuccess={edit ? doneEditing : next}
                            standalone={edit}
                            flowName="payment_wizard"
                        />
                    </div>
                </CSSTransition>
                <CSSTransition
                    in={page === "SelectPaymentMethod"}
                    classNames={transitionClass}
                    timeout={250}
                    unmountOnExit
                >
                    <div className="payment-wizard">
                        <SimpleNavHeader
                            key={`payment-wizard-${edit ? "ChangePaymentMethod" : "SelectPaymentMethod"}`}
                            closeToBack={edit || startPage === "SelectPaymentMethod"}
                            customBack={`payment-wizard-${edit ? "ChangePaymentMethod" : "SelectPaymentMethod"}`}
                            onBack={edit ? doneEditing : goPrevAndUpdateWizardState}
                        />
                        <PaymentMethods
                            onSuccess={edit ? doneEditing : () => goNextAndUpdateWizardState()}
                            select
                            edit={edit}
                            onOpenTabClick={edit === undefined ? startOpenTabFlow : undefined}
                        />
                    </div>
                </CSSTransition>
                <CSSTransition in={page === "Verify"} classNames={transitionClass} timeout={250} unmountOnExit>
                    <div className="payment-wizard">
                        <SimpleNavHeader
                            key="payment-wizard-Verify"
                            customBack="payment-wizard-Verify"
                            onBack={goPrevAndUpdateWizardState}
                            hasBackButton={true}
                        />
                        <VerifyPage
                            additionalDescription={verifyPageAdditionalDescription}
                            title="Verify your number"
                            onComplete={relaunchPaymentFlow}
                            additionalClassName="scroll-element"
                        />
                    </div>
                </CSSTransition>
                <CSSTransition in={page === "CreateTab"} classNames={transitionClass} timeout={250} unmountOnExit>
                    <div className="payment-wizard">
                        <SimpleNavHeader
                            key="payment-wizard-CreateTab"
                            customBack="payment-wizard-CreateTab"
                            onBack={goPrevAndUpdateWizardState}
                            hasBackButton={true}
                        />
                        <CreateTabPage onSuccess={goNextAndUpdateWizardState} />
                    </div>
                </CSSTransition>
                <CSSTransition in={page === "ReviewOrder"} classNames={transitionClass} timeout={250} unmountOnExit>
                    <div className="payment-wizard">
                        <SimpleNavHeader
                            key="payment-wizard-ReviewOrder"
                            customBack="payment-wizard-ReviewOrder"
                            onBack={goPrevAndUpdateWizardState}
                            hasBackButton={true}
                        />
                        <ReviewOrderContainer />
                    </div>
                </CSSTransition>
            </LocationThemeContainer>
        </ModalContainer>
    );
};
