import { AppDispatch } from "../../index";
import { PayPalCheckoutPaymentMethod } from "../../../common/payment";
import { getPayPalAvailableOperation } from "../operations";
import { paymentGatewayBehaviours } from "../../paymentGateways";

export const getPayPalAvailable = (dispatch: AppDispatch, { paymentGateway }: PayPalCheckoutPaymentMethod) =>
    getPayPalAvailableOperation.invoke(
        () => Promise.resolve(!!paymentGatewayBehaviours[paymentGateway].payPalBehaviour),
        dispatch
    );
