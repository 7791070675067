import { connect } from "react-redux";
import { DataProps, FunctionProps } from "src/common/types/utils";
import { pagePositionActions } from "src/features/pagePosition";
import { AppDispatch, AppState } from "../..";
import { Party, Props } from "../components/Party";
import { getIsOpenTable } from "../../openTable/selectors";

export function mapStateToProps(state: AppState, props: Props): DataProps<Props> {
    const isOpenTable = getIsOpenTable(state);
    return {
        ...props,
        isOpenTable,
    };
}

export function mapDispatchToProps(dispatch: AppDispatch): FunctionProps<Props> {
    return {
        animatingPage: (isServicePage: boolean) =>
            isServicePage
                ? dispatch(pagePositionActions.animatingServicePage(false))
                : dispatch(pagePositionActions.animatingMenuPage(false)),
    };
}

export const PartyContainer = connect(mapStateToProps, mapDispatchToProps)(Party);
