import { AddedCardPaymentMethod, AuthorizePaymentResult, PaymentGateway } from "src/common/payment";
import { Thunk } from "../operations/types";
import { HandledError } from "src/common/error";

export interface ProductFees {
    feePercentage: number;
    feeBaseAmount: number;
}

export type PaymentFlowPages =
    | "Phone"
    | "Verify"
    | "AccountDetails"
    | "SelectPaymentMethod"
    | "CreateTab"
    | "ReviewOrder";

export interface AddedCardResult {
    paymentMethod: AddedCardPaymentMethod;
    additionalFraudProtectionData?: string;
}

export type GetAddedCardFunc = (isPayment: boolean) => Promise<AddedCardResult | null | undefined>;

export type GetCvvTokenFunc = () => Promise<string | undefined>;

export type CompletePaymentFunc = (result: AuthorizePaymentResult | null | Error) => void;

export interface ThreeDSecureInfo {
    paymentGateway: PaymentGateway;
    paymentToken: string;
    isAddedCard: boolean;
    total?: number;
    returnUrl?: string;
    orderApiUrl?: string;
}

export class VerificationCancelledError extends HandledError {
    constructor() {
        super("Verification cancelled");
    }
}

export class VerificationFailedError extends HandledError {
    constructor(message?: string) {
        super(`Verification failed${message ? `: ${message}` : ""}`);
    }
}

export class ThreeDSecureError extends Error {
    constructor(public code?: string, message?: string) {
        super(message);
    }
}

export type ValidateFunc = (
    result?: AuthorizePaymentResult | Error,
    mustValidate?: boolean
) => Promise<AuthorizePaymentResult | Error | undefined>;

export type PaymentFunc = (
    result: AuthorizePaymentResult | Error | undefined,
    completePayment: (() => Promise<void>) | undefined,
    validate: ValidateFunc
) => Thunk;

export type ButtonPaymentFunc = (
    result?: AuthorizePaymentResult | Error,
    completePayment?: () => Promise<void>
) => Thunk;

export interface PaymentButtonProps {
    onPayment: ButtonPaymentFunc;
}

export interface BeginPaymentResult {
    paymentId: string;
}

export interface PaymentMethodInfo {
    paymentToken: string;
    additionalFraudProtectionData?: string;
}

export const NotRequired = "NotRequired";

export interface PaymentInfoBase extends PaymentMethodInfo {
    paymentGateway: PaymentGateway | typeof NotRequired;
    devicePaymentInfo?: DevicePaymentInfo;
    cardPaymentInfo?: CardPaymentInfo;
    addedCardPaymentInfo?: AddedCardPaymentInfo;
    threeDSecurePaymentInfo?: ThreeDSecurePaymentInfo;
    externalPaymentInfo?: ExternalPaymentInfo;
}

export interface PaymentInfo extends PaymentInfoBase {
    includedMembers: string[];
    tip: number;
    amount?: number;
    openTab?: boolean;
    membershipPaymentInfo?: MembershipPaymentInfo;
}

export interface DevicePaymentInfo {
    originalToken: string;
}

export interface CardPaymentInfo {
    cvvToken: string;
}

export interface AddedCardPaymentInfo {
    productId: string;
    remember: boolean;
}

export interface ThreeDSecurePaymentInfo {
    paymentToken: string;
}

export interface ExternalPaymentError {
    code: string;
    reason?: string;
    isConsumerFriendly: boolean;
}

export interface ExternalPaymentResult {
    transactionId?: string;
    error?: ExternalPaymentError;
}

export interface ExternalPaymentInfo extends ExternalPaymentResult {
    paymentId: string;
}

export enum TipType {
    PERCENTAGE = 0,
    CURRENCY = 1,
}

export interface BeginBrowserPaymentResult extends BeginPaymentResult {
    redirectUrl: string;
}

export interface BeginBrowserPaymentInfo {
    total: number;
    returnUrl: string;
}

export enum PaymentStatus {
    PENDING = 0,
    COMPLETE = 1,
    FAILED = 2,
}

export enum PosPaymentStatus {
    SUBMITTING = 0,
    ACCEPTED = 1,
    REJECTED = 2,
    RESOLVED = 3,
}

export interface Payment {
    id: string;
    status: PaymentStatus;
    owner: string;
    amount: number;
    processingFee: number;
    errorCode?: string;
    errorReason?: string;
    paymentGateway: PaymentGateway;
    share: PaymentShare;
    venueServiceFee: number;
    venueServiceFeeFactor: number;
    posStatus?: PosPaymentStatus;
}

export interface PaymentShare {
    split: number;
    shares: number;
}

export interface MembershipPaymentInfo {
    pointsSpendAmount: number;
}
