import * as React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { actionCreators as paymentActionCreators } from "../../payment";
import { useDispatch, useSelector } from "react-redux";
import { getAddCardFormActive } from "../../payment/selectors";
import { paymentGatewayBehaviours } from "../../paymentGateways";
import { showModalMessage } from "../../modalMessage/actions/show";
import { modalMessages } from "../../modalMessage/messages";
import { CardPaymentMethod, NewCardPaymentMethod, SelectablePaymentMethod } from "../../../common/payment";
import { waitForKeyboardClose } from "../../../common/keyboard";
import { SelectPaymentMethodActions } from "../types";
import { AddCardBehaviour } from "../../paymentGateways/types";
import { AddCardForm } from "./AddCardForm";
import { SelectPaymentMethodAction } from "./SelectPaymentMethodAction";
import { hostedFieldsActionCreators } from "../reducers";
import { getIsConnected } from "src/features/order/selectors";
import { usePaymentMethodSelectionContext } from "./SelectPaymentMethodContext";

interface Props<TResultFunc, TBehaviourFunc> {
    paymentMethod: CardPaymentMethod | NewCardPaymentMethod;
    getRequiredBehaviour: (addCardBehaviour: AddCardBehaviour) => TBehaviourFunc | undefined;
    getResultFunc: (behaviourFunc: TBehaviourFunc) => Promise<TResultFunc | undefined>;
    getPaymentMethod: (
        resultFunc: TResultFunc,
        resetState: () => void,
        remember: boolean
    ) => Promise<SelectablePaymentMethod | null>;
    isValid: boolean;
    showVaultCard?: boolean;
    actions?: SelectPaymentMethodActions;
    select?: boolean;
}

export function AddCardFormPaymentMethodAction<TResultFunc, TBehaviourFunc>({
    paymentMethod,
    getRequiredBehaviour,
    getResultFunc,
    getPaymentMethod,
    isValid,
    showVaultCard,
    actions,
    select,
}: Props<TResultFunc, TBehaviourFunc>) {
    const dispatch = useDispatch();

    const [tempSelectedPaymentMethod] = usePaymentMethodSelectionContext();

    const addCardFormActive = useSelector(getAddCardFormActive);
    const isConnected = useSelector(getIsConnected);

    const [renderAddCardForm, setRenderAddCardForm] = useState(false);
    const [showAddCardForm, setShowAddCardForm] = useState(false);
    const [hideAddCardForm, setHideAddCardForm] = useState(false);
    const [vaultCard, setVaultCard] = useState(true);

    const behaviourFunc = useRef<TBehaviourFunc>();
    const resultFunc = useRef<TResultFunc>();
    const unmounted = useRef(false);
    const remember = useRef(vaultCard);
    remember.current = vaultCard;
    const connected = useRef(isConnected);
    connected.current = isConnected;
    const getResult = useRef(getResultFunc);
    getResult.current = getResultFunc;

    const { addCardBehaviour } = paymentGatewayBehaviours[paymentMethod.paymentGateway];

    const resetState = useCallback(() => {
        if (unmounted.current) return;
        setRenderAddCardForm(false);
        setShowAddCardForm(false);
        setHideAddCardForm(false);
        setVaultCard(true);
        resultFunc.current = undefined;
        dispatch(paymentActionCreators.setAddCardFormActive(false));
    }, [dispatch]);

    const onShow = useCallback(() => {
        dispatch(paymentActionCreators.setAddCardFormActive(true));
    }, [dispatch]);

    const onSelect = useCallback(async () => {
        await waitForKeyboardClose();
        return await getPaymentMethod(resultFunc.current!, resetState, remember.current);
    }, [getPaymentMethod, resetState]);

    const initAddCardForm = useCallback(() => {
        if (!connected.current) {
            dispatch(showModalMessage(modalMessages.initAddCardFormFailed()));
            return;
        }
        if (!addCardBehaviour) return;
        behaviourFunc.current = getRequiredBehaviour(addCardBehaviour);
        if (!behaviourFunc.current) return;

        setRenderAddCardForm(true);
        dispatch(hostedFieldsActionCreators.clearState());
    }, [dispatch, addCardBehaviour, getRequiredBehaviour]);

    useEffect(() => {
        if (renderAddCardForm && behaviourFunc.current) {
            getResult.current(behaviourFunc.current).then((func) => {
                behaviourFunc.current = undefined;
                if (!unmounted.current) {
                    if (!func) {
                        dispatch(showModalMessage(modalMessages.initAddCardFormFailed()));
                        setRenderAddCardForm(false);
                    } else {
                        resultFunc.current = func;
                        setShowAddCardForm(true);
                    }
                }
            });
        }
    }, [dispatch, renderAddCardForm]);

    useEffect(() => {
        return () => {
            dispatch(paymentActionCreators.setAddCardFormActive(false));
            unmounted.current = true;
        };
    }, [dispatch]);

    useEffect(() => {
        if (resultFunc.current) {
            actions?.setIsValid(isValid);
        }
    }, [actions, isValid]);

    useEffect(() => {
        if (tempSelectedPaymentMethod === paymentMethod && !addCardFormActive) {
            actions?.setGetPaymentMethod(onSelect);
            actions?.setIsValid(false);
            initAddCardForm();
        } else if (tempSelectedPaymentMethod !== paymentMethod && select && resultFunc.current) {
            waitForKeyboardClose().then(() => {
                if (resultFunc.current) {
                    setHideAddCardForm(true);
                }
            });
        }
    }, [paymentMethod, tempSelectedPaymentMethod, addCardFormActive, actions, initAddCardForm, onSelect, select]);

    return (
        <>
            <SelectPaymentMethodAction paymentMethod={paymentMethod} rightIcon={!select ? null : undefined} />
            {renderAddCardForm && (
                <AddCardForm
                    paymentMethod={paymentMethod}
                    show={showAddCardForm}
                    hide={hideAddCardForm}
                    onShow={onShow}
                    onHide={resetState}
                    vaultCardController={
                        showVaultCard
                            ? {
                                  value: vaultCard,
                                  onChange: setVaultCard,
                              }
                            : undefined
                    }
                    onAdd={actions ? undefined : onSelect}
                />
            )}
        </>
    );
}
