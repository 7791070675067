import { HubConnection } from "@aspnet/signalr";
import { ReattemptFunc } from "./util/attempt";
import { AppState } from "../../index";

export type ConnectionInitializer = (connection: Promise<HubConnection>) => Promise<void>;

export enum ApiErrorCodes {
    PartyClosed = "PartyClosed",
    PartyUpdateTimeout = "PartyUpdateTimeout",
    PartyStateChanged = "PartyStateChanged",
}

export interface OrderApi {
    connect(hub: string, accessToken: string, onConnectionFailed?: (url: string, count: number) => void): Promise<void>;
    disconnect(): Promise<void>;
    send(url: string, request?: RequestInit, reattempt?: ReattemptFunc): Promise<Response>;
    invoke<T extends any>(name: string, ...args: any[]): Promise<T>;
    invokeWithRetry<T extends any>(reattempt: ReattemptFunc, name: string, ...args: any[]): Promise<T>;
    invokeInSequence<T extends any>(
        getState: () => AppState,
        stateChanged: () => boolean,
        name: string,
        ...args: any[]
    ): Promise<T>;
    subscribe(callback: (connection: HubConnection) => void): void;
    setInitializer(initializer: ConnectionInitializer | null): void;
    getInitializer(): ConnectionInitializer | null;
    removeMethodSubscription(methodName: string): void;
}
