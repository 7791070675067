import { PaymentGatewayBehaviours } from "../../interface";
import { initializeBraintreeApplePayButton, initializeBraintreeGooglePayButton } from "./braintree";
import {
    customGooglePayButtonId,
    getStripeApplePayAvailable,
    getStripeGooglePayAvailable,
    initializeStripeApplePayButton,
    initializeStripeGooglePayButton,
    vaultStripeApplePayPaymentMethod,
    vaultStripeGooglePayPaymentMethod,
} from "./stripe";
import { initializeTyroApplePayButton, initializeTyroGooglePayButton } from "./tyro";
import { getApplePayAvailable } from "./common/getApplePayAvailable";
import { getGooglePayAvailable } from "./common/getGooglePayAvailable";

export const paymentGatewayBehaviours: PaymentGatewayBehaviours = {
    Braintree: {
        applePay: {
            getAvailable: getApplePayAvailable,
            initializeButton: initializeBraintreeApplePayButton,
        },
        googlePay: {
            getAvailable: getGooglePayAvailable,
            initializeButton: initializeBraintreeGooglePayButton,
        },
        cardVerificationSupported: true,
    },
    Tyro: {
        applePay: {
            getAvailable: getApplePayAvailable,
            initializeButton: initializeTyroApplePayButton,
        },
        googlePay: {
            getAvailable: getGooglePayAvailable,
            initializeButton: initializeTyroGooglePayButton,
        },
        cardVerificationSupported: true,
    },
    Stripe: {
        applePay: {
            getAvailable: getStripeApplePayAvailable,
            initializeButton: initializeStripeApplePayButton,
            vaultPaymentMethod: vaultStripeApplePayPaymentMethod,
        },
        googlePay: {
            getAvailable: getStripeGooglePayAvailable,
            initializeButton: initializeStripeGooglePayButton,
            vaultPaymentMethod: vaultStripeGooglePayPaymentMethod,
            customButtonId: customGooglePayButtonId,
        },
    },
    GroupTab: {},
    Spreedly: {},
    Pos: {},
};
