import { QuickSellService } from "src/features/quickSell";
import { LimitedMenuPackage } from "../../groupTabs";
import { VenueCause } from "../../venueCause/types";

export interface MenuDataState {
    state: FetchState;
    url?: string;
    data?: LocationMenuData;
    activeServiceId?: string;
}

type FetchState = "unloaded" | "loading" | "loaded" | "failed";

export interface Indexed<T> {
    [id: string]: T;
}

export interface PriceLevels {
    [priceList: number]: number;
}

export interface MembershipPriceLists {
    [membershipLevel: string]: number;
}

export interface LocationMenuData {
    title: string;
    logoUrl: string;
    heroImage: ImageSet;
    backgroundImage?: ImageSet;
    menuBackgroundImage?: ImageSet;
    themeColor: string;
    themeTextColor: string;
    createDate: number;
    fromTheVenueTitle?: string;
    fromTheVenue?: string;
    services: Service[];
    menus: Indexed<Menu>;
    setMenus: Indexed<MenuItem>;
    items: Indexed<MenuItem>;
    originalItems: Indexed<MenuItem>;
    categories: Indexed<Category>;
    tagGroups: TagGroup[];
    courses: Course[];
    locale: string;
    currency: string;
    tips?: Tips;
    packages?: LimitedMenuPackage[];
    modifiers?: Indexed<MenuItemModifier>;
    venueCauses?: VenueCause[];
}

export interface Tips {
    enabled: boolean;
    default: number | null;
    levels: number[];
}

export interface LocationMenuDataUpdate {
    items?: Indexed<MenuItemUpdate>;
    unavailableSkus?: string[];
    categories?: Indexed<UpdateData>;
}

export interface UpdateData {
    unavailable?: boolean;
    waitTime?: number;
}

export interface Course {
    id: string;
    displayName: string;
    dynamic?: boolean;
}

export interface TagGroup {
    id: string;
    displayName: string;
    filterLabel: string;
    tags: Tag[];
    filterMode: "single" | "any" | "all";
    isUserPreference: boolean;
    scope?: string;
    parentTagGroup?: string;
}

export interface Tag {
    id: string;
    displayName: string;
    icon?: string;
    parentTag?: string;
    value?: any;
}

export type MenuItem = FoodMenuItem | DrinkMenuItem;

export type MenuItemWithId = MenuItem & {
    id: string;
};

export type AdUnitItem = MenuItem & {
    courseId: string;
    categoryId: string;
    itemId: string;
    orderItemId: string;
    quantity: number;
};

export interface Service {
    id: string;
    displayName: string;
    startTime: number;
    endTime: number;
    setMenus?: string[];
    menus: string[];
    originalMenus: string[];
    featured?: Featured;
    dates: ServiceDate[];
    courses: string[];
    categories: string[];
    originalCategories?: string[];
    defaultCourse: string;
    surcharge: number;
    priceList?: number;
    membershipPriceLists?: MembershipPriceLists;
    maxAlcoholicDrinksPerOrder?: number;
}

export interface Featured {
    title: string;
    items: FeaturedItem[];
}

export interface FeaturedItem {
    item: string;
    category: string;
    categories: string[];
}
export interface ServiceDate {
    start: string;
    end: string;
}

export interface Menu {
    displayName: string;
    categories: string[];
    originalCategories?: string[];
    icon: string;
}

export type CategoryType = "food" | "drink";

export enum MenuTabNames {
    FOOD = "Food",
    DRINKS = "Drinks",
}

export interface Category {
    type: CategoryType;
    displayName: string;
    menuItems: string[];
    originalMenuItems: string[];
    images: ImageSet;
    course?: string;
    special?: boolean;
    relatedItems?: string[];
    showFromPrice?: boolean;
    unavailable?: boolean;
    waitTime?: number;
}

export interface MenuItemUpdateableBase {
    available?: boolean;
    waitTime?: number;
}

export interface MenuItemUpdate extends MenuItemUpdateableBase {}

export interface AvailablePrices {
    basePrice: number;
    memberPrice?: number;
}

export interface HasPrices {
    price?: string;
    priceLevels?: PriceLevels;
    availablePrices?: AvailablePrices;
}

export interface MenuItemBase extends MenuItemUpdateableBase, HasPrices {
    displayName: string;
    description?: string;
    special?: boolean;
    popular?: boolean;
    tags?: string[];
    images: ImageSet;
    videos?: VideoSet;
    variants?: MenuItemVariant[];
    minVariantPrice?: AvailablePrices;
    sku?: string;
    relatedItems?: string[];
    similarItems?: string[];
    displayNames?: string[];
    details?: string[];
    dateAdded: number;
    energyContent?: number;
    subtypes?: Subtypes;
}

export interface FoodMenuItem extends MenuItemBase {
    type: "food";
    modifiers?: MenuItemModifier[];
}

export interface DrinkMenuItem extends MenuItemBase {
    type: "drink";
    recommended?: boolean;
    modifiers?: MenuItemModifier[];
}

export interface MenuItemModifier {
    displayName: string;
    options: ModifierOption[];
    required?: boolean; // deprecated. If a modifier has `minSelection > 0` it is required. Need to keep for legacy menus
    maxSelection: number;
    maxSelectionPerOption?: number;
    minSelection: number;
    id: string;
    available?: boolean;
    subtypes?: Subtypes;
    collapsible?: boolean;
}

export interface ModifierOption extends HasPrices {
    id?: string;
    displayName: string;
    sku?: string;
    menuItem?: string;
    available?: boolean;
    recommended?: boolean;
    nestedModifiers?: string[];
    originalIndex: number;
    energyContent?: number;
    subtypes?: Subtypes;
    itemReference?: {
        itemId: string;
    };
}

export interface VisibleModifierOption extends ModifierOption {
    nestedOptions?: VisibleNestedModifierOption[];
}

export interface VisibleNestedModifierOption {
    displayName: string;
    price?: number;
    nestedOptions?: VisibleNestedModifierOption[];
}

export type ImageSet = { [type: string]: string } & { alt?: string };
export type VideoSet = { [type: string]: string };

export interface MenuItemVariant extends HasPrices {
    id?: string;
    displayName: string;
    sku?: string;
    modifiers?: number[];
    available?: boolean;
    default?: boolean;
    recommended?: boolean;
    originalIndex: number;
    energyContent?: number;
    subtypes?: Subtypes;
}

export interface MenuLine {
    type: CategoryType;
    displayName?: string;
    categoryId: string;
    category: Category;
    relatedItem?: string;
    originalIndex?: number;
    categoryIndex: number;
}
export interface LocationMenuAdUnits {
    ids?: string[];
    quickSell?: {
        drinksOnly?: QuickSellService;
    };
}

export type ModifierChoice = ModifierOption | MenuItemVariant;

export type ItemWithAvailablePrices = MenuItem | MenuItemVariant | ModifierChoice | ModifierOption;

export enum Subtypes {
    ALCOHOLIC = 1,
}
