import { showBraintreeAddCard } from "./braintree/actions/showBraintreeAddCard";
import { verifyBraintreeCard } from "./braintree/actions/verifyBraintreeCard";
import { showTyroAddCard } from "./tyro/actions/showTyroAddCard";
import { verifyTyroCard } from "./tyro/actions/verifyTyroCard";
import { showStripeAddCard } from "./stripe/actions/showStripeAddCard";
import { TyroAddCardForm } from "./tyro/components/TyroAddCardForm";
import { PaymentGatewayBehaviours } from "./types";
import { visitBraintreePaymentInfo } from "./braintree/visitors/visitBraintreePaymentInfo";
import { handleStripePayment } from "./stripe/actions/handleStripePayment";
import { initializeBraintreePayPalButton } from "./braintree/initializeBraintreePayPalButton";
import { initializeTyroPayPalButton } from "./tyro/initializeTyroPayPalButton";
import { showStripeEnterCvv } from "./stripe/actions/showStripeEnterCvv";
import { showSpreedlyAddCard } from "./spreedly/actions/showSpreedlyAddCard";
import { SpreedlyAddCardForm } from "./spreedly/components/SpreedlyAddCardForm";

export const paymentGatewayBehaviours: PaymentGatewayBehaviours = {
    Braintree: {
        addCardBehaviour: {
            showAddCard: showBraintreeAddCard,
        },
        verifyCardBehaviour: {
            verifyCardAction: verifyBraintreeCard,
        },
        paymentBehaviour: {
            paymentInfoVisitor: visitBraintreePaymentInfo,
        },
        payPalBehaviour: {
            initializeButton: initializeBraintreePayPalButton,
        },
    },
    Tyro: {
        addCardBehaviour: {
            showAddCard: showTyroAddCard,
            addCardFormElement: TyroAddCardForm,
        },
        verifyCardBehaviour: {
            verifyCardAction: verifyTyroCard,
        },
        payPalBehaviour: {
            initializeButton: initializeTyroPayPalButton,
        },
    },
    Stripe: {
        addCardBehaviour: {
            showAddCard: showStripeAddCard,
            showEnterCvv: showStripeEnterCvv,
        },
        paymentBehaviour: {
            handleExternalPayment: handleStripePayment,
        },
    },
    GroupTab: {},
    Spreedly: {
        addCardBehaviour: {
            showAddCard: showSpreedlyAddCard,
            addCardFormElement: SpreedlyAddCardForm,
        },
    },
    Pos: {},
};
