import { replace } from "connected-react-router";
import { getAuthHeaders } from "src/common/auth";
import { AppDispatch, AppState } from "src/features";
import { ConnectionInitializer, orderApi } from "src/features/order/orderApi";
import {
    actionCreators as partyActions,
    getParty,
    MemberActivity,
    OpenTableContext,
    Party,
    PartyNotificationTier,
    PartyType,
} from "../../order";
import { HubConnection } from "@aspnet/signalr";
import { timeoutAndError } from "src/common/shared";
import { showModalMessage } from "../../modalMessage/actions/show";
import { modalMessages } from "../../modalMessage/messages";
import { getAnyParty } from "src/features/order/selectors";
import { getCurrentMemberId } from "../../accounts/selectors";
import { getMemberActivityInfo, saveMemberActivityInfo } from "../persistence/memberActivity";
import { actionCreators as openTableActionCreators } from "../../openTable/reducers";
import { PartyError, PartyErrors } from "../types";
import { ORDER_HUB_URL } from "../constants";
import { disconnectParty, fetchMenuAndMembership } from "../api";
import { disconnectAndCloseParty } from "../actions/disconnectAndCloseParty";
import { getAppInsights } from "src/features/analytics";
import { SeverityLevel } from "@microsoft/applicationinsights-web";

export const notUsed = async () => {
    // This needs to be imported in this file
    // otherwise something breaks in selectors
    await getAuthHeaders();
};

export const joinParty = async (
    getState: () => AppState,
    next: AppDispatch,
    tableToken: string,
    openTableContext?: OpenTableContext,
    timeoutLeft: number = 20000
) => {
    try {
        const onConnectionFailed = (url: string, count: number) => next(partyActions.connectionFailed(url, count));

        // Background reconnect initializer - needs to handle any errors itself
        const reconnectInitializer: ConnectionInitializer = async (connectionPromise) => {
            try {
                await connectParty(connectionPromise, getState, next, openTableContext);
            } catch (err) {
                if (orderApi.getInitializer() !== reconnectInitializer) {
                    // Ignore this error as we're no longer the active connection initializer
                    return;
                }
                getAppInsights()?.trackException({
                    exception: err as Error,
                    severityLevel: SeverityLevel.Error,
                })
                const party = getAnyParty(getState());
                await disconnectAndCloseParty(getState, next, "failed_reconnect");

                if (party?.type === PartyType.PAYONLY) {
                    next(replace("/pay-only"));
                } else {
                    next(replace("/join-table"));
                    next(showModalMessage(modalMessages.tableClosed()));
                }
            }
        };

        // This is the initial initializer while joining a party
        // Failure to join will rethrow because it will be handled
        // in (re)join(Table|PayOnlyParty) and
        const joinInitializer: ConnectionInitializer = async (connectionPromise) => {
            try {
                await connectParty(connectionPromise, getState, next, openTableContext, timeoutLeft);

                // switch to the 'background reconnect' mode
                orderApi.setInitializer(reconnectInitializer);
            } catch (err) {
                await disconnectAndCloseParty(getState, next, "failed_join");
                throw err;
            }
        };

        orderApi.setInitializer(joinInitializer);

        await orderApi.connect(ORDER_HUB_URL, tableToken, onConnectionFailed);
    } catch (err) {
        getAppInsights()?.trackException({
            exception: err as Error,
            severityLevel: SeverityLevel.Error,
        })
        await disconnectParty(next, true, "failed_join");
        throw err;
    }
};

const connectingParty = async (
    connectionPromise: Promise<HubConnection>,
    next: AppDispatch,
    getState: () => AppState,
    openTableContext?: OpenTableContext
) => {
    const connection = await connectionPromise;
    const state = getState();
    const currentMemberId = getCurrentMemberId(state);
    const isOpenTable = !!openTableContext; //getIsOpenTable selector cannot be used at this point because there is no party
    const isOpenTableFlexTab = !!openTableContext?.hasFlexTabBehavior;

    const partyConnectionMethod = isOpenTable && !isOpenTableFlexTab ? "subscribeToParty" : "joinParty";
    const memberActivityInfo = getMemberActivityInfo();

    const party: Party = await connection.invoke(partyConnectionMethod).catch((err) => {
        const match = /\{\{(.*)\}\}/.exec(err.message);
        if (match) {
            const errorCode = match[1];
            throw new PartyError(errorCode as PartyErrors);
        }
    });

    if (!party) {
        throw new PartyError("UnknownError", `${partyConnectionMethod} returned null party`);
    }

    const currentMemberInParty = party.members.find((m) => m.memberId === currentMemberId);

    if (isOpenTable && currentMemberInParty && memberActivityInfo?.partyId !== party.id) {
        saveMemberActivityInfo(party.id, MemberActivity.Ordering);
        next(openTableActionCreators.setActivity(MemberActivity.Ordering));
    }

    await fetchMenuAndMembership(party, next, getState);

    return party;
};

const connectParty = async (
    connectionPromise: Promise<HubConnection>,
    getState: () => AppState,
    next: AppDispatch,
    openTableContext?: OpenTableContext,
    timeout?: number
) => {
    next(partyActions.connecting());

    const party =
        timeout === undefined
            ? await connectingParty(connectionPromise, next, getState, openTableContext)
            : await Promise.race([
                  connectingParty(connectionPromise, next, getState, openTableContext),
                  timeoutAndError(timeout, new Error("Timeout")),
              ]);

    if (!party) return;

    const toastNotificationIds = party.notifications
        .filter((n) => n.tier === PartyNotificationTier.TOAST)
        .map((n) => n.id);

    next(partyActions.viewNotification(toastNotificationIds));
    next(partyActions.switched(party, getParty(getState())));
    next(partyActions.connected());
};
