import { connect } from "react-redux";
import { AppDispatch, AppState } from "../..";
import { DrinkCard, Props } from "../components/DrinkCard";
import { getVisibleMenuData } from "../selectors";
import { DataProps, FunctionProps } from "src/common/types/utils";
import { MenuItem } from "src/features/menudata";
import { addAllItemInDemoMode } from "../util/helpers";
import { getTestingFeaturesEnabled } from "src/features/order/selectors";

export interface OwnProps {
    showFromPrice?: boolean;
    menuItemId: string;
    quantity?: number;
}

function mapDispatchToProps(dispatch: AppDispatch, { menuItemId }: OwnProps): FunctionProps<Props> {
    return {
        onAddAllItem: (menuItem: MenuItem) => dispatch(addAllItemInDemoMode(menuItem, menuItemId)),
    };
}

export function mapStateToProps(state: AppState, { menuItemId, showFromPrice, quantity }: OwnProps): DataProps<Props> {
    const menuItem = getVisibleMenuData(state)!.items[menuItemId];

    const showAddAllItem = getTestingFeaturesEnabled(state);

    if (!menuItem || menuItem.type !== "drink") {
        // tslint:disable-next-line:no-console
        console.warn(`Menu item '${menuItemId}' not found`);
        return {
            menuItem: {} as any,
            showAddAllItem,
            quantity,
        };
    }

    return { menuItem, showFromPrice, showAddAllItem };
}

export const DrinkCardContainer = connect(mapStateToProps, mapDispatchToProps)(DrinkCard);
