import React from "react";
import { useSelector } from "react-redux";
import { getVenueFeedbackUrl } from "src/features/order/selectors/restaurantFlags";
import { Button, Text } from "src/sharedComponents";
import { href } from "src/common/experience";

export const PayOnlyVenueFeedbackUrl = () => {
    const venueFeedbackUrl = useSelector(getVenueFeedbackUrl);

    if (!venueFeedbackUrl) return null;

    return (
        <div className="pay-only-receipt__venue-feedback">
            <Button mode="outline" unthemed>
                <a {...href.openInNewWindowAttributes(venueFeedbackUrl)}>
                    <Text preset="g-16" mode="bold">
                        Give venue feedback
                    </Text>
                </a>
            </Button>
        </div>
    );
};
