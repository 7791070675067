import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTopPage } from "src/common/navigation";
import { campaignsActionCreators } from "src/features/analytics/actions";
import { MenuItemCardContainer } from "src/features/menu";
import { getVisibleMenuData } from "src/features/menu/selectors";
import { MenuItemButton } from "src/features/menuitem";
import { OrderCampaigns } from "src/features/order/reducers/types";
import { Text } from "src/sharedComponents";
import "../assets/FeaturedItem.scss";

export interface Props {
    title: string;
    itemId?: string;
    categoryId?: string;
}

export const FeaturedItem = ({ title, itemId, categoryId }: Props) => {
    const dispatch = useDispatch();
    const menuData = useSelector(getVisibleMenuData);
    const campaignTimeout = useRef(0);
    const item = itemId ? menuData?.items[itemId] : undefined;
    const itemName = item?.displayName;
    const itemWaitTime = item?.waitTime;

    useEffect(() => {
        if (!!itemId) {
            // need to trigger the campaign tracking after the is truly visible
            campaignTimeout.current = window.setTimeout(
                () =>
                    dispatch(
                        campaignsActionCreators.campaignImpression({
                            campaign_name: OrderCampaigns.FEATURED_ITEM,
                            data: {
                                current_page: getTopPage(),
                                "campaign.featured_title": title,
                                "campaign.featured_id": itemId,
                                "campaign.featured_name": itemName ?? "",
                                "campaign.featured_categoryId": categoryId ?? "",
                                availability: itemWaitTime ? `${itemWaitTime} min` : "",
                            },
                        })
                    ),
                200
            );
        }
        return () => window.clearTimeout(campaignTimeout.current);
    }, [dispatch, itemName, title, itemId, categoryId, itemWaitTime]);

    if (!itemId) return null;

    return (
        <div className="featured">
            <Text className="featured__title" preset="title-28" mode="extra-bold" value={title} />
            <MenuItemButton menuItemId={itemId} categoryId={categoryId} className="menuitemcard-link" isFeatured={true}>
                <MenuItemCardContainer menuItemId={itemId} categoryId={categoryId} showWaiterFavourite={true} />
            </MenuItemButton>
        </div>
    );
};
