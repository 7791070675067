import {
    DefaultHttpClient,
    HttpError,
    HttpRequest,
    HttpResponse,
    HubConnection,
    HubConnectionBuilder,
    ILogger,
} from "@aspnet/signalr";
import { attempt, ReattemptFunc } from "./util/attempt";
import { backoff, wait } from "./util/backoff";
import { auth } from "src/common/auth";
import { getAuthorizationHeaderValue, setHeader } from "./util/headers";
import { InvocationPool } from "./util/InvocationPool";
import { ApiErrorCodes, ConnectionInitializer, OrderApi } from "./types";
import { AppDispatch, AppState } from "../../index";
import { getEnvUrl, poll } from "src/common/shared";
import { config } from "src/common/config";
import { createLogger } from "@aspnet/signalr/dist/esm/Utils";
import { regionHelper } from "../../region";
import { ProblemDetailsError } from "./ProblemDetailError";
import { getAppInsights } from "src/features/analytics";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { connectivity } from "src/common/experience";
import { errorHandlers } from "src";
import { FetchError } from "./FetchError";
import { Party } from "src/features/order/types";
import { actionCreators } from "src/features/order/reducers/party";

export type Subscriber = (connection: HubConnection) => void;

const MEANDU_API_VERSION = 32;
const MEAND_API_VERSION_HEADER = "x-meandu-api-version";
const MEAND_API_QUERY_KEY = "api_version";

const connectionBackoff = () =>
    backoff(
        {
            initialDelay: 2000,
            multiplier: 1.5,
            maxDelay: 30000,
        },
        (ms) => {
            if (window.navigator.onLine === false) {
                return Promise.race([connectivity.awaitOnline(), wait(ms)]);
            }

            return wait(ms);
        }
    );

// Callers to orderApi.send() can use this as a retry mechanism _if_ their operation is retryable (ie. GET or idempotent PUT/POST)
export const apiBackoff = (maxRetries: number = 5) =>
    backoff({
        initialDelay: 2000,
        multiplier: 1.5,
        maxDelay: 5000,
        maxRetries,
    });

const errorCodeRegex = /{{(.*)}}/;

const connectionRetry = () => {
    const backoff = connectionBackoff();

    return (err: any) => {
        if (err.statusCode === 401) throw err;

        return backoff(err);
    };
};

const isSignalRConnectionError = (err: any) =>
    err &&
    err.message &&
    (err.message === "Server timeout elapsed without receiving a message from the server." ||
        err.message === "Invocation canceled due to connection being closed." ||
        /WebSocket closed/.test(err.message));

// Retry for an 'invoke' that needs to be resliant to a connection timeout
// *MAY* invoke the hub method again so idempotence is required.
export const invokeConnectionRetry = (maxRetries: number) => {
    const backoff = apiBackoff(maxRetries);

    return (err: any) => {
        if (!isSignalRConnectionError(err)) {
            throw err;
        }

        return backoff(err);
    };
};

const apiRetry = (init?: RequestInit, reattempt?: ReattemptFunc) => {
    let hasRetried = false;

    return async (err: any) => {
        if (err.status === 401) {
            const authToken = getAuthorizationHeaderValue(init);

            if (!hasRetried && authToken && auth.canRefreshToken(authToken)) {
                hasRetried = true;

                const updatedIdToken = await auth.refreshIdToken();
                setHeader(init, "Authorization", `Bearer ${updatedIdToken}`);

                return;
            }

            throw err;
        }

        if (!reattempt) {
            throw err;
        }

        await reattempt(err);
    };
};

class RegionAwareHttpClient extends DefaultHttpClient {
    private readonly region: string;

    constructor(region: string, logger?: ILogger) {
        super(createLogger(logger));
        this.region = region;
    }

    public async send(request: HttpRequest): Promise<HttpResponse> {
        request.headers = { ...request.headers, "x-meandu-region": this.region };
        return super.send(request);
    }
}

export class ServerOrderApi implements OrderApi {
    private connection: HubConnection | null = null;
    private connectionPromise: Promise<HubConnection> | null = null;
    private cancelConnection: CancellationPromise | null = null;

    private initializeConnection: ConnectionInitializer | null = null;
    private onConnectionFailed?: (url: string, count: number) => void = undefined;

    private readonly defaultBaseUrl: string;
    private readonly fallbackBaseUrl?: string;
    private baseUrl: string;

    private accessToken: string | null = null;
    private hub: string | null = null;

    private subscribers: Subscriber[] = [];

    private invocationPool = new InvocationPool();

    private initialReconnectDelay = 0;

    constructor() {
        this.defaultBaseUrl = getEnvUrl(config.REACT_APP_ORDER_API)!;
        this.fallbackBaseUrl = getEnvUrl(config.REACT_APP_FALLBACK_ORDER_API);
        this.baseUrl = this.defaultBaseUrl;
    }

    async connect(hub: string, accessToken: string, onConnectionFailed?: (url: string, count: number) => void) {
        this.onConnectionFailed = onConnectionFailed;

        this.hub = hub;
        this.accessToken = accessToken;

        this.connection = this.createConnection();

        for (const subscriber of this.subscribers) {
            subscriber(this.connection!);
        }

        this.monitorDisconnections(this.connection);

        await this.reconnect();
    }

    createConnection() {
        return new HubConnectionBuilder()
            .withUrl(`${this.baseUrl}${this.hub}`, {
                accessTokenFactory: () => this.accessToken!,
                httpClient: new RegionAwareHttpClient(regionHelper.region.id),
            })
            .build();
    }

    setInitializer(initializer: ConnectionInitializer | null) {
        this.initializeConnection = initializer;
    }

    getInitializer() {
        return this.initializeConnection;
    }

    private async reconnect() {
        try {
            this.cancelConnection = new CancellationPromise();

            const onAttemptFailed = (count: number) =>
                this.onConnectionFailed && this.hub && this.onConnectionFailed(this.hub, count);

            this.connectionPromise = attempt(
                async () => {
                    if (this.initialReconnectDelay > 0) {
                        await delay(this.initialReconnectDelay);
                        this.initialReconnectDelay = 0;
                    }

                    await this.connection!.start();
                    return this.connection!;
                },
                (err) => {
                    if (this.maybeSwitchFallbackUrl(err)) {
                        this.connection = this.createConnection();

                        // resubscribe to party and reconnect events for the new connection
                        for (const subscriber of this.subscribers) {
                            subscriber(this.connection!);
                        }

                        this.monitorDisconnections(this.connection);
                    }
                    return connectionRetry()(err);
                },
                this.cancelConnection.promise,
                onAttemptFailed
            );

            if (this.initializeConnection) {
                await this.initializeConnection(this.connectionPromise);
            }
        } catch (e) {
            this.connection = null;
            throw e;
        }
    }

    private maybeSwitchFallbackUrl(err: Error): boolean {
        const isRestError =
            (err instanceof FetchError || err instanceof ProblemDetailsError) &&
            (err.status === 502 || err.status === 504);

        const isSignalRConnectionError = err instanceof HttpError && err.statusCode === 0;

        if (
            (isRestError || isSignalRConnectionError) &&
            this.fallbackBaseUrl &&
            this.baseUrl !== this.fallbackBaseUrl
        ) {
            this.baseUrl = this.fallbackBaseUrl;
            return true;
        }

        return false;
    }

    private monitorDisconnections(connection: HubConnection) {
        connection.onclose(async () => {
            if (connection === this.connection) {
                await this.reconnect();
            }
        });

        connection.on("reconnect", async (minDelayMs: number, maxDelayMs) => {
            this.initialReconnectDelay = Math.random() * (maxDelayMs - minDelayMs) + minDelayMs;
            await connection.stop();
        });
    }

    private async awaitConnection(): Promise<HubConnection> {
        if (this.connectionPromise) {
            await this.connectionPromise;
        }

        return this.connection!;
    }

    async invokeWithRetry<T extends any>(reattempt: ReattemptFunc, name: string, ...args: any[]): Promise<T> {
        // If this changes, we've explicitly disconnected or reconnected to a new party
        const currentReconnect = this.reconnect;

        return attempt(
            () => this.invoke(name, ...args),
            (err) => {
                // Throw if we've changed session so we don't retry forever
                if (this.reconnect !== currentReconnect) {
                    throw err;
                }

                return reattempt(err);
            }
        );
    }

    async invoke<T extends any>(name: string, ...args: any[]): Promise<T> {
        const connection = await this.awaitConnection();

        try {
            return await this.invocationPool.wrap(() => connection.invoke(name, ...args));
        } catch (err) {
            const match = errorCodeRegex.exec(err.message)?.[1].split(":");
            const errorCode = match?.[0];
            const errorDetails = match?.[1]?.trim();

            for (const { code, handler } of errorHandlers) {
                if (code === errorCode) {
                    throw handler(errorDetails);
                }
            }

            throw err;
        }
    }

    async invokeWithPartyUpdateAsNeeded(
        dispatch: AppDispatch,
        getState: () => AppState,
        name: string,
        ...args: any[]
    ): Promise<Party | null> {
        const {
            party: { updatedTimestamp },
        } = getState();

        const party = await this.invoke<Party>(name, ...args);

        if (!party) return null;

        // This will only update the party in state if we haven't
        // had a "partyUpdated" event before invoke completed
        dispatch(actionCreators.updated(party, updatedTimestamp + 1));

        return party;
    }

    async invokeInSequence<T extends any>(
        getState: () => AppState,
        stateChanged: () => boolean,
        name: string,
        ...args: any[]
    ): Promise<T> {
        const party = getState().party.activeParty;
        if (!party) throw new Error(ApiErrorCodes.PartyClosed);

        try {
            return await this.invoke(name, ...args, party.sequenceId);
        } catch (err) {
            const match = errorCodeRegex.exec(err.message);

            if (match) {
                const errorCode = match[1];

                if (errorCode === "OutOfSequence") {
                    await poll(
                        () => {
                            const updatedParty = getState().party.activeParty;
                            if (!updatedParty) throw new Error(ApiErrorCodes.PartyClosed);
                            return updatedParty.sequenceId! > party.sequenceId!;
                        },
                        30000,
                        100,
                        ApiErrorCodes.PartyUpdateTimeout
                    );

                    if (stateChanged()) throw new Error(ApiErrorCodes.PartyStateChanged);

                    return await this.invokeInSequence(getState, stateChanged, name, ...args);
                }

                throw new Error(errorCode);
            }

            throw err;
        }
    }

    subscribe(callback: Subscriber): void {
        this.subscribers.push(callback);
    }

    removeMethodSubscription(methodName: string): void {
        this.connection?.off(methodName);
    }

    async disconnect() {
        await this.invocationPool.waitForIdle();

        if (this.cancelConnection) {
            this.cancelConnection.cancel();
            this.cancelConnection = null;
        }

        if (this.connection) {
            const connection = this.connection;
            this.connection = null;

            try {
                await connection.stop();
            } catch (e) {
                // We don't need to handle errors here
            } finally {
                this.connectionPromise = null;
            }
        }
    }

    async send(url: string, init?: RequestInit, reattempt?: ReattemptFunc): Promise<Response> {
        if (url !== "/region") {
            init = await regionHelper.addRegionHeaders(init);
        }

        const versionedUrl = applyApiVersion(url, init);

        const action = async () => {
            const response = await fetch(`${this.baseUrl}${versionedUrl}`, init);

            if (response.status === 401 || response.status === 403) {
                getAppInsights()?.trackTrace({
                    message: `API '${url}' returned ${response.status} from JWT ${JSON.stringify(
                        tryParseAuthJwt(init)
                    )}`,
                    severityLevel: SeverityLevel.Error,
                });
            }

            if (response.status === 401 || (response.status >= 500 && response.status <= 599)) {
                await ProblemDetailsError.throwError(response);
            }

            return response;
        };

        const onAttemptFailed = (count: number) => this.onConnectionFailed && this.onConnectionFailed(url, count);

        const retry = apiRetry(init, reattempt);

        return attempt(
            action,
            (err) => {
                this.maybeSwitchFallbackUrl(err);
                return retry(err);
            },
            undefined,
            onAttemptFailed
        );
    }
}

function applyApiVersion(url: string, init?: RequestInit) {
    if (init) {
        setHeader(init, MEAND_API_VERSION_HEADER, String(MEANDU_API_VERSION));
    }

    // Put the rest into an 'else' once we resolve the issue where Android isn't always sending the header

    const separator = url.indexOf("?") === -1 ? "?" : "&";

    return `${url}${separator}${MEAND_API_QUERY_KEY}=${MEANDU_API_VERSION}`;
}

class CancellationPromise {
    private readonly _promise = new Promise((_, rej) => (this._reject = rej));
    private _reject?: (err: any) => void = undefined;

    private _cancelled: Error | null = null;

    constructor() {
        this._promise = new Promise((_, rej) => {
            if (this._cancelled) {
                rej(this._cancelled);
            } else {
                this._reject = rej;
            }
        });
    }

    get promise() {
        return this._promise;
    }

    cancel() {
        const error = Error("Cancelled");
        if (this._reject) {
            this._reject(error);
        } else {
            this._cancelled = error;
        }
    }
}

const delay = (ms: number) => new Promise<void>((res) => setTimeout(res, ms));

function tryParseAuthJwt(init: RequestInit | undefined): any {
    const authHeader = getAuthorizationHeaderValue(init);

    if (!authHeader) {
        return authHeader;
    }

    try {
        return parseJwt(authHeader);
    } catch {
        return authHeader;
    }
}

function parseJwt(jwt: string): { [claim: string]: string | undefined } {
    return JSON.parse(atob(jwt.split(".")[1]));
}
