import { timeoutAndError } from "../../../../common/shared";
import { hostedFields, HostedFieldsStateObject } from "braintree-web";
import { normalizeError } from "../../../../common/error";
import { AddedCardResult, GetAddedCardFunc, ProductFees } from "../../../payment";
import { PaymentType } from "../../../../common/payment";
import { registerKeyboardClosed, registerKeyboardOpen } from "../../../../common/keyboard";
import { HostedField, hostedFieldOptions } from "../../../paymentMethods/types";
import { getCardDisplayName } from "../../../payment/util/getCardDisplayName";
import { fetchProductFees } from "../../../payment/api/fetchProductFees";
import { getBraintreeClientInstance } from "../../../../common/braintree";
import { fetchPaymentSessionToken } from "../../../payment/api/fetchPaymentSessionToken";
import moment from "moment";
import {
    getBraintreeAddedCardForPaymentOperation,
    getBraintreeAddedCardOperation,
    showBraintreeAddCardOperation,
} from "../operations";
import { BraintreeAddedCardPaymentMethod } from "../types";
import { ShowAddCardFunc } from "../../types";
import { setHostedFieldValidity } from "../../../paymentMethods/actions/setHostedFieldValidity";

export const showBraintreeAddCard: ShowAddCardFunc = (dispatch, newCardPaymentMethod, inParty) => {
    return showBraintreeAddCardOperation.invoke(async () => {
        const clientToken = await fetchPaymentSessionToken(dispatch, newCardPaymentMethod.paymentGateway, inParty);

        if (!clientToken) {
            throw new Error("No client token");
        }

        const { clientInstance, deviceData } = await getBraintreeClientInstance(clientToken);

        const initializeHostedFields = () =>
            hostedFields.create({
                client: clientInstance,
                styles: {
                    input: {
                        "font-size": hostedFieldOptions.fontSize,
                        "font-weight": hostedFieldOptions.fontWeight,
                        "font-family": hostedFieldOptions.fontFamily,
                        color: hostedFieldOptions.color,
                    },
                    "::placeholder": {
                        color: hostedFieldOptions.placeholderColor,
                    },
                    ":-ms-input-placeholder": {
                        color: hostedFieldOptions.placeholderColor,
                    },
                    "::-ms-input-placeholder": {
                        color: hostedFieldOptions.placeholderColor,
                    },
                    ".invalid": {
                        color: hostedFieldOptions.errorColor,
                    },
                },
                fields: {
                    number: {
                        selector: `#${HostedField.Number}`,
                        maxCardLength: 16,
                        placeholder: hostedFieldOptions.placeholders.cardNumber,
                        supportedCardBrands: {
                            visa: true,
                            mastercard: true,
                            "american-express": true,
                        },
                    } as any,
                    expirationDate: {
                        selector: `#${HostedField.Expiry}`,
                        placeholder: hostedFieldOptions.placeholders.expiry,
                    },
                    cvv: {
                        selector: `#${HostedField.CVV}`,
                        placeholder: hostedFieldOptions.placeholders.cvv,
                    },
                },
            });

        const hostedFieldsInstance = await Promise.race([
            initializeHostedFields(),
            timeoutAndError(hostedFieldOptions.initializeTimeout, "Timed out initializing Braintree hosted fields"),
        ]);

        if (!hostedFieldsInstance) {
            throw new Error("Failed to initialize Braintree Hosted Fields");
        }

        hostedFieldsInstance.on("validityChange", (event: HostedFieldsStateObject) => {
            const field = event.fields[event.emittedBy];
            const id = field.container.id;

            dispatch(setHostedFieldValidity(id, field.isValid, !field.isPotentiallyValid));

            if (field.isValid) {
                if (id === HostedField.Number) {
                    (hostedFieldsInstance as any).focus("expirationDate");
                } else if (id === HostedField.Expiry) {
                    (hostedFieldsInstance as any).focus("cvv");
                }
            }
        });

        const setCvvPlaceholder = (placeholder: string) => {
            (hostedFieldsInstance as any).setAttribute({
                field: "cvv",
                attribute: "placeholder",
                value: placeholder,
            });
        };

        hostedFieldsInstance.on("cardTypeChange", (event: HostedFieldsStateObject) => {
            if (event.cards.length === 1 && (event.cards[0] as any).supported) {
                // Change the CVV length for Amex cards
                if (event.cards[0].code.size === 4) {
                    setCvvPlaceholder(hostedFieldOptions.placeholders.cvvAmex);
                }
            } else {
                setCvvPlaceholder(hostedFieldOptions.placeholders.cvv);
                hostedFieldsInstance.clear("expirationDate");
                hostedFieldsInstance.clear("cvv");
            }
        });

        // using delayed open keyboard to fix iOS scrolling issues
        hostedFieldsInstance.on("focus", registerKeyboardOpen);

        hostedFieldsInstance.on("blur", registerKeyboardClosed);

        /*
        {
          "nonce": "token...",
          "details": {
            "cardholderName": null,
            "expirationMonth": "01",
            "expirationYear": "2023",
            "bin": "411111",
            "cardType": "Visa",
            "lastFour": "1111",
            "lastTwo": "11"
          },
          "description": "ending in 11",
          "type": "CreditCard",
          "binData": {
            "prepaid": "Unknown",
            "healthcare": "Unknown",
            "debit": "Unknown",
            "durbinRegulated": "Unknown",
            "commercial": "Unknown",
            "payroll": "Unknown",
            "issuingBank": "Unknown",
            "countryOfIssuance": "Unknown",
            "productId": "Unknown"
          }
        }
        */

        const getAddedCard: GetAddedCardFunc = (isPayment: boolean) =>
            (isPayment ? getBraintreeAddedCardForPaymentOperation : getBraintreeAddedCardOperation).invoke(
                () =>
                    new Promise<AddedCardResult | null>((resolve, reject) => {
                        const state = hostedFieldsInstance.getState();
                        const formValid = Object.keys(state.fields).every((key) => state.fields[key].isValid);

                        if (!formValid) {
                            resolve(null);
                            return;
                        }

                        hostedFieldsInstance.tokenize(async (err: any, payload: any) => {
                            if (err) {
                                reject(normalizeError(err));
                                return;
                            }

                            const {
                                nonce: token,
                                details: { cardType, lastFour, bin, expirationMonth, expirationYear },
                                binData: { productId },
                            } = payload;

                            const expiry = moment(`${expirationMonth}-${expirationYear}`, "MM-YYYY");
                            const displayName = getCardDisplayName(cardType);
                            const verificationMinAmount =
                                newCardPaymentMethod.cardTypeVerificationMinAmounts?.[displayName] ??
                                newCardPaymentMethod.verificationMinAmount;

                            let productFees: ProductFees | undefined;
                            if (isPayment) {
                                productFees = await fetchProductFees(
                                    dispatch,
                                    newCardPaymentMethod.paymentGateway,
                                    productId,
                                    newCardPaymentMethod.currency,
                                    null,
                                    inParty
                                );
                            }

                            const paymentMethod: BraintreeAddedCardPaymentMethod = {
                                ...newCardPaymentMethod,
                                paymentType: PaymentType.ADDEDCARD,
                                displayName,
                                token,
                                maskedNumber: `•••• ${lastFour}`,
                                expirationDate: expiry.format("MM/YY"),
                                productId,
                                processingFee: productFees?.feePercentage ?? 0,
                                processingFeeBaseAmount: productFees?.feeBaseAmount ?? 0,
                                remember: false,
                                bin,
                                verificationMinAmount,
                            };

                            resolve({
                                paymentMethod,
                                additionalFraudProtectionData: deviceData,
                            });
                        });
                    }),
                dispatch
            );

        return getAddedCard;
    }, dispatch);
};
