import React, { useRef, useEffect } from "react";
import { Text, Divider } from "src/sharedComponents";
import { Filter } from "src/features/filter/components/Filter";
import { SelectedFilters } from "src/features/filter";
import { TappableSpan } from "src/sharedComponents/common/tappable";
import classNames from "classnames";
import { MenuItemCardContainer } from "..";
import { MenuItemButton } from "src/features/menuitem";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { IntersectionObserverRef } from "src/common/shared";

interface Props {
    menuItemIds: string[];
    categoryId: string;
    displayName?: string;
    type: "drinklisting" | "foodlisting";
    onCategoryVisible: (categoryId: string[]) => void;
    scrollElement: Element | null;
    isFiltered?: boolean;
    canOpenFilter?: boolean;
    handleFilter: () => void;
    resetFilter: () => void;
    selectedFilters: SelectedFilters;
    relatedItem?: string;
    initialCategoriesId: string[];
    prefix: string;
    onItemVisible: (itemId: string) => void;
    itemObserver: IntersectionObserver;
    defaultVisibleItems: string[];
}

export const LineItem = ({
    menuItemIds,
    categoryId,
    displayName,
    type,
    scrollElement,
    isFiltered,
    canOpenFilter,
    prefix,
    onCategoryVisible,
    handleFilter,
    selectedFilters,
    resetFilter,
    relatedItem,
    initialCategoriesId,
    onItemVisible,
    itemObserver,
    defaultVisibleItems,
}: Props) => {
    const section = useRef<HTMLOListElement>(null);
    const observer = useRef<IntersectionObserverRef>({} as IntersectionObserverRef);
    const lineRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const observerCurrent = observer.current;
        const sectionCurrent = section.current;
        if (!observerCurrent?.data && sectionCurrent?.firstElementChild) {
            observerCurrent.data = new IntersectionObserver(
                (entries: IntersectionObserverEntry[]) => {
                    onCategoryVisible(
                        entries
                            .filter((entry) => entry.isIntersecting)
                            .map((entry) => entry.target.id.split("_")[1])
                            .filter((categoryId, index, items) => items.indexOf(categoryId) === index)
                    );
                },
                { rootMargin: "-126px 0px -60px 0px", threshold: 0.5 }
            );
            observerCurrent.data.observe(sectionCurrent.firstElementChild);
        }

        return () => {
            if (observerCurrent.data && sectionCurrent?.firstElementChild) {
                observerCurrent.data.unobserve(sectionCurrent.firstElementChild);
                observerCurrent.data = undefined;
            }
        };
    }, [section, observer, scrollElement, onCategoryVisible]);

    useEffect(() => {
        const shouldObserve = !prefix;
        const line = lineRef.current;
        if (line && shouldObserve) {
            itemObserver.observe(line);
        }
        return () => {
            if (line && shouldObserve) {
                itemObserver.unobserve(line);
            }
        };
    }, [onItemVisible, prefix, itemObserver]);

    const visibleByDefault =
        !!relatedItem && (initialCategoriesId.includes(categoryId) || defaultVisibleItems.includes(relatedItem));

    return (
        <ol key={"menu-category-title-" + displayName} ref={section} className={type} r-cmp={type}>
            {!relatedItem ? (
                <>
                    <div
                        className={classNames("menu-page__items__title", type === "drinklisting" && "with-filters")}
                        id={prefix + "_" + categoryId}
                    >
                        <Text preset="title-28" mode="extra-bold">
                            {displayName}
                        </Text>
                        {type === "drinklisting" && (
                            <Filter
                                onClick={handleFilter}
                                selectedFilters={selectedFilters}
                                canOpenFilter={canOpenFilter}
                            />
                        )}
                    </div>
                    {isFiltered && (
                        <div className="menu-page__items__filter-mesaage">
                            {!menuItemIds.length ? (
                                <Text preset="g-14" mode="block">
                                    No items match your filter. &nbsp;
                                    <TappableSpan onTap={resetFilter}>
                                        <Text preset="g-14" mode="bold">
                                            Clear filter
                                        </Text>
                                    </TappableSpan>
                                </Text>
                            ) : (
                                <Text preset="g-14" mode="block">
                                    Showing {menuItemIds.length} matching items. &nbsp;
                                    <TappableSpan onTap={resetFilter}>
                                        <Text preset="g-14" mode="bold">
                                            Clear filter
                                        </Text>
                                    </TappableSpan>
                                </Text>
                            )}
                        </div>
                    )}
                </>
            ) : (
                <div key={"item" + relatedItem + categoryId} id={prefix + relatedItem + "_" + categoryId} ref={lineRef}>
                    <MenuItemButton menuItemId={relatedItem} categoryId={categoryId} className="menuitemcard-link">
                        <LazyLoadComponent visibleByDefault={visibleByDefault} threshold={600} useIntersectionObserver>
                            <MenuItemCardContainer
                                menuItemId={relatedItem}
                                showWaiterFavourite={true}
                                categoryId={categoryId}
                            />
                        </LazyLoadComponent>
                    </MenuItemButton>
                    <Divider small />
                </div>
            )}
        </ol>
    );
};
