import "./WizardModal.scss";

import React, { FC, ReactElement, useCallback, useEffect, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import { SimpleNavHeader } from "src/common/navigation";
import { StatusBar } from "src/common/statusBar";
import { LocationThemeContainer } from "src/features/location/container/LocationThemeContainer";
import classNames from "classnames";
import { Wizard } from "../wizard";
import { useDispatch, useSelector } from "react-redux";
import { SimpleNavHeaderProps, SimpleNavProps } from "../../../common/navigation/components/SimpleNavHeader";
import { usePrevious } from "../../../sharedComponents/common/shared";
import { ModalContainer } from "src/common/modal";
import { waitForKeyboardClose } from "../../../common/keyboard";
import { AppState } from "../../index";
import { WizardPageTransition, WizardTransition } from "../types";

type WizardPageRenderFunc = (onSuccess: () => void, standalone: boolean) => ReactElement;

export type FooterProps = { autoCloseDelay: number; onClose: () => void };

export interface WizardPage {
    render: WizardPageRenderFunc;
    header?: FC<SimpleNavProps> | null;
    footer?: FC<FooterProps> | null;
    headerProps?: Partial<SimpleNavHeaderProps>;
    onTransitionComplete?: () => void;
    customPage?: string;
    getAutoNextTrigger?: (state: AppState) => boolean;
    getAutoPrevTrigger?: (state: AppState) => boolean;
    autoClose?: boolean;
    autoCloseDelay?: number;
}

export interface Props<T extends string> {
    wizard: Wizard<T>;
    pages: { [page in T]: WizardPage };
    transition?: WizardTransition;
    pageTransition?: WizardPageTransition;
    customWizardName?: string;
    parentSelector?(): HTMLElement;
    onReset?: () => void;
    onComplete?: () => void;
    onOpenComplete?: () => void;
    onCloseComplete?: () => void;
}

const wizardTimes = {
    show: 250,
    pageTransition: {
        slide: 250,
        fade: 350,
    },
};

const defaultGetAutoNextTrigger = () => false;

interface WizardPageAutoNextProps<T extends string> {
    wizard: Wizard<T>;
    page: T;
    getAutoNextTrigger?: (state: AppState) => boolean;
}

const WizardPageAutoNext = <T extends string>({ wizard, page, getAutoNextTrigger }: WizardPageAutoNextProps<T>) => {
    const autoNextTrigger = useSelector(getAutoNextTrigger ?? defaultGetAutoNextTrigger);
    const dispatch = useDispatch();
    useEffect(() => {
        if (autoNextTrigger) {
            dispatch(wizard.actionCreators.next(page));
        }
    }, [dispatch, autoNextTrigger, wizard, page]);
    return null;
};

const WizardPageAutoPrev = <T extends string>({ wizard, page, getAutoNextTrigger }: WizardPageAutoNextProps<T>) => {
    const autoNextTrigger = useSelector(getAutoNextTrigger ?? defaultGetAutoNextTrigger);
    const dispatch = useDispatch();
    useEffect(() => {
        if (autoNextTrigger) {
            dispatch(wizard.actionCreators.prev(page));
        }
    }, [dispatch, autoNextTrigger, page, wizard]);
    return null;
};

export const WizardModal = <T extends string>(props: Props<T>) => {
    const {
        wizard,
        pages,
        transition = "slide-from-right",
        pageTransition = "slide-in",
        customWizardName,
        parentSelector,
        onReset,
        onComplete,
        onOpenComplete,
        onCloseComplete,
    } = props;

    const dispatch = useDispatch();
    const wizardState = useSelector(wizard.getState);
    const prevWizardState = usePrevious(wizardState);
    const { standalone, currentPage, startPage, direction } = wizardState ?? prevWizardState ?? {};
    const prevStandalone = usePrevious(standalone);
    const prevPage = usePrevious(currentPage);

    const onResetRef = useRef<() => void>();
    const onCompleteRef = useRef<() => void>();
    const unmountedRef = useRef(false);
    const timerRef = useRef<number>();
    const didOverrideStartTransitionRef = useRef(false);
    if (wizardState) {
        didOverrideStartTransitionRef.current = !!wizardState.options?.overrideStartTransition;
    }

    const afterClose = useCallback(() => {
        onResetRef.current?.();
        if (!unmountedRef.current) {
            // The wizard isn't "complete" if it just unmounted
            onCloseComplete?.();
            onCompleteRef.current?.();
        }
        onResetRef.current = onCompleteRef.current = undefined;
    }, [onCloseComplete]);

    const onAnimationEnd = useCallback(
        (event: AnimationEvent) => {
            if (!event.animationName.endsWith("-reverse")) {
                onOpenComplete?.();
            }
        },
        [onOpenComplete]
    );

    useEffect(() => {
        return () => {
            unmountedRef.current = true;
            clearTimeout(timerRef.current);
            dispatch(wizard.actionCreators.done());
        };
    }, [dispatch, wizard]);

    const prev = useCallback(() => {
        if (!standalone) {
            onResetRef.current = onReset;
            onCompleteRef.current = undefined;
        }
        waitForKeyboardClose().then(() => dispatch(wizard.actionCreators.prev()));
    }, [dispatch, wizard, onReset, standalone]);

    const next = useCallback(() => {
        if (!standalone) {
            onResetRef.current = undefined;
            onCompleteRef.current = onComplete;
        }
        waitForKeyboardClose().then(() => dispatch(wizard.actionCreators.next()));
    }, [dispatch, wizard, onComplete, standalone]);

    const done = useCallback(() => {
        if (!standalone) {
            onResetRef.current = undefined;
            onCompleteRef.current = onComplete;
        }
        dispatch(wizard.actionCreators.done());
    }, [dispatch, wizard, onComplete, standalone]);

    const closeWithDelay = useCallback(
        (delay = 1000) => {
            if (timerRef.current) return;
            timerRef.current = window.setTimeout(() => {
                dispatch(wizard.actionCreators.done());
                timerRef.current = undefined;
            }, delay);
        },
        [dispatch, wizard]
    );

    return (
        <ModalContainer
            isOpen={!!wizardState}
            parentSelector={parentSelector}
            className={{
                base: classNames(
                    "Wizard-modal",
                    `${wizard.name}-modal`,
                    customWizardName && `${customWizardName}-modal`
                ),
                afterOpen: classNames(
                    "Wizard-modal--after-open",
                    `${wizard.name}-modal--after-open`,
                    customWizardName && `${customWizardName}-modal--after-open`
                ),
                beforeClose: classNames(
                    "Wizard-modal--before-close",
                    `${wizard.name}-modal--before-close`,
                    customWizardName && `${customWizardName}-modal--before-close`
                ),
            }}
            bodyOpenClassName={`${wizard.name}-modal__Body--open`}
            overlayClassName={classNames(
                "Wizard-modal__Overlay",
                `${wizard.name}-modal__Overlay`,
                customWizardName && `${customWizardName}-modal__Overlay`,
                wizardState?.options?.overrideStartTransition ?? transition,
                !wizardState && didOverrideStartTransitionRef.current && "prevent-after-open-animation",
                currentPage && wizard.supportsKeyboardUnfixed(currentPage) && "keyboard-unfixed"
            )}
            onRequestClose={done}
            shouldCloseOnEsc
            closeTimeoutMS={wizardTimes.show}
            onAfterClose={afterClose}
            overlayRef={(instance) => {
                instance?.addEventListener("animationend", onAnimationEnd);
                instance?.addEventListener("animationcancel", onAnimationEnd);
            }}
        >
            <LocationThemeContainer>
                <StatusBar backgroundColor="#fff" />
                {Object.keys(pages).map((page) => {
                    const {
                        render,
                        header: Header = SimpleNavHeader,
                        headerProps: additionalHeaderProps,
                        footer: Footer,
                        onTransitionComplete,
                        customPage,
                        getAutoNextTrigger,
                        getAutoPrevTrigger,
                        autoClose,
                        autoCloseDelay,
                    } = pages[page];
                    const key = `wizard-${standalone ? "standalone-" : ""}${customPage ?? page}`;
                    const headerProps = {
                        ...additionalHeaderProps,
                        closeToBack: wizardState ? standalone || startPage === currentPage : undefined,
                        customBack: wizardState ? key : undefined,
                        onBack: wizardState ? (standalone ? done : prev) : undefined,
                        title: wizardState ? additionalHeaderProps?.title : undefined,
                    };
                    const isStandalonePage =
                        (standalone && currentPage === page) || (prevStandalone && prevPage === page);
                    const transition = (() => {
                        if (isStandalonePage) return "slide-up";
                        if (standalone || prevStandalone) return "none";
                        return pageTransition;
                    })();
                    if (currentPage === page && autoClose) {
                        closeWithDelay(autoCloseDelay);
                    }
                    return (
                        <React.Fragment key={page}>
                            <WizardPageAutoNext wizard={wizard} page={page} getAutoNextTrigger={getAutoNextTrigger} />
                            <WizardPageAutoPrev wizard={wizard} page={page} getAutoNextTrigger={getAutoPrevTrigger} />
                            <CSSTransition
                                in={currentPage === page}
                                classNames={classNames(
                                    `wizard-transition-${transition}`,
                                    `wizard-transition-${direction}`
                                )}
                                timeout={
                                    transition === "fade-in"
                                        ? wizardTimes.pageTransition.fade
                                        : wizardTimes.pageTransition.slide
                                }
                                unmountOnExit
                                appear
                                onEntered={onTransitionComplete}
                            >
                                <div className="wizard">
                                    {Header && <Header {...headerProps} />}
                                    {render(standalone ? done : next, standalone ?? prevStandalone ?? false)}
                                    {Footer && <Footer autoCloseDelay={autoCloseDelay} onClose={done} />}
                                </div>
                            </CSSTransition>
                        </React.Fragment>
                    );
                })}
            </LocationThemeContainer>
        </ModalContainer>
    );
};
