import "../assets/SignInWizard.scss";

import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import { SimpleNavHeader } from "src/common/navigation";
import { signInActions, SignInWizardPage, verifying } from "..";
import { MenuDataLocaleContext } from "src/features/menudata/context/MenuDataLocaleContext";
import { ModalContainer } from "src/common/modal";
import { SignInOptionsPage } from "./SignInOptionsPage";
import { getMenuDataLocale } from "../../menudata/selectors/getMenuDataLocale";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "../../accountmenu/selectors";
import {
    getNextSignInPage,
    getPrevSignInPage,
    getShowAccountUnverified,
    getSignInOptions,
    getSignInPage,
} from "../selectors";
import { waitForKeyboardClose } from "src/common/keyboard";
import { signInAction } from "../actions/signInAction";
import { EditPhonePage } from "./EditPhonePage";
import { VerifyPage } from "./VerifyPage";
import { AccountDetails } from "../../accounts/components/AccountDetails";
import { verifyOperation } from "../operations";
import { saveProfile } from "../../accountmenu";
import { SsoError } from "../util/SsoError";
import { showModalMessage } from "../../modalMessage/actions/show";
import { modalMessages } from "../../modalMessage/messages";
import { getSsoProvider, IdentityValidationRequest, SsoProvider } from "src/common/sso";
import classNames from "classnames";
import { getAuthState, getCurrentMemberId } from "../../accounts/selectors";
import { PrimaryButtonFunc } from "../../modalMessage/reducers/modalMessage";
import { SsoButton } from "./SsoButton";
import { Text } from "src/sharedComponents";
import { TappableAnchor } from "../../../sharedComponents/common/tappable";
import { sso } from "src/common/experience";
import { SignInOptions } from "../reducers/signIn";
import { createSelector } from "reselect";

const defaultGetSignInComplete = () => false;

export const SignInWizard = () => {
    const options = useSelector(getSignInOptions);
    const getNextPageAndSignInComplete = useMemo(
        () =>
            createSelector(
                getNextSignInPage,
                options?.getSignInComplete ?? defaultGetSignInComplete,
                (nextPage, signInComplete) => ({
                    nextPage: signInComplete ? null : nextPage,
                    signInComplete,
                })
            ),
        [options]
    );
    const page = useSelector(getSignInPage);
    const { nextPage, signInComplete } = useSelector(getNextPageAndSignInComplete);
    const prevPage = useSelector(getPrevSignInPage);
    const currentMemberId = useSelector(getCurrentMemberId);
    const menuDataLocale = useSelector(getMenuDataLocale);
    const verifyStatus = useSelector(verifyOperation.getStatus);
    const verifyError = useSelector(verifyOperation.getError);
    const profile = useSelector(getProfile);
    const authState = useSelector(getAuthState);
    const showAccountUnverified = useSelector(getShowAccountUnverified);

    const currentMemberIdRef = useRef(currentMemberId);
    const pageRef = useRef<SignInWizardPage>();
    const setPhoneNumberOnlyRef = useRef(false);
    if (page) {
        if (!pageRef.current) {
            currentMemberIdRef.current = currentMemberId;
        }
        if (page === "Phone") {
            setPhoneNumberOnlyRef.current = !!profile?.hasSsoIdentity && !options?.requireVerification;
        }
        pageRef.current = page;
    }
    const nextPageRef = useRef<SignInWizardPage | null>(nextPage);
    nextPageRef.current = nextPage;
    const prevPageRef = useRef<SignInWizardPage | null>(prevPage);
    prevPageRef.current = prevPage;
    const closeTimeoutMSRef = useRef(250);
    const isForwardsRef = useRef(true);
    const linkSsoRef = useRef(false);
    linkSsoRef.current =
        currentMemberId !== currentMemberIdRef.current && // user signed-in rather than creating account
        !profile?.hasSsoIdentity; // User signed in with phone

    const dispatch = useDispatch();

    const close = useCallback(() => {
        waitForKeyboardClose().then(() => {
            options?.onCancel?.();
            dispatch(signInActions.completeSignInFlow());
        });
    }, [dispatch, options]);

    const closeImmediately = useCallback(() => {
        closeTimeoutMSRef.current = 0;
        dispatch(signInActions.completeSignInFlow());
    }, [dispatch]);

    const complete = useCallback(() => {
        waitForKeyboardClose().then(() => {
            if (linkSsoRef.current && options?.completeAfterLinkSso) {
                dispatch(signInActions.completeSignInFlow());
                return;
            }
            options?.onComplete?.();
            if (!options?.getSignInComplete) {
                dispatch(signInActions.completeSignInFlow());
            }
        });
    }, [dispatch, options]);

    const cleanUp = useCallback(() => {
        pageRef.current = undefined;
        closeTimeoutMSRef.current = 250;
        isForwardsRef.current = true;
        document.body.classList.remove("sso-complete");
        dispatch(signInActions.setOptions(undefined));
    }, [dispatch]);

    const showPage = useCallback(
        (page: SignInWizardPage, isForwards: boolean = true) => {
            isForwardsRef.current = isForwards;
            waitForKeyboardClose().then(() => dispatch(signInActions.showPage(page)));
        },
        [dispatch]
    );

    const next = useCallback(() => {
        if (nextPageRef.current) {
            showPage(nextPageRef.current);
        } else {
            complete();
        }
    }, [showPage, complete]);

    const prev = useCallback(() => {
        if (prevPageRef.current) {
            showPage(prevPageRef.current, false);
        } else {
            close();
        }
    }, [showPage, close]);

    const verifySignInRequest = useCallback(
        (request: IdentityValidationRequest) => {
            dispatch(verifying(request));
        },
        [dispatch]
    );

    const savePhoneNumber = useCallback(
        (phone: string) => {
            dispatch(saveProfile({ ...profile, phone }, next));
        },
        [dispatch, profile, next]
    );

    const cleanUpAndTryLinkSsoIfRequired = useCallback(() => {
        cleanUp();
        if (linkSsoRef.current) {
            let linkOptions: SignInOptions | undefined;
            if (options?.completeAfterLinkSso && options.onComplete) {
                linkOptions = {
                    onComplete: options.onComplete,
                    onCancel: options.onComplete,
                };
            }
            const primaryAction = () => {
                dispatch(signInActions.trackAccountLinkResponse("link"));
                dispatch(signInAction(linkOptions));
            };
            const secondaryAction = () => {
                dispatch(signInActions.trackAccountLinkResponse("cancel"));
                if (options?.completeAfterLinkSso) {
                    options.onComplete?.();
                }
            };
            dispatch(showModalMessage(modalMessages.linkSso(primaryAction, secondaryAction)));
        }
    }, [dispatch, cleanUp, options]);

    useEffect(() => {
        if (showAccountUnverified) {
            setTimeout(() => {
                const primaryAction = () => {
                    dispatch(
                        signInAction({
                            requireVerification: true,
                        })
                    );
                };
                dispatch(showModalMessage(modalMessages.accountUnverified(primaryAction)));
            }, 250);
        }
    }, [dispatch, showAccountUnverified]);

    useEffect(() => {
        if (pageRef.current && signInComplete) {
            // Calling onComplete caused the state to change
            // indicating signin is complete
            document.body.classList.add("sso-complete");
            setTimeout(closeImmediately, 250);
        }
    }, [signInComplete, closeImmediately]);

    useEffect(() => {
        if (verifyStatus === "complete" && pageRef.current) {
            dispatch(verifyOperation.actionCreators.reset());
            next();
        }
    }, [dispatch, verifyStatus, next]);

    useEffect(() => {
        if (verifyError) {
            if (verifyError instanceof SsoError) {
                const ssoProvder = getSsoProvider(verifyError.source);
                const primaryButton: PrimaryButtonFunc | undefined = ssoProvder
                    ? (closeAndAction) => (
                          <SsoButton
                              ssoProvider={ssoProvder}
                              onSignIn={verifySignInRequest}
                              closeAndAction={closeAndAction}
                          />
                      )
                    : undefined;

                dispatch(
                    showModalMessage(modalMessages.ssoAccountExists(primaryButton, undefined, verifyError.maskedEmail))
                );
            } else if (pageRef.current !== "Verify") {
                dispatch(showModalMessage(modalMessages.loginFailed()));
            }
        }
    }, [dispatch, verifySignInRequest, verifyError]);

    useEffect(() => {
        // getIsAnonymous returns true by default before the authState has been set
        // but we want to make sure the user is really anonymous before calling init
        if (authState?.isAnonymous === true) {
            for (const [ssoProvider, ssoBehaviour] of Object.entries(sso)) {
                if (ssoBehaviour.isAvailable()) {
                    ssoBehaviour
                        .initialize?.()
                        .catch((err) =>
                            dispatch(signInActions.trackInitializeLoginSourceFailed(ssoProvider as SsoProvider, err))
                        );
                }
            }
        }
    }, [dispatch, authState]);

    const getTransition = (wizardPage: SignInWizardPage) => {
        if (pageRef.current === wizardPage) {
            return isForwardsRef.current ? "push" : "pop";
        }
        return isForwardsRef.current ? "pop" : "push";
    };

    const slideAnimation = options?.slideAnimation ?? "slide-in";
    const hasPrevPage = !!prevPage;

    return (
        <MenuDataLocaleContext.Provider value={menuDataLocale!}>
            <ModalContainer
                isOpen={!!page}
                className={{
                    base: `${slideAnimation} verify-wizard`,
                    afterOpen: `${slideAnimation}--after-open`,
                    beforeClose: `${slideAnimation}--before-close`,
                }}
                bodyOpenClassName="SignInWizard-modal__Body--open"
                overlayClassName={classNames("verify-wizard--overlay", `${slideAnimation}-modal--overlay`)}
                onRequestClose={close}
                shouldCloseOnEsc
                closeTimeoutMS={closeTimeoutMSRef.current}
                onAfterClose={cleanUpAndTryLinkSsoIfRequired}
                useLocationTheme={options?.useLocationTheme}
            >
                <CSSTransition
                    timeout={250}
                    classNames={`verify-wizard-transition--${getTransition("Options")}`}
                    in={pageRef.current === "Options"}
                    unmountOnExit
                >
                    <div className="verify-wizard__content">
                        <SimpleNavHeader
                            customBack="header/on-boarding-SIGN_IN_OPTIONS"
                            closeToBack={!options?.isMidWizard}
                            onBack={prev}
                        />
                        <SignInOptionsPage onContinueWithPhoneNumber={next} onSsoSignIn={verifySignInRequest} />
                    </div>
                </CSSTransition>
                <CSSTransition
                    timeout={250}
                    classNames={`verify-wizard-transition--${getTransition("Phone")}`}
                    in={pageRef.current === "Phone"}
                    unmountOnExit
                >
                    <div className="verify-wizard__content">
                        <SimpleNavHeader
                            customBack="header/on-boarding-LOGIN_OR_SIGN_UP"
                            closeToBack={!hasPrevPage && !options?.isMidWizard}
                            onBack={prev}
                        />
                        <EditPhonePage
                            onSubmit={setPhoneNumberOnlyRef.current ? savePhoneNumber : undefined}
                            buttonText="Continue"
                            subtitle={
                                setPhoneNumberOnlyRef.current
                                    ? "We need your number to text you any order updates."
                                    : ""
                            }
                            hidePlaceholder
                            hideLegalLinks
                            hideClear
                            onComplete={next}
                        />
                    </div>
                </CSSTransition>
                <CSSTransition
                    timeout={250}
                    classNames={`verify-wizard-transition--${getTransition("Verify")}`}
                    in={pageRef.current === "Verify"}
                    unmountOnExit
                >
                    <div className="verify-wizard__content">
                        <SimpleNavHeader
                            customBack="header/on-boarding-SECURE_ACCOUNT"
                            closeToBack={!hasPrevPage && !options?.isMidWizard}
                            onBack={prev}
                        />
                        <VerifyPage
                            title="Verify your number"
                            subtitle={(phone) => (
                                <>
                                    Enter the code sent to {phone}.<br />
                                    Wrong number?&nbsp;
                                    <TappableAnchor onTap={() => showPage("Phone", false)}>
                                        <Text
                                            preset="g-14"
                                            mode="bold"
                                            className="verify-page__description__different-number-link"
                                        >
                                            Use a different number
                                        </Text>
                                    </TappableAnchor>
                                </>
                            )}
                        />
                    </div>
                </CSSTransition>
                <CSSTransition
                    timeout={250}
                    classNames={`verify-wizard-transition--${getTransition("Details")}`}
                    in={pageRef.current === "Details"}
                    unmountOnExit
                >
                    <div className="verify-wizard__content">
                        <SimpleNavHeader
                            customBack="header/on-boarding-ADD_DETAILS"
                            closeToBack={!hasPrevPage && !options?.isMidWizard}
                            onBack={prev}
                        />
                        <AccountDetails
                            onSuccess={next}
                            flowName={options?.accountDetailsOptions?.flowName ?? "signin_wizard"}
                            hidePhone
                            title={options?.accountDetailsOptions?.accountDetailsTitle ?? "Add your details"}
                            subtitle={
                                options?.accountDetailsOptions?.subtitle ??
                                "Make sure your name matches your payment method. We’ll share your first name with the venue when you place your order."
                            }
                            submitText={options?.accountDetailsOptions?.submitText ?? "Continue"}
                            animateTopLevel
                            requireEmail={options?.accountDetailsOptions?.requireEmail}
                            hideEmailMessage={options?.accountDetailsOptions?.hideEmailMessage}
                            venueDetails={options?.accountDetailsOptions?.venueDetails}
                            emailMessage={options?.accountDetailsOptions?.emailMessage}
                        />
                    </div>
                </CSSTransition>
            </ModalContainer>
        </MenuDataLocaleContext.Provider>
    );
};
