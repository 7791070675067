import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { SimpleNavHeader } from "src/common/navigation";
import { LocationThemeContainer } from "src/features/location/container/LocationThemeContainer";
import { PaymentMethods } from "./PaymentMethods";
import { ModalContainer } from "src/common/modal";

interface Props {
    showPage: boolean;
    onClose?: () => void;
}

export const PaymentMethodsModal = ({ showPage, onClose }: Props) => {
    const [showWizard, setShowWizard] = useState(showPage);

    useEffect(() => {
        setShowWizard(showPage);
    }, [showPage]);

    useEffect(() => {
        return () => onClose && onClose();
    }, [onClose]);

    const close = () => {
        setShowWizard(false);
    };

    return (
        <ModalContainer
            isOpen={showWizard}
            className={{
                base: "PaymentWizard-modal slide-in",
                afterOpen: "PaymentWizard-modal--after-open slide-in--after-open",
                beforeClose: "PaymentWizard-modal--before-close slide-in--before-close",
            }}
            bodyOpenClassName="PaymentWizard-modal__Body--open"
            overlayClassName={classNames(
                "PaymentWizard-modal--overlay",
                "ReactModal__PaymentWizard",
                "slide-in-modal--overlay",
                "keyboard-unfixed"
            )}
            onRequestClose={close}
            onAfterClose={onClose}
            shouldCloseOnEsc
            closeTimeoutMS={250}
            portalClassName="PaymentMethodModalPortal ReactModalPortal"
        >
            <SimpleNavHeader
                key={"payment-wizard-PaymentMethodsModal"}
                closeToBack
                customBack={"payment-wizard-PaymentMethodsModal"}
                onBack={close}
            />
            <LocationThemeContainer>
                <div className="payment-wizard">
                    <PaymentMethods onSuccess={close} select />
                </div>
            </LocationThemeContainer>
        </ModalContainer>
    );
};
