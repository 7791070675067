import {
    GroupTabPaymentMethod,
    NewCardPaymentMethod,
    PaymentType,
    PosPaymentMethod,
    SelectablePaymentMethod,
} from "src/common/payment";
import { createSelector } from "reselect";
import { getShowGroupTabsWizard } from "../../groupTabs/selectors/groupTabWizardSelectors";
import { getShowManageModal } from "../../groupTabs/selectors/activeGroupTab";
import { getPaymentState } from "./paymentState";
import { getPaymentMethod } from "../util/getPaymentMethod";
import { getIsSectionTakeaway } from "src/features/order/selectors";
import { getHasPaymentOrder } from "src/features/payOnly/selectors/payOnlyState";

export const getSelectedPaymentMethod = createSelector(
    getPaymentState,
    ({ selectedPaymentMethod }) => selectedPaymentMethod
);

export const getPreselectedPaymentMethod = createSelector(
    getPaymentState,
    ({ preselectedPaymentMethod }) => preselectedPaymentMethod
);

export const getPaymentMethods = createSelector(getPaymentState, ({ paymentMethods }) => paymentMethods);

export const getNewCardPaymentMethod = createSelector(getPaymentMethods, (paymentMethods) =>
    getPaymentMethod<NewCardPaymentMethod>(paymentMethods, PaymentType.NEWCARD)
);

export const getCanUseGroupTabPaymentMethod = createSelector(
    getIsSectionTakeaway,
    getHasPaymentOrder,
    (isTakeaway, hasPaymentOrder) => !isTakeaway && !hasPaymentOrder
);

export const getGroupTabPaymentMethod = createSelector(
    getPaymentMethods,
    getShowGroupTabsWizard,
    getShowManageModal,
    getCanUseGroupTabPaymentMethod,
    (paymentMethods, showGroupTabsWizard, showManageGroupTab, canUseGroupTabPaymentMethod) =>
        showGroupTabsWizard || showManageGroupTab || !canUseGroupTabPaymentMethod
            ? null
            : getPaymentMethod<GroupTabPaymentMethod>(paymentMethods, PaymentType.GROUPTAB)
);

export const getListablePaymentMethods = createSelector(
    getPaymentMethods,
    (paymentMethods) =>
        (paymentMethods?.filter(
            (p) => p.paymentType !== PaymentType.NEWCARD && p.paymentType !== PaymentType.GROUPTAB
        ) as SelectablePaymentMethod[] | undefined) ?? null
);

export const getAddedCard = createSelector(getPaymentState, ({ addedCard }) => addedCard);

export const getAddedCardPaymentMethod = createSelector(getAddedCard, (addedCard) => addedCard?.paymentMethod ?? null);

export const getAddCardFormActive = createSelector(getPaymentState, ({ addCardFormActive }) => addCardFormActive);

export const getOpenTabInfo = createSelector(getPaymentState, ({ openTabInfo }) => openTabInfo);

export const getOpenTabPaymentMethod = createSelector(
    getOpenTabInfo,
    (openTabInfo) => openTabInfo?.paymentMethod ?? null
);

export const getPosPaymentMethod = createSelector(
    getPaymentMethods,
    getShowGroupTabsWizard,
    getShowManageModal,
    (paymentMethods, showGroupTabsWizard, showManageGroupTab) =>
        showGroupTabsWizard || showManageGroupTab
            ? null
            : getPaymentMethod<PosPaymentMethod>(paymentMethods, PaymentType.POS)
);
