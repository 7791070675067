import * as React from "react";
import { Redirect, Route, Switch } from "react-router";
import { PageTransition } from "src/sharedComponents";
import { ServicePageContainer } from "../../service";
import { MenuItemModalContainer } from "../../menuitem";
import { ProfileDrawerContainer } from "../../accountmenu/containers/ProfileDrawerContainer";
import { NavBarContainer } from "../../../common/navigation/containers/NavBarContainer";
import { LocationThemeContainer } from "../../location/container/LocationThemeContainer";
import { ToastOrderHistoryStatusContainer } from "../../orderHistoryItem/containers/ToastOrderHistoryStatusContainer";
import { History, Location } from "history";
import { ServiceChangeModal } from "../../service/components/ServiceChangeModal";
import { MenuSearchPage } from "src/features/menuSearch/components/MenuSearchPage";
import { ManageMembershipsModal } from "src/features/membership/components/ManageMembershipsModal";
import { GroupTabsView } from "src/features/groupTabs/components/GroupTabsView";
import { MenuPageTabs } from "../../menu/components/MenuPageTabs";
import { BannerNotifications } from "src/features/banners/components/BannerNotifications";
import { ViewCartButton } from "../../order/component/ViewCartButton";
import { CartViewModal } from "../../order/component/CartViewModal";
import { PaymentWizardContainer } from "../../payment/containers/PaymentWizardContainer";
import { ScheduleTakeawayModal } from "src/features/takeaway/components/ScheduleTakeawayModal";
import { OrderHoldingModal } from "../../openTable/components/OrderHoldingModal";
import { Pay } from "../../payOnly/components/Pay";
import { PayOnly } from "../../payOnly/components/PayOnly";
import { MyOffersPage } from "src/features/offers/components/MyOffersPage";

export interface Props {
    location: Location;
    noParty?: boolean;
    history: History;
    animatingPage?: (isServicePage: boolean) => void;
    isOpenTable?: boolean;
}

interface State {
    duringAnimation: boolean;
}

export class Party extends React.Component<Props, State> {
    renderMenu = () => {
        const { location, history, animatingPage } = this.props;
        return (
            <>
                <PageTransition
                    location={location}
                    transitionAction={history.action}
                    onLoad={() => animatingPage?.(location.pathname === "/menu/service")}
                >
                    <Switch location={location}>
                        <Route
                            path="/menu"
                            render={() => (
                                <Switch location={location}>
                                    <Route exact path="/menu/no-service" component={ServicePageContainer} />
                                    <Route exact path="/menu/service" component={ServicePageContainer} />
                                    <Route exact path="/menu/:menuId/:categoryId?" component={MenuPageTabs} />
                                </Switch>
                            )}
                        />
                        <Route path="/" component={() => <div />} />
                    </Switch>
                </PageTransition>
                <MenuItemModalContainer />
                <MenuSearchPage />
            </>
        );
    };

    render() {
        const { noParty, isOpenTable, location } = this.props;
        if (noParty) {
            // Being used by CMS Preview and Open Table
            return (
                <LocationThemeContainer>
                    {isOpenTable ? (
                        <Switch location={location}>
                            <Route path="/pay" component={PayOnly} />
                            <Redirect to="/pay" />
                        </Switch>
                    ) : (
                        this.renderMenu()
                    )}
                </LocationThemeContainer>
            );
        }
        return (
            <LocationThemeContainer>
                <div className="party-wrapper">
                    <div className="party-wrapper__page">
                        <Switch location={location}>
                            <Route path="/menu" render={this.renderMenu} />
                            <Route path="/pay" component={Pay} />
                        </Switch>
                        <ProfileDrawerContainer open={false} />
                        <GroupTabsView />
                        <MyOffersPage />
                        <ToastOrderHistoryStatusContainer />
                    </div>
                    <BannerNotifications />
                    <CartViewModal />
                    <PaymentWizardContainer />
                    <ViewCartButton />
                    <NavBarContainer />
                    <ManageMembershipsModal />
                    <ServiceChangeModal />
                    <ScheduleTakeawayModal />
                    {isOpenTable && <OrderHoldingModal />}
                </div>
            </LocationThemeContainer>
        );
    }
}
