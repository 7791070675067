import { AddedCardResult, GetAddedCardFunc, ProductFees } from "../../../payment";
import {
    getSpreedlyAddedCardForPaymentOperation,
    getSpreedlyAddedCardOperation,
    showSpreedlyAddCardOperation,
} from "../operations";
import { loadExternalScript, timeoutAndError } from "../../../../common/shared";
import { HostedField, hostedFieldOptions } from "../../../paymentMethods/types";
import { SpreedlyNewCardPaymentMethod } from "../types";
import { ShowAddCardFunc } from "../../types";
import { registerKeyboardClosed, registerKeyboardOpen } from "../../../../common/keyboard";
import { setHostedFieldValidity } from "../../../paymentMethods/actions/setHostedFieldValidity";
import moment, { Moment } from "moment";
import { getCardDisplayName } from "../../../payment/util/getCardDisplayName";
import { fetchProductFees } from "../../../payment/api/fetchProductFees";
import { AddedCardPaymentMethod, PaymentType } from "../../../../common/payment";

const fieldStyle = `
    color: ${hostedFieldOptions.color};
    font-family: ${hostedFieldOptions.fontFamily};
    font-size: ${hostedFieldOptions.fontSize};
    height: 1.2em;
    line-height: 1.2em;
    padding: 0;
    margin: 0;
    width: 100%;
    outline: none;
`
    .replace(/[\r\n]/g, "")
    .replace(/\s{2,}/g, " ")
    .trim();

export const showSpreedlyAddCard: ShowAddCardFunc = (dispatch, newCardPaymentMethod, inParty, memberId, profile) => {
    return showSpreedlyAddCardOperation.invoke(async () => {
        const { environmentKey } = newCardPaymentMethod as SpreedlyNewCardPaymentMethod;
        const expiryEl = document.getElementById(HostedField.Expiry) as HTMLInputElement;

        let innerResolve: (result: AddedCardResult | null) => void = () => {};
        let innerReject: (err: Error) => void = () => {};
        let innerIsPayment = false;
        let expiry: Moment | undefined;

        const initializeHostedFields = () =>
            new Promise<void>(async (resolve, reject) => {
                try {
                    await loadExternalScript("Spreedly", "https://core.spreedly.com/iframe/iframe-v1.min.js");

                    Spreedly.removeHandlers();

                    Spreedly.init(environmentKey, {
                        numberEl: HostedField.Number,
                        cvvEl: HostedField.CVV,
                    });

                    let prevIsAmex = false;
                    const prevError: { [el: string]: boolean } = {};

                    Spreedly.on("fieldEvent", (name, type, activeEl, inputProperties) => {
                        switch (type) {
                            case "focus":
                                return registerKeyboardOpen();
                            case "blur":
                                return registerKeyboardClosed();
                            case "input":
                                const { activeElement, cardType, numberLength, validNumber, cvvLength, validCvv } =
                                    inputProperties;

                                if (!activeElement) return;

                                const isNumber = activeElement === "number";
                                const isAmex = cardType === "american_express";

                                const valid = isNumber ? !!validNumber : !!validCvv;
                                const length = (isNumber ? numberLength : cvvLength) || 0;
                                let expectedLength: number;
                                if (isNumber) {
                                    expectedLength = cardType === "american_express" ? 15 : 16;
                                } else {
                                    expectedLength = cardType === "american_express" ? 4 : 3;
                                }
                                const error = !valid && length >= expectedLength;

                                if (prevError[activeElement] !== error) {
                                    Spreedly.setStyle(
                                        activeElement,
                                        `color: ${error ? hostedFieldOptions.errorColor : hostedFieldOptions.color}`
                                    );
                                    prevError[activeElement] = error;
                                }

                                if (prevIsAmex !== isAmex) {
                                    Spreedly.setPlaceholder(
                                        "cvv",
                                        isAmex
                                            ? hostedFieldOptions.placeholders.cvvAmex
                                            : hostedFieldOptions.placeholders.cvv
                                    );
                                    prevIsAmex = isAmex;
                                }

                                const field = isNumber ? HostedField.Number : HostedField.CVV;
                                return dispatch(setHostedFieldValidity(field, valid, error));
                        }
                    });

                    Spreedly.on("errors", (errors) => {
                        const message = errors.map((e) => e.message).join();
                        innerReject(new Error(message));
                    });

                    /*
                    {
                      "token": "J8hh7Xkc0yoPCUkWZev1eyOFFta",
                      "created_at": "2022-10-17T02:00:44Z",
                      "updated_at": "2022-10-17T02:00:44Z",
                      "email": null,
                      "data": null,
                      "storage_state": "cached",
                      "test": true,
                      "metadata": null,
                      "callback_url": null,
                      "last_four_digits": "1111",
                      "first_six_digits": "411111",
                      "card_type": "visa",
                      "first_name": null,
                      "last_name": null,
                      "month": 1,
                      "year": 2023,
                      "address1": null,
                      "address2": null,
                      "city": null,
                      "state": null,
                      "zip": null,
                      "country": null,
                      "phone_number": null,
                      "company": null,
                      "full_name": "",
                      "eligible_for_card_updater": true,
                      "shipping_address1": null,
                      "shipping_address2": null,
                      "shipping_city": null,
                      "shipping_state": null,
                      "shipping_zip": null,
                      "shipping_country": null,
                      "shipping_phone_number": null,
                      "issuer_identification_number": "41111111",
                      "click_to_pay": false,
                      "payment_method_type": "credit_card",
                      "errors": [

                      ],
                      "fingerprint": "a85f77dbac4a2ac7d171395d6ae07b46a9a0",
                      "verification_value": "XXX",
                      "number": "XXXX-XXXX-XXXX-1111"
                    }
                    */

                    Spreedly.on("paymentMethod", async (token, pmData) => {
                        const { card_type, last_four_digits } = pmData;

                        let productId: string;
                        if (card_type === "american_express") {
                            productId = "AMEX";
                        } else if (card_type === "master") {
                            productId = "MASTERCARD";
                        } else {
                            productId = card_type.toUpperCase();
                        }

                        let productFees: ProductFees | undefined;
                        if (innerIsPayment) {
                            productFees = await fetchProductFees(
                                dispatch,
                                newCardPaymentMethod.paymentGateway,
                                productId,
                                newCardPaymentMethod.currency,
                                null,
                                inParty
                            );
                        }

                        const paymentMethod: AddedCardPaymentMethod = {
                            ...newCardPaymentMethod,
                            paymentType: PaymentType.ADDEDCARD,
                            displayName: getCardDisplayName(card_type),
                            token,
                            maskedNumber: `•••• ${last_four_digits}`,
                            expirationDate: expiry!.format("MM/YY"),
                            productId,
                            processingFee: productFees?.feePercentage ?? 0,
                            processingFeeBaseAmount: productFees?.feeBaseAmount ?? 0,
                            remember: false,
                        };

                        innerResolve({ paymentMethod });
                    });

                    Spreedly.on("ready", () => {
                        Spreedly.setFieldType("number", "tel");
                        Spreedly.setPlaceholder("number", hostedFieldOptions.placeholders.cardNumber);
                        Spreedly.setStyle("number", fieldStyle);

                        Spreedly.setFieldType("cvv", "tel");
                        Spreedly.setPlaceholder("cvv", hostedFieldOptions.placeholders.cvv);
                        Spreedly.setStyle("cvv", fieldStyle);

                        Spreedly.setNumberFormat("prettyFormat");
                        (Spreedly as any).setParam("allow_blank_name", true);

                        resolve();
                    });
                } catch (err) {
                    reject(err);
                }
            });

        await Promise.race([
            initializeHostedFields(),
            timeoutAndError(hostedFieldOptions.initializeTimeout, "Timed out initializing Spreedly hosted fields"),
        ]);

        const getAddedCard: GetAddedCardFunc = (isPayment: boolean) =>
            (isPayment ? getSpreedlyAddedCardForPaymentOperation : getSpreedlyAddedCardOperation).invoke(
                () =>
                    new Promise<AddedCardResult | null>((resolve, reject) => {
                        innerResolve = resolve;
                        innerReject = reject;
                        innerIsPayment = isPayment;

                        expiry = moment(expiryEl.value, "MM / YY");

                        Spreedly.tokenizeCreditCard({
                            month: expiry.format("MM"),
                            year: expiry.format("YYYY"),
                            first_name: profile?.firstName ?? "",
                            last_name: profile?.lastName ?? "",
                            email: profile?.email,
                            phone_number: profile?.phone,
                            metadata: {
                                customer_id: memberId,
                            },
                        });
                    }),
                dispatch
            );

        return getAddedCard;
    }, dispatch);
};
