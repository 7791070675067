import "./PaymentMethods.scss";

import React, { PropsWithChildren, useEffect, useMemo, useRef, useState } from "react";
import {
    CardPaymentMethod,
    DevicePaymentMethod,
    isCardPaymentMethod,
    isDevicePaymentMethod,
    isDigitalWalletPaymentMethod,
    PaymentMethod as PaymentMethodType,
    PaymentType,
    SelectablePaymentMethod,
} from "../../../common/payment";
import { Button, Prompt, Text } from "src/sharedComponents";
import classNames from "classnames";
import { SelectPaymentMethodAction } from "./SelectPaymentMethodAction";
import { PaymentMethod } from "./PaymentMethod";
import { ViewPaymentMethodAction } from "./ViewPaymentMethodAction";
import { AddCardPaymentMethodAction } from "./AddCardPaymentMethodAction";
import { useDispatch, useSelector } from "react-redux";
import {
    getAddedCardPaymentMethod,
    getCanOpenTabInPaymentFlow,
    getGroupTabPaymentMethod,
    getListablePaymentMethods,
    getNewCardPaymentMethod,
    getOpenTabPaymentMethod,
    getPosPaymentMethod,
    getPreselectedPaymentMethod,
    getSelectedPaymentMethod,
} from "../../payment/selectors";
import { getPaymentMethods } from "../../payment/actions/getPaymentMethods";
import { actionCreators as paymentActionCreators } from "../../payment";
import { trackPaymentMethodSelected } from "../../payment/actions/trackPaymentMethodSelected";
import { initializePayPalCheckout } from "../../payment/actions/initializePayPalCheckoutButton";
import { initializeGooglePay } from "../../payment/actions/initializeGooglePayButton";
import { CircleArrowRightIcon, TabIcon } from "src/sharedComponents/assets/icons";
import { getParty, PartyType } from "../../order";
import { SelectCardPaymentMethodAction } from "./SelectCardPaymentMethodAction";
import { SelectPaymentMethodActions } from "../types";
import { paymentMethodsActionCreators } from "..";
import { capitaliseFirstLetter } from "src/common/formatter";
import { getTabTypeName } from "../../groupTabs/selectors/activeGroupTab";
import { NetworkConnectedButton } from "../../notifications/components/NetworkConnectedButton";
import { SelectPaymentMethodProvider } from "./SelectPaymentMethodContext";
import { SelectVaultDevicePaymentMethodAction } from "./SelectVaultDevicePaymentMethodAction";
import { googlePay } from "src/common/experience";

interface Props {
    edit?: boolean;
    select?: boolean;
    vaultCard?: boolean;
    showVaultCard?: boolean;
    showEnterCvv?: boolean;
    title?: string | null;
    buttonText?: string;
    onVaultCard?: () => void;
    onSuccess?: (paymentMethod: SelectablePaymentMethod) => void;
    filter?: (paymentMethod: PaymentMethodType) => boolean;
    onOpenTabClick?: () => void;
}

export const PaymentMethods = ({
    edit,
    select,
    vaultCard,
    showVaultCard,
    showEnterCvv,
    title,
    buttonText,
    onVaultCard,
    onSuccess,
    filter,
    onOpenTabClick,
    children,
}: PropsWithChildren<Props>) => {
    const dispatch = useDispatch();

    const paymentMethods = useSelector(getListablePaymentMethods);
    const selectedPaymentMethod = useSelector(getSelectedPaymentMethod);
    const preselectedPaymentMethod = useSelector(getPreselectedPaymentMethod);
    const newCardPaymentMethod = useSelector(getNewCardPaymentMethod);
    const groupTabPaymentMethod = useSelector(getGroupTabPaymentMethod);
    const addedCardPaymentMethod = useSelector(getAddedCardPaymentMethod);
    const openTabPaymentMethod = useSelector(getOpenTabPaymentMethod);
    const posPaymentMethod = useSelector(getPosPaymentMethod);
    const canOpenTabInPaymentFlow = useSelector(getCanOpenTabInPaymentFlow);
    const party = useSelector(getParty);
    const tabTypeName = useSelector(getTabTypeName);

    const [hasExternalOnClick, setHasExternalOnClick] = useState(false);
    const [externalIsValid, setExternalIsValid] = useState(true);
    const [tempSelectedPaymentMethod, setTempSelectedPaymentMethod] = useState<PaymentMethodType | null>(
        selectedPaymentMethod && filter?.(selectedPaymentMethod) !== false ? selectedPaymentMethod : null
    );

    const externalOnClick = useRef<(() => Promise<SelectablePaymentMethod | null>) | null>(null);
    const onClick = useRef(() => {});

    const actions = useMemo<SelectPaymentMethodActions>(
        () => ({
            setGetPaymentMethod(getPaymentMethod: (() => Promise<SelectablePaymentMethod | null>) | null) {
                externalOnClick.current = getPaymentMethod;
                setHasExternalOnClick(!!getPaymentMethod);
            },
            setIsValid(isValid: boolean) {
                setExternalIsValid(isValid);
            },
            parentOnClick() {
                onClick.current();
            },
        }),
        []
    );

    useEffect(() => {
        if (!paymentMethods) {
            dispatch(getPaymentMethods());
        }
    }, [dispatch, paymentMethods]);

    useEffect(() => {
        if (paymentMethods && !tempSelectedPaymentMethod && select) {
            if (preselectedPaymentMethod && filter?.(preselectedPaymentMethod) !== false) {
                setTempSelectedPaymentMethod(preselectedPaymentMethod);
                return;
            }

            if (groupTabPaymentMethod && filter?.(groupTabPaymentMethod) !== false) {
                setTempSelectedPaymentMethod(groupTabPaymentMethod);
                return;
            }

            if (posPaymentMethod && filter?.(posPaymentMethod) !== false) {
                setTempSelectedPaymentMethod(posPaymentMethod);
                return;
            }

            const filteredPaymentMethods = filter ? paymentMethods.filter(filter) : paymentMethods;
            const defaultablePaymentMethods = filteredPaymentMethods.filter(isDigitalWalletPaymentMethod);

            if (defaultablePaymentMethods.length) {
                setTempSelectedPaymentMethod(defaultablePaymentMethods[0]);
            } else if (
                !filteredPaymentMethods.length &&
                (!addedCardPaymentMethod || filter?.(addedCardPaymentMethod) === false) &&
                newCardPaymentMethod &&
                filter?.(newCardPaymentMethod) !== false
            ) {
                setTempSelectedPaymentMethod(newCardPaymentMethod);
            }
        }
        dispatch(paymentActionCreators.preselectPaymentMethod(null));
    }, [
        dispatch,
        paymentMethods,
        preselectedPaymentMethod,
        tempSelectedPaymentMethod,
        groupTabPaymentMethod,
        newCardPaymentMethod,
        addedCardPaymentMethod,
        posPaymentMethod,
        filter,
        select,
    ]);

    onClick.current = async () => {
        const paymentMethod = externalOnClick.current ? await externalOnClick.current() : tempSelectedPaymentMethod;
        if (!paymentMethod || paymentMethod.paymentType === PaymentType.NEWCARD) return;

        dispatch(paymentActionCreators.selectPaymentMethod(paymentMethod));
        dispatch(trackPaymentMethodSelected(paymentMethod, true));

        const cb = () => {
            onSuccess?.(paymentMethod);
        };

        if (paymentMethod.paymentType === PaymentType.PAYPALCHECKOUT) {
            dispatch(initializePayPalCheckout(paymentMethod, cb));
        } else if (paymentMethod.paymentType === PaymentType.GOOGLEPAY) {
            dispatch(initializeGooglePay(cb));
        } else {
            cb();
        }
    };

    useEffect(() => {
        if (canOpenTabInPaymentFlow && onOpenTabClick) {
            dispatch(paymentMethodsActionCreators.trackOpenTabPaymentMethodViewed());
        }
    }, [dispatch, canOpenTabInPaymentFlow, onOpenTabClick]);

    const buttonDisabled =
        !tempSelectedPaymentMethod ||
        (tempSelectedPaymentMethod.paymentType === PaymentType.NEWCARD && !hasExternalOnClick) ||
        (hasExternalOnClick && !externalIsValid);

    const customGooglePayButtonId =
        select && vaultCard && tempSelectedPaymentMethod?.paymentType === PaymentType.GOOGLEPAY
            ? googlePay.getCustomButtonId(tempSelectedPaymentMethod)
            : null;

    return (
        <SelectPaymentMethodProvider value={[tempSelectedPaymentMethod, setTempSelectedPaymentMethod]}>
            <div className="payment-methods">
                <div className={classNames("scroll-element", "animated-child", { "no-submit": !select })}>
                    {title !== null && (
                        <div className="payment-methods__title profile-page__title">
                            <Text preset="title-28" mode="bold">
                                {title ?? "Payment methods"}
                            </Text>
                        </div>
                    )}
                    {groupTabPaymentMethod && filter?.(groupTabPaymentMethod) !== false && (
                        <>
                            <Text
                                className="payment-methods__subtitle"
                                preset="g-18"
                                mode={["bold", "block"]}
                                value={select ? `Pay with ${tabTypeName}` : `${capitaliseFirstLetter(tabTypeName)}s`}
                            />
                            {select ? (
                                <SelectPaymentMethodAction
                                    key={groupTabPaymentMethod.token}
                                    paymentMethod={groupTabPaymentMethod}
                                    actions={actions}
                                />
                            ) : (
                                <PaymentMethod
                                    key={groupTabPaymentMethod.token}
                                    paymentMethod={groupTabPaymentMethod}
                                />
                            )}
                            <Text className="payment-methods__subtitle" preset="g-18" mode={["bold", "block"]}>
                                Other payment methods
                            </Text>
                        </>
                    )}
                    {canOpenTabInPaymentFlow && !!onOpenTabClick && (
                        <Prompt
                            title="Open a tab"
                            subtitle="Check out once & invite others to order with your tab."
                            leftIcon={<TabIcon />}
                            rightIcon={<CircleArrowRightIcon />}
                            className="payment-methods__prompt"
                            onClick={onOpenTabClick}
                        />
                    )}
                    {posPaymentMethod &&
                        filter?.(posPaymentMethod) !== false &&
                        (select ? (
                            <SelectPaymentMethodAction
                                key={posPaymentMethod.token}
                                paymentMethod={posPaymentMethod}
                                actions={actions}
                            />
                        ) : (
                            <PaymentMethod key={posPaymentMethod.token} paymentMethod={posPaymentMethod} />
                        ))}
                    {openTabPaymentMethod && select && edit && (
                        <SelectPaymentMethodAction
                            key={openTabPaymentMethod.displayName}
                            paymentMethod={openTabPaymentMethod}
                            actions={actions}
                        />
                    )}
                    {paymentMethods
                        ?.filter((p) => isDevicePaymentMethod(p) && filter?.(p) !== false)
                        .map((p) =>
                            select ? (
                                vaultCard ? (
                                    <SelectVaultDevicePaymentMethodAction
                                        key={p.token}
                                        paymentMethod={p as DevicePaymentMethod}
                                        actions={actions}
                                    />
                                ) : (
                                    <SelectPaymentMethodAction key={p.token} paymentMethod={p} actions={actions} />
                                )
                            ) : (
                                <ViewPaymentMethodAction key={p.token} paymentMethod={p} />
                            )
                        )}
                    {addedCardPaymentMethod &&
                        (select ? (
                            <SelectPaymentMethodAction
                                key={addedCardPaymentMethod.token}
                                paymentMethod={addedCardPaymentMethod}
                                actions={actions}
                            />
                        ) : (
                            <ViewPaymentMethodAction
                                key={addedCardPaymentMethod.token}
                                paymentMethod={addedCardPaymentMethod}
                            />
                        ))}
                    {paymentMethods
                        ?.filter((p) => isCardPaymentMethod(p) && filter?.(p) !== false)
                        .map((p) =>
                            select ? (
                                showEnterCvv ? (
                                    <SelectCardPaymentMethodAction
                                        key={p.token}
                                        paymentMethod={p as CardPaymentMethod}
                                        actions={actions}
                                    />
                                ) : (
                                    <SelectPaymentMethodAction key={p.token} paymentMethod={p} actions={actions} />
                                )
                            ) : (
                                <ViewPaymentMethodAction key={p.token} paymentMethod={p} />
                            )
                        )}
                    {newCardPaymentMethod && filter?.(newCardPaymentMethod) !== false && (
                        <AddCardPaymentMethodAction
                            select={select}
                            vaultCard={vaultCard}
                            showVaultCard={showVaultCard ?? select}
                            paymentMethod={newCardPaymentMethod}
                            actions={select ? actions : undefined}
                            onVaultCard={onVaultCard}
                        />
                    )}
                    {children}
                </div>
                {select && (
                    <div className="payment-methods__submit profile-page__submit">
                        <div
                            className={classNames(
                                "payment-methods__submit__container",
                                customGooglePayButtonId && "with-custom-google-pay-button"
                            )}
                        >
                            {party?.type === PartyType.PAYONLY ? (
                                <NetworkConnectedButton
                                    value={buttonText ?? (edit ? "Save changes" : "Select payment")}
                                    onClick={onClick.current}
                                    disabled={buttonDisabled}
                                />
                            ) : (
                                <>
                                    <Button
                                        value={buttonText ?? (edit ? "Save changes" : "Select payment")}
                                        onClick={onClick.current}
                                        disabled={buttonDisabled}
                                    />
                                    {customGooglePayButtonId && <div id={customGooglePayButtonId} />}
                                </>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </SelectPaymentMethodProvider>
    );
};
