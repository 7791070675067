import { PaymentGatewayBehaviours } from "../../interface";
import {
    getBraintreeApplePayAvailable,
    getBraintreeGooglePayAvailable,
    initializeBraintreeApplePayButton,
    initializeBraintreeGooglePayButton,
} from "./braintree";
import {
    completeStripeApplePayPayment,
    getStripeApplePayAvailable,
    getStripeGooglePayAvailable,
    initializeStripeApplePayButton,
    initializeStripeGooglePayButton,
} from "./stripe";

export const paymentGatewayBehaviours: PaymentGatewayBehaviours = {
    Braintree: {
        applePay: {
            getAvailable: getBraintreeApplePayAvailable,
            initializeButton: initializeBraintreeApplePayButton,
        },
        googlePay: {
            getAvailable: getBraintreeGooglePayAvailable,
            initializeButton: initializeBraintreeGooglePayButton,
        },
    },
    Tyro: {},
    Stripe: {
        applePay: {
            getAvailable: getStripeApplePayAvailable,
            initializeButton: initializeStripeApplePayButton,
            completePayment: completeStripeApplePayPayment,
        },
        googlePay: {
            getAvailable: getStripeGooglePayAvailable,
            initializeButton: initializeStripeGooglePayButton,
        },
    },
    GroupTab: {},
    Spreedly: {},
    Pos: {},
};
