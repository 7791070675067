import * as React from "react";
import { useCallback } from "react";
import { actionCreators as paymentActionCreators, GetAddedCardFunc } from "../../payment";
import { useDispatch, useSelector } from "react-redux";
import { showModalMessage } from "../../modalMessage/actions/show";
import { modalMessages } from "../../modalMessage/messages";
import { getParty } from "../../order";
import { NewCardPaymentMethod, SelectablePaymentMethod } from "../../../common/payment";
import { getAllHostedFieldsValid } from "../selectors/hostedFields";
import { addCard } from "../actions/addCard";
import { getPaymentMethods } from "../../payment/actions/getPaymentMethods";
import { trackPaymentMethodSelected } from "../../payment/actions/trackPaymentMethodSelected";
import { SelectPaymentMethodActions } from "../types";
import { AddCardBehaviour, ShowAddCardFunc } from "../../paymentGateways/types";
import { AddCardFormPaymentMethodAction } from "./AddCardFormPaymentMethodAction";
import { getProfile } from "../../accountmenu/selectors";
import { getCurrentMemberId } from "../../accounts/selectors";
import { actionCreators } from "../../spinnerModal/reducers/spinner";
import { usePaymentMethodSelectionContext } from "./SelectPaymentMethodContext";

interface Props {
    select?: boolean;
    vaultCard?: boolean;
    showVaultCard?: boolean;
    paymentMethod: NewCardPaymentMethod;
    actions?: SelectPaymentMethodActions;
    onVaultCard?: () => void;
}

export const AddCardPaymentMethodAction = ({
    select,
    vaultCard,
    showVaultCard,
    paymentMethod,
    actions,
    onVaultCard,
}: Props) => {
    const dispatch = useDispatch();

    const [, setTempSelectedPaymentMethod] = usePaymentMethodSelectionContext();

    const allHostedFieldsValid = useSelector(getAllHostedFieldsValid);
    const party = useSelector(getParty);
    const memberId = useSelector(getCurrentMemberId);
    const profile = useSelector(getProfile);

    const getRequiredBehaviour = useCallback((addCardBehaviour: AddCardBehaviour) => {
        return addCardBehaviour.showAddCard;
    }, []);

    const getResultFunc = useCallback(
        (showAddCard: ShowAddCardFunc) => {
            return showAddCard(dispatch, paymentMethod, !!party, memberId, profile);
        },
        [dispatch, paymentMethod, party, memberId, profile]
    );

    const getPaymentMethod = useCallback(
        (getAddedCard: GetAddedCardFunc, resetState: () => void, remember: boolean) =>
            new Promise<SelectablePaymentMethod | null>(async (resolve) => {
                const isSavingCard = !select || vaultCard;

                if (isSavingCard) dispatch(actionCreators.setIsLoading(true));

                const addedCardResult = await getAddedCard(!isSavingCard);

                if (addedCardResult === null) {
                    if (isSavingCard) dispatch(actionCreators.setIsLoading(false));
                    dispatch(showModalMessage(modalMessages.invalidCard()));
                    return resolve(null);
                }

                if (addedCardResult === undefined) {
                    // addedCardResult will be undefined if getAddedCard failed for some reason
                    // due to operation.invoke() swallowing the error and returning undefined
                    if (isSavingCard) dispatch(actionCreators.setIsLoading(false));
                    dispatch(showModalMessage(modalMessages.addCardFailed()));
                    return resolve(null);
                }

                const { paymentMethod: addedCard } = addedCardResult;
                addedCard.remember = remember;

                const onSuccess = () => {
                    if (isSavingCard) dispatch(actionCreators.setIsLoading(false));

                    if (select) {
                        dispatch(trackPaymentMethodSelected(addedCard, false));
                        if (!isSavingCard) setTempSelectedPaymentMethod(addedCard);
                    }

                    resetState();
                    setTimeout(() => resolve(addedCard), 500);
                };

                const onFailure = (rejected: boolean) => {
                    if (isSavingCard) dispatch(actionCreators.setIsLoading(false));
                    dispatch(showModalMessage(rejected ? modalMessages.invalidCard() : modalMessages.addCardFailed()));
                    resolve(null);
                };

                if (isSavingCard) {
                    dispatch(addCard(addedCardResult, () => dispatch(getPaymentMethods(onSuccess)), onFailure));
                    onVaultCard?.();
                } else {
                    dispatch(paymentActionCreators.addCard(addedCardResult));
                    onSuccess();
                }
            }),
        [dispatch, onVaultCard, setTempSelectedPaymentMethod, select, vaultCard]
    );

    return (
        <AddCardFormPaymentMethodAction
            paymentMethod={paymentMethod}
            getRequiredBehaviour={getRequiredBehaviour}
            getResultFunc={getResultFunc}
            getPaymentMethod={getPaymentMethod}
            isValid={allHostedFieldsValid}
            showVaultCard={showVaultCard}
            actions={actions}
            select={select}
        />
    );
};
