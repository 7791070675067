import { connect } from "react-redux";
import { AppState } from "src/features";
import { DrinkMenuItem, FoodMenuItem } from "../../menudata";
import { MenuItemCard, Props } from "../components/MenuItemCard";
import { getVisibleMenuData } from "../selectors";

export interface OwnProps {
    menuItemId: string;
    showWaiterFavourite: boolean;
    categoryId?: string;
    quantity?: number;
}

function mapStateToProps(state: AppState, { menuItemId, showWaiterFavourite, categoryId, quantity }: OwnProps): Props {
    const menuData = getVisibleMenuData(state);
    const menuItem = menuData!.items[menuItemId] as FoodMenuItem | DrinkMenuItem;
    const type = menuItem ? menuItem.type : "food";
    const showFromPrice = categoryId ? menuData!.categories[categoryId].showFromPrice : undefined;

    return {
        type,
        menuItemId,
        showWaiterFavourite,
        showFromPrice,
        quantity,
    };
}

export const MenuItemCardContainer = connect(mapStateToProps)(MenuItemCard);
