import "./PayOnly.scss";

import React, { useCallback, useEffect, useRef, useState } from "react";
import { Connecting } from "src/features/partyOnboarding/components/joinTable/Connecting";
import { getJoinPayOnlyStep } from "src/features/partyOnboarding/selectors";
import { CSSTransition } from "react-transition-group";
import { useDispatch, useSelector } from "react-redux";
import { getLatestTermsUpdate } from "src/features/marketing/actions/termsUpdate";
import classNames from "classnames";
import { RequireMenuDataContainer } from "src/features/party/containers/RequireMenuDataContainer";
import { RequirePartyContainer } from "src/features/party/containers/RequirePartyContainer";
import { scrolling, splashScreen } from "src/common/experience";
import { Text } from "../../../sharedComponents";
import { AlertIcon } from "src/sharedComponents/assets/icons";
import { OrderStatus } from "src/features/order";
import { getPayOnlyBillPaid, getPayOnlyPaymentOrder } from "../selectors";
import { PayOnlyHeaderPropsProvider } from "./PayOnlyHeader";
import { PayOnlyPaymentWizard } from "./PayOnlyPaymentWizard";
import { PayOnlyPaymentsFeedWizard } from "./PayOnlyPaymentsFeedWizard";
import { LocationThemeContainer } from "src/features/location/container/LocationThemeContainer";
import { PayOnlyActiveReceipt } from "./PayOnlyActiveReceipt";
import { PayOnlyPaidReceipt } from "./PayOnlyPaidReceipt";
import { Redirect } from "react-router";

export const PayOnly = () => {
    const dispatch = useDispatch();

    const order = useSelector(getPayOnlyPaymentOrder);
    const step = useSelector(getJoinPayOnlyStep);
    const hideSplashScreen = useRef(step !== "Initializing");
    const billPaid = useSelector(getPayOnlyBillPaid);

    const [disableUnseenPayments, setDisableUnseenPayments] = useState(false);
    const hideUnseenPayments = useCallback(() => setDisableUnseenPayments(true), []);
    const showUnseenPayments = useCallback(() => setDisableUnseenPayments(false), []);

    useEffect(() => {
        scrolling.scrollTo({ top: 0, behavior: "smooth" });
    }, [dispatch]);

    useEffect(() => {
        dispatch(getLatestTermsUpdate());
        window.history.pushState({ back: false }, "", null);
        document.body.classList.add("pay-only-party");

        return () => {
            document.body.classList.remove("pay-only-party");
            document.body.classList.remove("pay-only-party--completed");
        };
    }, [dispatch]);

    useEffect(() => {
        if (billPaid) {
            document.body.classList.add("pay-only-party--completed");
        }
    }, [dispatch, billPaid]);

    useEffect(() => {
        if (hideSplashScreen.current) {
            splashScreen.hide();
        }
    }, []);

    useEffect(() => {
        if (!hideSplashScreen.current && step !== "Initializing") {
            hideSplashScreen.current = true;
            splashScreen.hide();
        }
    }, [step]);

    const connecting = step === "Joining" || step === "Rejoining" || step === "Paused";
    const refreshFailed = order?.status === OrderStatus.INVALID;

    const RedirectToHome = () => (
        <Redirect to="/join-table" />
    );

    return (
        <div className={classNames("pay-only", connecting && "step-connecting", refreshFailed && "refresh-failed")}>
            <CSSTransition in={!connecting && step !== "Initializing"} classNames="fade" timeout={250} unmountOnExit>
                <RequireMenuDataContainer
                    success={() => (
                        <RequirePartyContainer
                            open={false}
                            success={() => (
                                <PayOnlyHeaderPropsProvider>
                                    <LocationThemeContainer>
                                        {!billPaid ? (
                                            <PayOnlyActiveReceipt disableUnseenPayments={disableUnseenPayments} />
                                        ) : (
                                            <PayOnlyPaidReceipt />
                                        )}
                                    </LocationThemeContainer>
                                    <PayOnlyPaymentWizard />
                                    <PayOnlyPaymentsFeedWizard
                                        onOpenComplete={hideUnseenPayments}
                                        onCloseComplete={showUnseenPayments}
                                    />
                                </PayOnlyHeaderPropsProvider>
                            )}
                            fallback={PayOnlyFailed}
                        />
                    )}
                    fallback={RedirectToHome}
                />
            </CSSTransition>
            <CSSTransition in={connecting} classNames="fade" timeout={250} unmountOnExit>
                <Connecting paused={step === "Paused"} isPayOnly />
            </CSSTransition>
        </div>
    );
};

const PayOnlyFailed = () => (
    <div className="pay-only__failed">
        <div>
            <AlertIcon />
        </div>
        <Text preset="title-24" mode="bold">
            Couldn’t connect to me&u
        </Text>
        <Text preset="g-14" mode="block">
            Try scanning the QR code again with your Camera app, or contact venue staff to complete payment.
        </Text>
    </div>
);
