import * as React from "react";
import { ModalActionMessage, ModalMessage, PrimaryButtonFunc, PrimaryComponentActions } from "./reducers/modalMessage";
import { Camera, ErrorIcon, LeaveLeftArrowIcon, RemoveIcon } from "src/sharedComponents/assets/icons";
import { __ } from "src/common/strings";
import { CameraAccessDeniedMessage } from "src/common/experience/interface";
import { Switch } from "src/sharedComponents/controls/switch";
import { ProblemDetails } from "../order/orderApi/ProblemDetailError";
import { GroupTabCloseReason, GroupTabErrorType, TabType } from "../groupTabs";
import { Divider, Text } from "src/sharedComponents";
import { ThumbsFeedback } from "../survey/components/ThumbsFeedback";
import { SurveyPage } from "../survey/components/SurveyPage";
import { PayOnlyGetReceipt } from "../payOnly/components/PayOnlyGetReceipt";
import { OpenTableOrderingOfflineMessage } from "../openTable/components/OpenTableOrderingDisabledPage";
import { NotificationType, PayOnlyNotificationDetails } from "src/features/payOnly/components/PayOnlyNotifications";
import { PreventLeavePartyReason } from "../openTable/types";
import { RewardEarned } from "../offers/components/RewardEarned";
import { GetHelpButton } from "src/features/modalMessage/components/GetHelpButton";

const unableToJoinThisTable: ModalMessage = {
    type: "info",
    title: __("Unable to start your order"),
    text: __("There was a problem starting your order."),
};

const getTabUnavailableMessage = (tabTypeName: string): ModalMessage => ({
    type: "info",
    title: __("Whoops, could not join {tabTypeName}!", { tabTypeName }),
    text: __("The {tabTypeName} you’re trying to join is not available.", { tabTypeName }),
    icon: <ErrorIcon />,
});

const notChargedText = "You have not been charged. ";

const joinTableMessages = {
    unableToJoinThisTable(): ModalMessage {
        return unableToJoinThisTable;
    },
    tableNotSetUp(): ModalMessage {
        return {
            ...unableToJoinThisTable,
            text: __("This QR code has not been correctly set up to order with me&u. Please alert venue staff."),
        };
    },
    outsideServiceHours(venueName?: string | null): ModalMessage {
        return {
            type: "info",
            title: __(
                "{venueName} is not accepting orders via me&u at this time. Please see our staff for assistance",
                { venueName: venueName ?? __("This venue") }
            ),
            text: __("This venue is currently outside service hours."),
        };
    },
    tableUnavailable(showNotCharged?: boolean): ModalMessage {
        return {
            type: "info",
            title: __("This section is not accepting orders via me&u at this time."),
            text: __(`${showNotCharged ? notChargedText : ""}Please see our staff for assistance.`),
        };
    },
    accountInArrears(primaryAction: () => void): ModalMessage {
        return {
            type: "info",
            title: __("Account locked"),
            text: __(
                "Your account has been locked due to a failed payment. Please contact me&u to unlock your account."
            ),
            primaryAction,
            primaryActionText: __("Contact us"),
        };
    },
    venueNotFound(): ModalMessage {
        return {
            ...unableToJoinThisTable,
            text: __("Venue not found."),
        };
    },
    unsupportedApiVersion(): ModalMessage {
        return {
            type: "info",
            title: __("Update needed"),
            text: __("To continue using me&u please update to the latest version of the app."),
        };
    },
    findQrCodeTimeout(): ModalMessage {
        return {
            type: "info",
            title: __("No QR code found"),
            text: __("We weren’t able to find a QR code. Try scanning the QR code with your default camera app."),
        };
    },
    showQrCodeUnknownError(): ModalMessage {
        return {
            type: "info",
            title: __("Can’t scan for QR codes"),
            text: __("Try scanning the QR code with your default camera app."),
        };
    },
    showQrCodeCameraAccessDenied(message: CameraAccessDeniedMessage): ModalMessage {
        return {
            ...message,
            type: "info",
            title: __("Can’t access camera"),
        };
    },
    takeawayUnavailable(venueName?: string | null): ModalMessage {
        return {
            type: "info",
            title: __("Takeaway unavailable"),
            text: __(
                "{venueName} isn’t accepting takeaway orders with me&u right now. Please contact the venue for assistance.",
                { venueName: venueName ?? __("This venue") }
            ),
        };
    },
    getOrCreateTableOrderFailure(): ModalMessage {
        return {
            type: "info",
            title: __("Please wait for your server"),
            text: __("Please contact our staff to start ordering."),
        };
    },
    unableToJoinFlexTab(): ModalMessage {
        return {
            type: "info",
            title: __("Unable to join tab"),
            text: __("Please reach out to the person who shared this tab or ask staff for assistance."),
        };
    },
};

const connectivityMessages = {
    lostConnection(): ModalMessage {
        return {
            type: "info",
            title: __("Lost connection to venue"),
            text: __("We can’t reach the venue right now. Please order directly with the venue or try again later."),
        };
    },
    noNetworkFound(): ModalMessage {
        return {
            type: "info",
            title: __("No network found"),
            text: __("Please check your data settings or network signal and try again."),
        };
    },
};

const groupTabsMessages = {
    closeTab(primaryAction: () => void, tabTypeName: string, tabType: TabType = TabType.PAYONCREATE): ModalMessage {
        const text =
            tabType === TabType.PAYONCREATE
                ? "Any remaining funds will be refunded to your account. Depending on your bank, it may take a few days for your account to be updated."
                : `Closing your ${tabTypeName} will remove any ${tabTypeName} members, and you will no longer be able to order using your ${tabTypeName}.`;

        return {
            type: "action",
            title: __("Close your {tabTypeName}?", { tabTypeName }),
            text: __("{text}", { text }),
            useLocationTheme: true,
            showCancel: true,
            primaryAction,
            primaryActionText: __("Close {tabTypeName}", { tabTypeName }),
        };
    },
    leaveTab(primaryAction: () => void, tabTypeName: string): ModalMessage {
        return {
            type: "action",
            title: __("Are you sure you want to leave this {tabTypeName}?", { tabTypeName }),
            text: __("You will no longer be able to use this {tabTypeName} as a payment method.", { tabTypeName }),
            useLocationTheme: true,
            showCancel: true,
            primaryAction,
            primaryActionText: __("Leave {tabTypeName}", { tabTypeName }),
            primaryActionIcon: <LeaveLeftArrowIcon />,
        };
    },
    removeMember(
        memberFirstName: string,
        memberLastName: string,
        primaryAction: () => void,
        tabTypeName: string
    ): ModalMessage {
        return {
            type: "action",
            title: __("Remove {memberFirstName}?", { memberFirstName }),
            text: __(
                "{memberFirstName} {memberLastName} will be removed from your {tabTypeName} and won’t be able to join again.",
                { memberFirstName, memberLastName, tabTypeName }
            ),
            useLocationTheme: true,
            showCancel: true,
            primaryAction,
            primaryActionText: __("Remove member"),
        };
    },
    joiningGroupTab(primaryAction: () => void, tabTypeName: string): ModalMessage {
        return {
            type: "action",
            title: __("Joining a {tabTypeName}", { tabTypeName }),
            text: __("Join this {tabTypeName} by scanning the QR code. Order & pay from the {tabTypeName}.", {
                tabTypeName,
            }),
            primaryAction,
            primaryActionText: __("Scan QR code"),
            primaryActionIcon: <Camera />,
            mustAction: true,
        };
    },
    partOfGroupTabCreate(
        beforeAction: string,
        primaryAction: () => void,
        primaryActionText: string,
        tabTypeName: string,
        secondaryAction?: () => void,
        isOpenTable?: boolean
    ): ModalMessage {
        const actionText = primaryActionText || `Leave ${tabTypeName} & continue`;
        return {
            type: "action",
            title: __(isOpenTable ? `Leave your current ${tabTypeName}?` : `You are on another ${tabTypeName}`, {
                tabTypeName,
            }),
            text: __(
                isOpenTable
                    ? `You need to leave your current ${tabTypeName} before starting this order`
                    : `By ${beforeAction} a new ${tabTypeName} you will be removed from your current one.`,
                {
                    beforeAction,
                    tabTypeName,
                }
            ),
            primaryAction,
            primaryActionText: __("{actionText}", { actionText }),
            secondaryAction,
            secondaryActionText: __(isOpenTable ? "Cancel" : `Stay on current ${tabTypeName}`, { tabTypeName }),
            useLocationTheme: true,
        };
    },
    ownAnotherGroupTab(
        totalSpend: string,
        remainingFunds: string,
        beforeAction: string = "creating",
        primaryAction: () => void,
        tabTypeName: string,
        tabType: TabType,
        primaryActionText: string,
        secondaryAction?: () => void,
        secondaryActionText: string = "Dismiss",
        isOpenTable?: boolean
    ): ModalMessage {
        const isPayOnCreateTab = tabType === TabType.PAYONCREATE;
        return {
            type: "action",
            title: __("You already have an open {tabTypeName}", { tabTypeName }),
            text: (
                <>
                    <Text preset="g-14">
                        {isOpenTable
                            ? `You need to close your current ${tabTypeName} before starting this order.`
                            : `Before ${beforeAction} a new ${tabTypeName}, you need to close your previous one.`}
                        {isPayOnCreateTab && " Any remaining funds will be refunded to your payment method."}
                    </Text>
                    {isPayOnCreateTab && (
                        <>
                            <Divider fullWidth />
                            <div style={{ display: "flex", justifyContent: "space-between", margin: "12px 0" }}>
                                <Text preset="g-14">Spend total</Text>
                                <Text preset="g-14">{totalSpend}</Text>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between", margin: "0 0 12px" }}>
                                <Text preset="g-18" mode="bold">
                                    Remaining funds
                                </Text>
                                <Text preset="g-18" mode="bold">
                                    {remainingFunds}
                                </Text>
                            </div>
                        </>
                    )}
                </>
            ),
            primaryAction,
            primaryActionText: __("{primaryActionText}", { primaryActionText }),
            secondaryAction,
            secondaryActionText: __("{secondaryActionText}", { secondaryActionText }),
            useLocationTheme: true,
        };
    },
    tabUnavailable(primaryAction: () => void, tabTypeName: string): ModalMessage {
        return {
            ...getTabUnavailableMessage(tabTypeName),
            type: "info",
            primaryAction,
            mustAction: true,
        };
    },
    knownFailureGroupTab(problemDetails: ProblemDetails, tabTypeName: string): ModalMessage {
        switch (problemDetails?.type) {
            case GroupTabErrorType.JOIN_ATTEMPT_LIMIT:
            case GroupTabErrorType.JOIN_NOT_FOUND:
                return {
                    type: "info",
                    title: `Whoops, ${tabTypeName} not found!`,
                    text: `Please check with the ${tabTypeName} owner.`,
                };
            case GroupTabErrorType.JOIN_FAILED:
                return getTabUnavailableMessage(tabTypeName);
            case GroupTabErrorType.MEMBER_JOIN_TAB:
                return {
                    type: "info",
                    title: `You are on another ${tabTypeName}`,
                    text: `By joining a new ${tabTypeName} you will be removed from your current one.`,
                };
            case GroupTabErrorType.OWNER_JOIN_TAB:
                return {
                    type: "info",
                    title: `You already have an open ${tabTypeName}`,
                    text: `Before joining another ${tabTypeName}, you need to close your previous one.`,
                };
            case GroupTabErrorType.OPEN_FAILED:
                return {
                    type: "info",
                    icon: <ErrorIcon />,
                    title: problemDetails.title,
                    text: `Failed to open a new ${tabTypeName}`,
                };
            case GroupTabErrorType.OWNER_CREATE_TAB:
                return {
                    type: "info",
                    title: `You already have an open ${tabTypeName}`,
                    text: `Before joining another ${tabTypeName}, you need to close your previous one.`,
                };
            case GroupTabErrorType.INFO:
                return {
                    type: "info",
                    title: problemDetails.title,
                    text: problemDetails.detail,
                };
        }
        return {
            type: "info",
            icon: <ErrorIcon />,
            title: problemDetails.title,
            text: problemDetails.detail,
        };
    },
    tabTipFailedPayment(
        tabTypeName: string,
        reason: string,
        primaryAction: () => void,
        secondaryAction: () => void
    ): ModalMessage {
        return {
            type: "info",
            icon: <ErrorIcon />,
            title: __("An issue occured with the {tabTypeName}’s payment method", { tabTypeName }),
            text: __("Your tip was not added due to a payment issue.", { tabTypeName }),
            primaryAction,
            importantText: __("Reason: {reason}", { reason }),
            mustAction: true,
            primaryActionText: __("Continue without tipping"),
            secondaryActionText: __("Try again"),
            secondaryAction,
        };
    },
    groupTabClosed(tabTypeName: string, primaryAction?: () => void): ModalMessage {
        return {
            type: "info",
            icon: <ErrorIcon />,
            title: __("You’re no longer in a {tabTypeName}", { tabTypeName }),
            text: __("The owner has closed the {tabTypeName}.", { tabTypeName }),
            primaryAction,
            mustAction: !!primaryAction,
        };
    },
    groupTabMemberRemoved(tabTypeName: string, primaryAction?: () => void): ModalMessage {
        return {
            type: "info",
            icon: <ErrorIcon />,
            title: __("You’re no longer in a {tabTypeName}", { tabTypeName }),
            text: __("The owner has removed you from the {tabTypeName}.", { tabTypeName }),
            primaryAction,
            mustAction: !!primaryAction,
        };
    },
    groupTabClosedOwner(closeReason: GroupTabCloseReason, tabTypeName: string): ModalMessage {
        const text =
            closeReason === GroupTabCloseReason.VENUE
                ? __("Please talk to venue staff for more information.")
                : __("Your {tabTypeName} auto closed due to insufficient funds.", { tabTypeName });

        return {
            type: "info",
            icon: <ErrorIcon />,
            title: __("Your {tabTypeName} was closed", { tabTypeName }),
            text,
        };
    },
    groupTabSwitchToFullMenu(primaryAction: () => void, tabTypeName: string): ModalMessage {
        return {
            type: "action",
            title: __("Are you sure you want to swap to the full menu?"),
            text: __("By swapping to the full menu, you will no longer be able to pay with the {tabTypeName}.", {
                tabTypeName,
            }),
            primaryAction,
            primaryActionText: __("Swap to the full menu"),
            useLocationTheme: true,
            showCancel: true,
        };
    },
    groupTabSwitchToLimitedMenu(primaryAction: () => void, tabTypeName: string): ModalMessage {
        return {
            type: "action",
            title: __("Are you sure you want to swap to the {tabTypeName} menu?", { tabTypeName }),
            text: __(
                "By swapping to the {tabTypeName} menu, you will have access to a limited menu. Any items in your cart will be removed.",
                { tabTypeName }
            ),
            primaryAction,
            primaryActionText: __("Swap to {tabTypeName} menu", { tabTypeName }),
            useLocationTheme: true,
            showCancel: true,
        };
    },
    groupTabUnavailableWithTakeaway(tabTypeName: string): ModalMessage {
        return {
            type: "info",
            title: __("{tabTypeName} unavailable", { tabTypeName }),
            text: __(
                "Takeaway orders can’t be placed on {tabTypeName}. You’ll need to select a different payment method.",
                { tabTypeName }
            ),
        };
    },
};

const orderMessages = {
    timedOut(): ModalMessage {
        return {
            type: "info",
            title: __("Your previous order was closed"),
            text: __("Due to inactivity, we’ve cleared your active order."),
        };
    },
    tableClosed(showNotCharged?: boolean): ModalMessage {
        return {
            type: "info",
            title: __("Your order has been closed"),
            text: __(
                `${showNotCharged ? notChargedText : ""}Thank you for dining with me&u - the new way to order & pay.`
            ),
        };
    },
    tableClosedNotFound(tableLabel: string, showNotCharged?: boolean): ModalMessage {
        return {
            type: "info",
            title: __("We are unable to find your orders"),
            text: __(
                `${
                    showNotCharged ? notChargedText : ""
                }They may have been moved to another {tableLabel}. Please ask venue staff for assistance.`,
                { tableLabel }
            ),
        };
    },
    tableOrSectionDisabled(venueName?: string | null, showNotCharged?: boolean, tableLabel?: string): ModalMessage {
        return {
            type: "info",
            title: __("{venueName} isn’t taking any more orders or payments from this {tableLabel}", {
                venueName: venueName ?? __("This venue"),
                tableLabel: tableLabel ?? __("QR code"),
            }),
            text: __(`${showNotCharged ? notChargedText : ""}Please ask venue staff for assistance.`),
        };
    },
    venueClosed(venueName?: string | null): ModalMessage {
        return {
            type: "info",
            title: __("{venueName} is now closed", { venueName: venueName ?? __("This venue") }),
            text: __("You have not been charged. Thank you for dining with me&u - the new way to order & pay."),
        };
    },
    validationFailed(): ModalMessage {
        return {
            type: "info",
            icon: <ErrorIcon />,
            title: __("Cannot confirm order"),
            text: __(
                "We’re having difficulties confirming some of your items with the venue. Please remove these from your order to continue."
            ),
            primaryActionText: __("OK"),
        };
    },
    itemsRejected(): ModalMessage {
        return {
            type: "info",
            icon: <ErrorIcon />,
            title: __("Item not available"),
            text: __(
                "Unfortunately some of your items are no longer available. Please remove them from your order to continue."
            ),
            primaryActionText: __("OK"),
        };
    },
    offersChanged(): ModalMessage {
        return {
            type: "info",
            icon: <ErrorIcon />,
            title: __("Your offer has changed"),
            text: __(
                "The offer you were redeeming no longer qualifies for this order. Please review the changes to your order and try again."
            ),
            primaryActionText: __("View changes"),
        };
    },
    reviewOrder(): ModalMessage {
        return {
            type: "info",
            icon: <ErrorIcon />,
            title: __("Your order may have changed"),
            text: __("Please review your order summary and try again."),
            primaryActionText: __("View changes"),
        };
    },
    paymentFailed(reason: string, primaryAction?: () => void): ModalMessage {
        return {
            type: "info",
            icon: <ErrorIcon />,
            title: __("Payment failed"),
            text: __("Please review your payment method and try again."),
            importantText: __("Reason: {reason}", { reason }),
            primaryAction,
            mustAction: !!primaryAction,
        };
    },
    paymentFailedGroupTabInsufficientFunds(
        tabType: TabType,
        tabTypeName: string,
        primaryAction?: () => void
    ): ModalMessage {
        const title = tabType === TabType.PAYONORDER ? "Spending limit reached" : "Insufficient funds";
        return {
            type: "info",
            icon: <ErrorIcon />,
            title: __("{title}", { title }),
            text: __("This {tabTypeName} does not have the available funds to complete your order.", { tabTypeName }),
            primaryAction,
        };
    },
    paymentFailedGroupTabClosed(primaryAction: () => void, tabTypeName: string): ModalMessage {
        return {
            type: "info",
            icon: <ErrorIcon />,
            title: __("Oh no, this {tabTypeName} has been closed!", { tabTypeName }),
            text: __("The {tabTypeName} is no longer available, please select another payment method.", {
                tabTypeName,
            }),
            primaryAction,
            mustAction: true,
        };
    },
    paymentFailedGroupTabMemberRemoved(primaryAction: () => void, tabTypeName: string): ModalMessage {
        return {
            type: "info",
            icon: <ErrorIcon />,
            title: __("Oh no, you’re no longer in a {tabTypeName}!", { tabTypeName }),
            text: __("You have been removed from this {tabTypeName}, please select another payment method.", {
                tabTypeName,
            }),
            primaryAction,
            mustAction: true,
        };
    },
    paymentFailedGroupTabPaymentMethod(tabTypeName: string, reason?: string, primaryAction?: () => void): ModalMessage {
        return {
            type: "info",
            icon: <ErrorIcon />,
            title: __("An issue occured with the {tabTypeName}’s payment method", { tabTypeName }),
            text: __("Your order was not able to be placed. Please use another payment method."),
            importantText: reason && __("Reason: {reason}", { reason }),
            primaryAction,
            mustAction: !!primaryAction,
        };
    },
    verifyCardFailed(primaryAction?: () => void): ModalMessage {
        return this.paymentFailed(__("Card holder verification failed"), primaryAction);
    },
    verifyCardRequired(primaryAction?: () => void): ModalMessage {
        return {
            type: "info",
            icon: <ErrorIcon />,
            title: __("Payment cancelled"),
            text: __("Please retry your payment so your payment method can be verified."),
            importantText: __("Reason: Card holder verification required"),
            primaryAction,
            mustAction: true,
        };
    },
    verifyCardCancelled(primaryAction?: () => void): ModalMessage {
        return {
            type: "info",
            icon: <ErrorIcon />,
            title: __("Payment cancelled"),
            text: __("We were unable to verify your payment method. Please try again."),
            importantText: __("Reason: Card holder verification cancelled"),
            primaryAction,
            mustAction: true,
        };
    },
    someItemsFailedToSend(primaryAction?: () => void, primaryActionText?: string): ModalMessage {
        return {
            type: "info",
            icon: <ErrorIcon />,
            title: __("Some items did not send"),
            text: __(
                "Some items were not sent, but you’ve been charged for the full amount. Alert venue staff to ensure you receive all of your items."
            ),
            primaryAction,
            primaryActionText,
            useLocationTheme: !primaryAction,
            fromServer: true,
        };
    },
    orderNotConfirmed(primaryAction?: () => void, primaryActionText?: string): ModalMessage {
        return {
            type: "info",
            icon: <ErrorIcon />,
            title: __("Your order can’t be confirmed"),
            text: __("Alert venue staff to ensure you receive all of your items."),
            primaryAction,
            primaryActionText,
            fromServer: true,
        };
    },
    failedToSubmit(): ModalMessage {
        return {
            type: "info",
            icon: <ErrorIcon />,
            title: __("Your order was not sent"),
            text: __("Venue failed to accept order."),
            useLocationTheme: true,
        };
    },
    orderUpdated(): ModalMessage {
        return {
            type: "info",
            title: __("Your order has been updated"),
            text: __("One or more of your selected modifiers are no longer available."),
        };
    },
    orderSending(): ModalMessage {
        return {
            type: "progress",
            title: __("Sending order"),
        };
    },
    confirmAgeRestriction(
        venueName: string | undefined,
        ageRestriction: number,
        primaryAction: () => void,
        secondaryAction: ModalMessage
    ): ModalMessage {
        return {
            type: "action",
            title: __("Are you over {ageRestriction}?", { ageRestriction }),
            text: __(
                "{venueName} requires you to confirm your age before ordering. Venue staff may request proof of age.",
                { venueName: venueName ?? __("This venue") }
            ),
            primaryAction,
            primaryActionText: __("I am over {ageRestriction}", { ageRestriction }),
            secondaryAction,
            secondaryActionText: __("No, I am underage"),
            useLocationTheme: true,
        };
    },
    showBespokeModal(title: string | undefined, text: string | undefined): ModalMessage {
        return {
            type: "info",
            title,
            text,
            titlePreset: "title-20",
        };
    },
    underage(venueName: string | undefined, primaryAction: () => void, ageRestriction: number): ModalMessage {
        return {
            type: "action",
            title: __("You must be over {ageRestriction}", { ageRestriction }),
            text: __("Unfortunately, you need to be over {ageRestriction} to order at {venueName}", {
                venueName: venueName ?? __("this venue"),
                ageRestriction,
            }),
            primaryAction,
            mustAction: true,
            useLocationTheme: true,
        };
    },
    minimumOrderValue(minimumOrderValue: string, primaryAction: () => void): ModalMessage {
        return {
            type: "info",
            title: __("Minimum order value required"),
            text: __(
                "A minimum order of {minimumOrderValue} is required. Please add more items to your cart, or seek assistance from our staff.",
                {
                    minimumOrderValue,
                }
            ),
            primaryAction,
            primaryActionText: __("Add more items"),
        };
    },
    cannotConfirmPrices(): ModalMessage {
        return {
            type: "info",
            title: __("Unable to confirm prices"),
            icon: <ErrorIcon />,
            text: __("We were unable to confirm the prices of some of your items with the venue. Please try again."),
        };
    },
    newTakeawayMenuChange(primaryAction: () => void, secondaryAction: () => void): ModalMessage {
        return {
            type: "info",
            title: __("Menu change"),
            text: __("We will update any items in your cart that change price or become unavailable."),
            primaryActionText: __("Don’t change time"),
            primaryAction,
            secondaryActionText: __("Change pickup time"),
            secondaryAction,
        };
    },
    selectedTakeawayTimeExpired(primaryAction: () => void, secondaryAction: () => void): ModalMessage {
        return {
            type: "info",
            title: __("Time’s up!"),
            text: __(
                "Your chosen time is no longer available, please choose a new time to pick up your takeaway order. Please note choosing a new service time may change the menu. "
            ),
            primaryActionText: __("Order ASAP"),
            primaryAction,
            secondaryActionText: __("Choose a new pickup time"),
            secondaryAction,
            mustAction: true,
        };
    },
    attemptedToJoinAnotherTable(): ModalMessage {
        return {
            type: "action",
            title: __("You already have an open order"),
            text: __(
                "Before starting a new order, please pay the outstanding balance or leave the order from the profile menu. If you can’t leave your current order please ask venue staff for assistance."
            ),
            primaryActionText: __("OK"),
            useLocationTheme: true,
            mustAction: true,
        };
    },
};

const accountMessages = {
    emailReceipt(emailAddress: string, useLocationTheme?: boolean, asActionSheet?: boolean): ModalMessage {
        return {
            type: "action",
            title: __("Check your inbox"),
            text: __("Your receipt was sent to: {emailAddress}", { emailAddress }),
            useLocationTheme,
            asActionSheet,
        };
    },
    emailReceiptFailed(useLocationTheme?: boolean): ModalMessage {
        return {
            type: "info",
            title: __("Could not send receipt"),
            text: __("Review your email address and try again."),
            useLocationTheme,
        };
    },
    cannotLogout(): ModalMessage {
        return {
            type: "info",
            title: __("Can’t log out when ordering"),
            text: __("Please complete or leave your order before logging out."),
        };
    },
    cannotDeleteAccountInParty(): ModalMessage {
        return {
            type: "info",
            title: __("Can’t delete account when ordering"),
            text: __("Please complete or leave your order before deleting your account."),
        };
    },
    cannotDeleteAccountPendingOrder(): ModalMessage {
        return {
            type: "info",
            title: __("Can’t delete account when ordering"),
            text: __("Please wait for your order to be processed by the venue before deleting your account."),
        };
    },
    cannotDeleteAccountOpenedGroupTab(tabTypeName: string): ModalMessage {
        return {
            type: "info",
            title: __("Can’t delete account with an open {tabTypeName}", { tabTypeName }),
            text: __("Please close your {tabTypeName} before deleting your account.", { tabTypeName }),
        };
    },
    deleteAccount(primaryAction: () => void): ModalMessage {
        return {
            type: "action",
            title: __("Are you sure you want to delete your account?"),
            text: __("You won’t be able to access your account from now on."),
            primaryActionText: __("Delete account"),
            primaryAction,
            showCancel: true,
        };
    },
    loginFailed(): ModalMessage {
        return {
            type: "info",
            title: __("Login failed"),
            text: __("Please try again or select another login method."),
        };
    },
    ssoAccountExists(primaryButton?: PrimaryButtonFunc, secondaryAction?: () => void, email?: string): ModalMessage {
        return {
            type: "action",
            title: __("Account exists"),
            text: email
                ? __("Looks like you already have an account. Please log in instead. ({email})", { email })
                : __("Please log in with the last used sign in method, or create an account using this number."),
            primaryButton,
            secondaryActionText: email
                ? __("Log in with a different account")
                : __("Create a new account using this number"),
            secondaryAction,
            asActionSheet: true,
            unthemed: true,
        };
    },
    accountUnverified(primaryAction: () => void): ModalMessage {
        return {
            type: "action",
            title: __("Verify your mobile number"),
            text: __("For security purposes we need to re-verify the mobile number on your account."),
            primaryAction,
            primaryActionText: __("Continue"),
            asActionSheet: true,
            mustAction: true,
        };
    },
    linkSso(primaryAction: () => void, secondaryAction: () => void): ModalMessage {
        return {
            type: "action",
            title: __("Log in easier next time"),
            text: __(
                "Secure your account by linking your phone account to a social sign-in so you can log in easier next time."
            ),
            primaryAction,
            primaryActionText: __("Link account"),
            secondaryAction,
            secondaryActionText: __("Not now"),
            asActionSheet: true,
            unthemed: true,
            forceClosable: true,
        };
    },
    optInMarketingFailed(): ModalMessage {
        return {
            title: "Subscription unsuccessful",
            text: "We were unable to subscribe you. Please try again later",
            type: "info",
        };
    },
};

const paymentMessages = {
    initAddCardFormFailed(): ModalMessage {
        return {
            type: "info",
            title: __("Could not show the card details form"),
            text: __("Please check your data settings or network signal and try again."),
        };
    },
    invalidCard(): ModalMessage {
        return {
            type: "info",
            title: __("Could not add your card"),
            text: __("Please check that your card number, expiry date and CVV are correct."),
        };
    },
    addCardFailed(): ModalMessage {
        return {
            type: "info",
            title: __("Could not add your card"),
            text: __("Please check your data settings or network signal and try again."),
        };
    },
    getCvvTokenFailed(): ModalMessage {
        return {
            type: "info",
            title: __("Could not verify your CVV"),
            text: __("Please check your data settings or network signal and try again."),
        };
    },
    vaultDeviceCardFailed(): ModalMessage {
        return {
            type: "info",
            icon: <ErrorIcon />,
            title: __("Oh no, something went wrong!"),
            text: __("Please try again or ask staff for assistance."),
        };
    },
};

const membershipMessages = {
    connectMembershipFailed(): ModalMessage {
        return {
            title: "Error connecting membership",
            text: "There was a problem connecting your membership. Please try again later.",
            type: "info",
        };
    },
    activateMembershipFailed(): ModalMessage {
        return {
            title: "Error activating membership",
            text: "No membership was found with the details entered.",
            type: "info",
        };
    },
    switchMembershipFailed(): ModalMessage {
        return {
            title: "Error switching membership",
            text: "There was a problem switching your membership. Please try again later.",
            type: "info",
        };
    },
    removeMembershipFailed(): ModalMessage {
        return {
            title: "Error removing membership",
            text: "There was a problem removing your membership. Please try again later.",
            type: "info",
        };
    },
    showBarcodeUnknownError(membershipName: string): ModalMessage {
        return {
            type: "info",
            title: __("Can’t scan for barcodes"),
            text: __("Please manually enter your {membershipName} number", { membershipName }),
        };
    },
    findBarcodeTimeout(membershipName: string): ModalMessage {
        return {
            type: "info",
            title: __("No barcode found"),
            text: __("We weren’t able to find a {membershipName} barcode", { membershipName }),
        };
    },
    connectMembershipModal(
        membershipName: string,
        primaryAction: () => void,
        secondaryAction: () => void,
        primaryDisabled: boolean
    ): ModalMessage {
        return {
            type: "action",
            title: membershipName,
            primaryActionText: "Enable this program",
            primaryAction,
            secondaryActionText: "Remove this program",
            secondaryActionIcon: <RemoveIcon />,
            secondaryAction,
            forceClosable: true,
            primaryButtonDisabled: primaryDisabled,
            asActionSheet: true,
        };
    },
    insufficientPointsSpendBalance(): ModalMessage {
        return {
            type: "info",
            icon: <ErrorIcon />,
            title: __("Points balance too low"),
            text: __("Your points balance is insufficient for this order."),
        };
    },
    phoneOtpFailed(): ModalMessage {
        return {
            title: "Error sending verification code",
            text: "There was a problem sending your phone verification code. Please try again later or use another web browser.",
            type: "info",
        };
    },
};

const baseSentMessage = (
    hasPickUp?: boolean,
    isTakeaway?: boolean,
    tableLabel?: string,
    tableNumber?: string
): Pick<ModalActionMessage, "type" | "title" | "text"> => ({
    type: "action",
    title: __("Order sent!"),
    text: isTakeaway
        ? __("Keep an eye on your phone - we’ll send an SMS once your order is ready to be picked up.")
        : hasPickUp
        ? __("We'll SMS you when your food is ready to collect.")
        : tableLabel && tableNumber
        ? __("Your order will be delivered to {tableLabel} {tableNumber}.", { tableLabel, tableNumber })
        : __("Your order will be delivered."),
});

interface FeedbackSurveyOptions {
    hasSurveyDetails?: boolean;
    hasPickUp?: boolean;
    action?: () => void;
    shouldValidate?: boolean;
    initialValid?: boolean;
    primaryAction?: () => void;
    isTakeaway?: boolean;
    isExpanded?: boolean;
    useLocationTheme?: boolean;
    onClose?: () => void;
    tableLabel?: string;
    tableNumber?: string;
}

const systemMessages = {
    feedbackSurvey(options: FeedbackSurveyOptions = {}): ModalMessage {
        const {
            hasSurveyDetails,
            hasPickUp,
            action,
            shouldValidate,
            initialValid,
            primaryAction,
            isTakeaway,
            isExpanded,
            useLocationTheme,
            onClose,
            tableLabel,
            tableNumber,
        } = options;

        return {
            ...baseSentMessage(hasPickUp, isTakeaway, tableLabel, tableNumber),
            internal: !!shouldValidate,
            shouldValidate: !!shouldValidate
                ? {
                      initialValid: !!initialValid,
                      validate: true,
                  }
                : undefined,
            primaryComponent: !!hasSurveyDetails
                ? ({ validate, expand, isExpanded }: PrimaryComponentActions) => {
                      const handleOnRate = () => {
                          action?.();
                          setTimeout(expand, 16);
                      };

                      return (
                          <>
                              {!isExpanded && <Divider fullWidth />}
                              <ThumbsFeedback
                                  isLarge={isExpanded}
                                  locationTheme={useLocationTheme}
                                  onNegative={handleOnRate}
                                  onPositive={handleOnRate}
                                  validate={validate}
                              />
                          </>
                      );
                  }
                : undefined,
            additionalExpandedComponent: hasSurveyDetails ? () => <SurveyPage /> : undefined,
            primaryActionText: hasSurveyDetails ? (!!shouldValidate ? "Submit" : "Dismiss") : "OK",
            expandedOptions: { showClose: true, hideTitle: true, customBack: "feedback-survey" },
            primaryAction,
            showNetworkConnectivityIssues: !!shouldValidate,
            useLocationTheme,
            isExpanded,
            onClose,
        };
    },
    cookiePreferences(
        primaryAction: () => void,
        onAnalyticsCookiesToggled: (toggled: boolean) => void,
        onAdvertisingCookiesToggled: (toggled: boolean) => void,
        analyticsAllowed: boolean = true,
        advertisingAllowed: boolean = true
    ): ModalMessage {
        return {
            type: "action",
            title: __("Your privacy"),
            text: __(
                "We use cookies and similar technologies to help personalise content and provide a better experience. By clicking OK you agree to this as outlined in our {{Cookie Policy|cookiePolicy}}. To change preferences or withdraw consent, please update your Cookie Preferences."
            ),
            expandedText: __(
                "When you visit any website, it may store or retrieve information on your browser, mostly in the form of cookies. This information might be about you, your preferences or your device and is mostly used to make the site work as you expect it to. The information does not usually directly identify you, but it can give you a more personalised web experience. Because we respect your right to privacy, you can choose not to allow some types of cookies. Click on the different category headings to find out more and change our default settings. However, blocking some types of cookies may impact your experience of the site and the services we are able to offer. For more information view our {{Cookie Policy|cookiePolicy}}."
            ),
            additionalExpandedContent: (
                <>
                    <Switch
                        id="necessary_cookies"
                        title={__("Strictly necessary cookies")}
                        text={__(
                            "These cookies are essential to allow us to operate the app. They enable you to use features, such as adding items to your order and processing payments."
                        )}
                        defaultChecked={true}
                        disabled={true}
                    />
                    <Switch
                        id="advertising_cookies"
                        title={__("Targeted advertising cookies")}
                        text={__(
                            "We use these cookies on behalf of our partners to make ads shown on other website more effective. The cookies remember your activities on our app and this information may be shared with our partner organisations."
                        )}
                        defaultChecked={advertisingAllowed}
                        onChange={onAdvertisingCookiesToggled}
                    />
                    <Switch
                        id="analytics_cookies"
                        title={__("Analytics cookies")}
                        text={__(
                            "These cookies collect information about how visitors use our services, for instance if they get error messages from webpages. We use this information to maintain, operate, and continually improve the website and application."
                        )}
                        defaultChecked={analyticsAllowed}
                        onChange={onAnalyticsCookiesToggled}
                    />
                </>
            ),
            primaryAction,
            secondaryActionExpands: true,
            secondaryActionText: __("Cookie preferences"),
            allowsSpinner: true,
            showsLegalPage: true,
        };
    },
    problemDetail(pd: ProblemDetails, type: ModalMessage["type"] = "info"): ModalMessage {
        if (pd.extensions?.helpUrl) {
            const helpUrl = pd.extensions.helpUrl;
            return {
                title: pd.title,
                text: pd.detail,
                type: "action",
                primaryButton: (closeAndAction) => <GetHelpButton helpUrl={helpUrl} closeAndAction={closeAndAction} />,
                secondaryActionText: "Dismiss",
                asActionSheet: true,
                unthemed: true,
                forceClosable: true,
            };
        }
        return {
            title: pd.title,
            text: pd.detail,
            type: type,
        };
    },
};

const payOnlyMessages = {
    payOnlyNeedReceipt(primaryAction: () => void): ModalMessage {
        return {
            type: "action",
            title: __("Need a receipt?"),
            primaryComponent: ({ validate }) => <PayOnlyGetReceipt validate={validate} />,
            primaryAction,
            primaryActionText: __("Get receipt"),
            secondaryActionText: __("Skip"),
            useLocationTheme: true,
            shouldValidate: {
                initialValid: false,
                validate: true,
            },
            asActionSheet: true,
        };
    },
    payOnlyOverpayment(
        remainingBalance: string,
        paymentAmount: string,
        newTipAmount: string,
        primaryAction: () => void,
        secondaryAction: () => void
    ): ModalMessage {
        return {
            type: "action",
            title: __("There’s only {remainingBalance} left to pay", { remainingBalance }),
            text: __(
                "Your payment of {paymentAmount} is more than the remaining balance. Do you want to leave the rest as a tip?",
                { paymentAmount }
            ),
            primaryAction,
            primaryActionText: __("Add {newTipAmount} as a tip", { newTipAmount }),
            secondaryAction,
            secondaryActionText: __("Just pay the remaining"),
            useLocationTheme: true,
            actionsReversed: true,
            unthemed: true,
            asActionSheet: true,
        };
    },
    payOnlyZeroRemainingBalance(primaryAction: () => void): ModalMessage {
        return {
            type: "info",
            title: __("There’s nothing left to pay"),
            text: __("Someone beat you to it and paid the balance. We’re processing their payment now."),
            primaryAction,
            useLocationTheme: true,
            unthemed: true,
        };
    },
    payOnlyRemainingBalanceTooLow(
        remainingBalance: string,
        primaryAction: () => void,
        lowBalanceLimit: string
    ): ModalMessage {
        return {
            type: "action",
            title: __("The outstanding bill amount will be too low"),
            text: __(
                "There is a low balance limit of {lowBalanceLimit}. This payment will leave an outstanding amount of {remainingBalance}. Pay the remaining balance to close the bill.",
                { remainingBalance, lowBalanceLimit }
            ),
            primaryAction,
            primaryActionText: __("Add {remainingBalance} to payment", { remainingBalance }),
            useLocationTheme: true,
            asActionSheet: true,
        };
    },
    payOnlyPaymentOptions(primaryAction: () => void, secondaryAction: () => void): ModalMessage {
        return {
            type: "action",
            title: __("How would you like to pay?"),
            primaryAction,
            primaryActionText: __("Pay balance"),
            secondaryAction,
            secondaryActionText: __("Split bill"),
            showCancel: true,
            actionsReversed: true,
            useLocationTheme: true,
            unthemed: true,
            asActionSheet: true,
            forceClosable: true,
        };
    },
};

const promotionMessages = {
    promotionNoLongerValid(): ModalMessage {
        return {
            type: "info",
            title: __("Promo code no longer valid"),
            text: __("The promo code you entered is no longer valid. It has been removed from your order"),
        };
    },
};

const openTableMessages = {
    cannotLeaveOpenTable(reason: PreventLeavePartyReason, primaryAction: () => void): ModalMessage {
        const hasSubmittedOrders = reason === PreventLeavePartyReason.HAS_SUBMITTED_ORDERS;
        const isFlexTabAdmin = reason === PreventLeavePartyReason.IS_FLEX_TAB_ADMIN;
        const text = hasSubmittedOrders
            ? "Please pay the outstanding balance before leaving this order or ask staff for assistance."
            : isFlexTabAdmin
            ? "Please ask your friends to leave your Tab before leaving this order or ask staff for assistance."
            : "You are not able to leave this order. Please ask staff for assistance.";
        const primaryActionText = hasSubmittedOrders ? "Go to pay" : "OK";

        return {
            type: "action",
            title: __("You can’t leave this order"),
            text: __("{text}", { text }),
            primaryAction: hasSubmittedOrders ? primaryAction : undefined,
            primaryActionText: __("{primaryActionText}", { primaryActionText }),
            showCancel: hasSubmittedOrders,
        };
    },
    openTableOrderingOffline(): ModalMessage {
        return {
            type: "action",
            title: __("Ordering offline"),
            text: OpenTableOrderingOfflineMessage,
            primaryActionText: __("OK"),
        };
    },
    confirmExitOrderHolding(primaryAction: () => void): ModalMessage {
        return {
            type: "action",
            title: __("Are you sure?"),
            text: __("Your order won’t be submitted unless you confirm it again."),
            primaryActionText: __("Yes I’m sure"),
            primaryAction,
            secondaryActionText: __("No, keep waiting"),
            asActionSheet: true,
            useLocationTheme: true,
        };
    },
    unconfirmedOrderOrPayment(type: NotificationType): ModalMessage {
        return {
            type: "action",
            primaryComponent: () => <PayOnlyNotificationDetails type={type} />,
            asActionSheet: true,
            useLocationTheme: true,
            primaryActionText: __("OK"),
        };
    },
};

const offerMessages = {
    claimOfferError(): ModalMessage {
        return {
            type: "info",
            icon: <ErrorIcon />,
            title: __("Something went wrong"),
            text: __("There was an issue with claiming your offer. Please try again."),
            primaryActionText: __("OK"),
        };
    },
    noOffersFound(primaryAction: () => void, venueName: string | undefined): ModalMessage {
        return {
            type: "action",
            title: __("No custom offers right now"),
            text: __(
                "You don’t have any custom offers from {venueName} available today - but there are still great deals to be found on the menu.",
                {
                    venueName: venueName ?? __("this venue"),
                }
            ),
            primaryAction,
            primaryActionText: "Browse menu",
            useLocationTheme: true,
        };
    },
    offerUnlocked(
        hasPickUp?: boolean,
        primaryAction?: () => void,
        isTakeaway?: boolean,
        tableLabel?: string,
        tableNumber?: string
    ): ModalMessage {
        return {
            ...baseSentMessage(hasPickUp, isTakeaway, tableLabel, tableNumber),
            primaryComponent: () => <RewardEarned />,
            primaryActionText: "Dismiss",
            primaryAction,
            useLocationTheme: true,
        };
    },
};

export const modalMessages = {
    ...joinTableMessages,
    ...connectivityMessages,
    ...orderMessages,
    ...accountMessages,
    ...paymentMessages,
    ...membershipMessages,
    ...systemMessages,
    ...groupTabsMessages,
    ...payOnlyMessages,
    ...promotionMessages,
    ...openTableMessages,
    ...offerMessages,
};
