import { createSelector } from "reselect";
import { getPayOnlyBill, getPayOnlyRemainingBalance } from "./payOnlyBill";
import { PayOnlyShare } from "../types";
import { combineAndRound } from "../../payment/util/combineAndRound";
import { calculateTipAmount, calculateTipPercentage, getCustomTip, getTipLevel } from "../../payment/selectors/tips";
import {
    calculateProcessingFee,
    getProcessingFeeBaseAmount,
    getProcessingFeePercentage,
} from "../../payment/selectors/processingFees";
import { evenRound } from "../../../sharedComponents/common/utils";
import { getProfile } from "src/features/accountmenu/selectors";
import { getAnyParty } from "src/features/order/selectors";
import { AdjustmentCategory, OrderItem } from "src/features/order";
import { getPayOnlyState } from "./payOnlyState";
import { AppState } from "src/features";
import { getPayOnlyEmbellishedItems } from "./payOnlyOrder";

const getPayOnlyShare = createSelector(getPayOnlyBill, getPayOnlyState, (bill, { amount }) => {
    if (!bill) return null;

    const {
        total,
        subTotal,
        nonMemberSubTotal,
        surchargeAmount,
        surchargeAmountExTax,
        surchargePercentage,
        discountAmount,
        membershipDiscountAmount,
        taxSummary,
    } = bill;

    // We are paying a share of the bill so work out how much
    // of a share of everything else we are paying
    const share = amount / total;

    const subTotalShare = evenRound(subTotal * share, 2);
    const nonMemberSubTotalShare = evenRound(nonMemberSubTotal * share, 2);
    const surchargeAmountShare = evenRound(surchargeAmount * share, 2);
    const surchargeAmountExTaxShare = evenRound(surchargeAmountExTax * share, 2);
    const discountAmountShare = evenRound(discountAmount * share, 2);
    const membershipDiscountAmountShare = evenRound(membershipDiscountAmount * share, 2);
    const taxSummaryShare = taxSummary.map((taxClassTotal) => ({
        ...taxClassTotal,
        total: taxClassTotal.total * share,
    }));

    const exclusiveTaxTotal = taxSummaryShare
        .filter((taxSummaryShare) => taxSummaryShare.exclusive === true)
        .reduce((accumulator, currentValue) => accumulator + currentValue.total, 0);

    const payOnlyShare: PayOnlyShare = {
        orderTotal: subTotalShare,
        subTotal: subTotalShare,
        nonMemberSubTotal: nonMemberSubTotalShare,
        surchargeAmount: surchargeAmountShare,
        surchargeAmountExTax: surchargeAmountExTaxShare,
        surchargePercentage,
        discountAmount: discountAmountShare,
        membershipDiscountAmount: membershipDiscountAmountShare,
        total: amount,
        taxSummary: taxSummaryShare,
        exclusiveTaxTotal: exclusiveTaxTotal,
    };

    return payOnlyShare;
});

export const getPayOnlyTotalBeforeTip = createSelector(getPayOnlyShare, (payOnlyShare) => payOnlyShare?.total ?? 0);

export const getPayOnlyExclusiveSubTotal = createSelector(
    getPayOnlyShare,
    (payOnlyShare) => payOnlyShare?.subTotal ?? 0
);

export const getPayOnlyDiscountAmount = createSelector(
    getPayOnlyShare,
    (payOnlyShare) => payOnlyShare?.discountAmount ?? 0
);

export const getPayOnlyExclusiveTaxTotal = createSelector(
    getPayOnlyShare,
    (payOnlyShare) => payOnlyShare?.exclusiveTaxTotal ?? 0
);

export const getVenueSurchargeAmount = createSelector(
    getPayOnlyShare,
    (payOnlyShare) => payOnlyShare?.surchargeAmountExTax || payOnlyShare?.surchargeAmount
);

export const getVenueSurchargePercentage = createSelector(
    getPayOnlyShare,
    (payOnlyShare) => payOnlyShare?.surchargePercentage ?? 0
);

export const getVenueServiceFeeFactor = createSelector(getAnyParty, (anyParty) => {
    const totalVenueServiceFee = anyParty?.adjustments?.reduce(
        (total, a) =>
            a.adjustmentCategory === AdjustmentCategory.VENUE_SERVICE_FEE ? total + Math.max(0, a.value - 1) : total,
        0
    );
    return totalVenueServiceFee ?? 0;
});

export const getVenueServiceFee = createSelector(
    getVenueServiceFeeFactor,
    getPayOnlyTotalBeforeTip,
    (factor, totalSelected) => evenRound(totalSelected * factor, 2)
);

export const getPayOnlyTotalBeforeTipWithVenueServiceFee = createSelector(
    getVenueServiceFee,
    getPayOnlyTotalBeforeTip,
    combineAndRound
);

export const getPayOnlyTipAmount = createSelector(
    getCustomTip,
    getTipLevel,
    getPayOnlyTotalBeforeTip,
    calculateTipAmount
);

export const getPayOnlyTipPercentage = createSelector(
    getCustomTip,
    getTipLevel,
    getPayOnlyTotalBeforeTip,
    calculateTipPercentage
);

export const getPayOnlyTotalBeforeProcessingFee = createSelector(
    getPayOnlyTotalBeforeTipWithVenueServiceFee,
    getPayOnlyTipAmount,
    combineAndRound
);

export const getPayOnlyProcessingFee = createSelector(
    getProcessingFeePercentage,
    getProcessingFeeBaseAmount,
    getPayOnlyTotalBeforeProcessingFee,
    calculateProcessingFee
);

export const getPayOnlyProcessingFeeWithoutTip = createSelector(
    getProcessingFeePercentage,
    getProcessingFeeBaseAmount,
    getPayOnlyTotalBeforeTipWithVenueServiceFee,
    calculateProcessingFee
);

export const getPayOnlyTotalWithoutTip = createSelector(
    getPayOnlyTotalBeforeTipWithVenueServiceFee,
    getPayOnlyProcessingFeeWithoutTip,
    combineAndRound
);

export const getPayOnlyTotal = createSelector(
    getPayOnlyTotalBeforeProcessingFee,
    getPayOnlyProcessingFee,
    combineAndRound
);

export const getPayOnlyIncludedItems = createSelector(
    (state: AppState) => state.payOnly,
    getPayOnlyEmbellishedItems,
    (payOnly, embelishedItems) => {

        let includedItems: OrderItem[] = [];

        for (let embelishedItem of embelishedItems) {
            const selectedQuantity = payOnly.selectedItems[embelishedItem.referenceId];

            if (selectedQuantity > 0) {
                const { isPaid, ...orderItem } = embelishedItem.sourceItem;

                includedItems.push({
                    ...orderItem,
                    price: (orderItem.unitPrice ?? 0) * selectedQuantity,
                    quantity: selectedQuantity
                })
            }
        }

        return includedItems;
    }
)

export const getPayOnlyPaymentInfo = createSelector(
    getPayOnlyState,
    getProfile,
    getPayOnlyTotalBeforeTip,
    getPayOnlyIncludedItems,
    ({ share }, profile, amount, includedItems) => {
        return {
            amount,
            name: profile?.firstName,
            share,
            includedItems
        };
    }
);

export const getPayOnlyPotentialRemainingBill = createSelector(
    getPayOnlyRemainingBalance,
    getPayOnlyTotalBeforeTip,
    (remainingBalance, total) => evenRound(remainingBalance - total, 2)
);
