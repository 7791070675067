import { AppDispatch, AppState } from "../..";
import { actionCreators as paymentActions } from "../reducers";
import { getApplePayAvailable } from "./getApplePayAvailable";
import { getGooglePayAvailable } from "./getGooglePayAvailable";
import { getPaymentMethodsBackgroundOperation, getPaymentMethodsOperation } from "../operations";
import {
    ApplePayPaymentMethod,
    GooglePayPaymentMethod,
    isLocationPaymentMethod,
    isSelectablePaymentMethod,
    NewCardPaymentMethod,
    PaymentMethod,
    PaymentType,
    PayPalCheckoutPaymentMethod,
    SelectablePaymentMethod,
} from "src/common/payment";
import { getProfile } from "../../accountmenu/selectors";
import { getSelectablePaymentMethod } from "../util/getSelectablePaymentMethod";
import { initializePayPalCheckoutAwaitable } from "./initializePayPalCheckoutButton";
import { initializeGooglePayAwaitable } from "./initializeGooglePayButton";
import { AccountProfile } from "../../accountmenu";
import { fetchPaymentMethods } from "../api/fetchPaymentMethods";
import { getPayPalAvailable } from "./getPayPalAvailable";
import { getAddCardAvailable } from "./getAddCardAvailable";
import { getCanUseGroupTabPaymentMethod } from "src/features/payment/selectors";
import { getInParty } from "src/features/order/selectors";

export interface GetPaymentMethodsResult {
    paymentMethods: PaymentMethod[];
    selectedPaymentMethod: SelectablePaymentMethod | null;
}

const getSelectedPaymentMethod = (
    paymentMethods: PaymentMethod[],
    profile: AccountProfile | null,
    forGroupTabsAdmin: boolean,
    forceNewSelectionIfGroupTabLastUsed: boolean
) => {
    if (
        paymentMethods.length === 1 &&
        isSelectablePaymentMethod(paymentMethods[0]) &&
        (!forGroupTabsAdmin || !isLocationPaymentMethod(paymentMethods[0]))
    ) {
        return paymentMethods[0] as SelectablePaymentMethod;
    }

    if (!profile || (!profile.mostRecentPaymentToken && !profile.mostRecentPaymentGroupTab)) return null;

    if (!forGroupTabsAdmin && profile.mostRecentPaymentGroupTab) {
        const paymentMethod = getSelectablePaymentMethod(paymentMethods, profile.mostRecentPaymentGroupTab);
        if (paymentMethod) {
            return forceNewSelectionIfGroupTabLastUsed && paymentMethod.paymentType === PaymentType.GROUPTAB
                ? null
                : paymentMethod;
        }
    }

    const selectedPaymentMethod = profile.mostRecentPaymentToken
        ? getSelectablePaymentMethod(paymentMethods, profile.mostRecentPaymentToken)
        : null;

    if (selectedPaymentMethod === null) return null;

    if (forGroupTabsAdmin && isLocationPaymentMethod(selectedPaymentMethod)) {
        return null;
    }

    if (
        !forGroupTabsAdmin &&
        !isLocationPaymentMethod(selectedPaymentMethod) &&
        paymentMethods.some(isLocationPaymentMethod)
    ) {
        return null;
    }

    return selectedPaymentMethod;
};

const removeUnavailablePaymentMethod = async <T extends PaymentMethod>(
    dispatch: AppDispatch,
    paymentMethods: PaymentMethod[],
    paymentType: PaymentType,
    getAvailable: (dispatch: AppDispatch, paymentMethod: T) => Promise<boolean | undefined>
) => {
    const paymentMethodIdx = paymentMethods.findIndex((p) => p.paymentType === paymentType);
    if (paymentMethodIdx === -1) return;
    if (await getAvailable(dispatch, paymentMethods[paymentMethodIdx] as T)) return;
    paymentMethods.splice(paymentMethodIdx, 1);
};

const getPaymentMethodsInternal = async (
    dispatch: AppDispatch,
    getState: () => AppState,
    success?: (result: GetPaymentMethodsResult) => void,
    forGroupTabsAdmin: boolean = false
) => {
    const state = getState();
    const profile = getProfile(state);
    const inParty = getInParty(state);
    const canUseGroupTabPaymentMethod = getCanUseGroupTabPaymentMethod(state);
    const forceNewSelectionIfGroupTabLastUsed = !canUseGroupTabPaymentMethod;

    const [vaultedPaymentMethods, devicePaymentMethods] = await Promise.all([
        fetchPaymentMethods("vaulted", inParty),
        fetchPaymentMethods("device", inParty),
    ]);

    await removeUnavailablePaymentMethod<ApplePayPaymentMethod>(
        dispatch,
        devicePaymentMethods,
        PaymentType.APPLEPAY,
        getApplePayAvailable
    );

    await removeUnavailablePaymentMethod<GooglePayPaymentMethod>(
        dispatch,
        devicePaymentMethods,
        PaymentType.GOOGLEPAY,
        getGooglePayAvailable
    );

    await removeUnavailablePaymentMethod<PayPalCheckoutPaymentMethod>(
        dispatch,
        devicePaymentMethods,
        PaymentType.PAYPALCHECKOUT,
        getPayPalAvailable
    );

    await removeUnavailablePaymentMethod<NewCardPaymentMethod>(
        dispatch,
        vaultedPaymentMethods,
        PaymentType.NEWCARD,
        getAddCardAvailable
    );

    const paymentMethods = [...devicePaymentMethods, ...vaultedPaymentMethods];

    const selectedPaymentMethod = getSelectedPaymentMethod(
        paymentMethods,
        profile,
        forGroupTabsAdmin,
        forceNewSelectionIfGroupTabLastUsed
    );

    dispatch(paymentActions.getPaymentMethodsComplete(paymentMethods));
    dispatch(paymentActions.selectPaymentMethod(selectedPaymentMethod));

    if (success) {
        success({
            paymentMethods,
            selectedPaymentMethod,
        });
    }
};

export const getPaymentMethods = (
    success?: (result: GetPaymentMethodsResult) => void,
    forGroupTabsAdmin: boolean = false
) =>
    getPaymentMethodsOperation.getThunk((dispatch: AppDispatch, getState: () => AppState) =>
        getPaymentMethodsInternal(dispatch, getState, success, forGroupTabsAdmin)
    );

export const getPaymentMethodsBackground = () =>
    getPaymentMethodsBackgroundOperation.getThunk((dispatch: AppDispatch, getState: () => AppState) =>
        getPaymentMethodsInternal(dispatch, getState)
    );

export const getPaymentMethodsAwaitable = (
    dispatch: AppDispatch,
    getState: () => AppState,
    success?: (result: GetPaymentMethodsResult) => void,
    forGroupTabsAdmin: boolean = false
) =>
    getPaymentMethodsOperation.invoke(
        () => getPaymentMethodsInternal(dispatch, getState, success, forGroupTabsAdmin),
        dispatch
    );

export const getPaymentMethodsAsNeeded = async (
    dispatch: AppDispatch,
    getState: () => AppState,
    forGroupTabsAdmin: boolean = false
) => {
    let selectedPaymentMethod: SelectablePaymentMethod | null = null;
    const state = getState();
    let {
        payment: { paymentMethods },
    } = state;

    if (!paymentMethods) {
        await getPaymentMethodsAwaitable(
            dispatch,
            getState,
            (result) => {
                paymentMethods = result.paymentMethods;
                selectedPaymentMethod = result.selectedPaymentMethod;
            },
            forGroupTabsAdmin
        );
    } else {
        const profile = getProfile(state);
        const canUseGroupTabPaymentMethod = getCanUseGroupTabPaymentMethod(state);
        const forceNewSelectionIfGroupTabLastUsed = !canUseGroupTabPaymentMethod;

        selectedPaymentMethod = getSelectedPaymentMethod(
            paymentMethods,
            profile,
            forGroupTabsAdmin,
            forceNewSelectionIfGroupTabLastUsed
        );
        dispatch(paymentActions.selectPaymentMethod(selectedPaymentMethod));
    }

    if (selectedPaymentMethod?.paymentType === PaymentType.PAYPALCHECKOUT) {
        await initializePayPalCheckoutAwaitable(dispatch, selectedPaymentMethod);
    } else if (selectedPaymentMethod?.paymentType === PaymentType.GOOGLEPAY) {
        await initializeGooglePayAwaitable(dispatch);
    }

    return selectedPaymentMethod;
};
