import { createSelector } from "reselect";
import { AppState } from "src/features";

export const getRestaurantFlags = (state: AppState) => state.restaurantFlags || {};

export const restaurantAdUnitEnabled = createSelector(getRestaurantFlags, (flags) => flags.quickSellEnabled);

export const getIsSalesMode = createSelector(getRestaurantFlags, (flags) => flags.salesMode);

export const getIsOrderHeadCountEnabled = createSelector(getRestaurantFlags, (flags) => flags.enableOrderHeadCount);

export const getVenueFeedbackUrl = createSelector(getRestaurantFlags, (flags) => flags.customFeedbackUrl);

export const getDietaryInformationUrl = createSelector(getRestaurantFlags, (flags) => flags.dietaryInformationUrl);

export const getGoogleTagManagerId = createSelector(getRestaurantFlags, (flags) => flags.googleTagManagerId);

export const getFacebookPixelId = createSelector(getRestaurantFlags, (flags) =>
    flags.googleTagManagerId ? undefined : flags.facebookPixelId
);
