import "./PayOnlyPaymentWizard.scss";

import React, { useCallback, useMemo } from "react";
import { WizardModal } from "../../wizards/components/WizardModal";
import { useDispatch, useSelector } from "react-redux";
import { actionCreators } from "../reducers";
import { PayOnlyHeaderProxy } from "./PayOnlyHeader";
import { PayOnlySelectSplitOption } from "./PayOnlySelectSplitOption";
import { PayOnlySplitOptionConfigure } from "./PayOnlySplitOptionConfigure";
import { PayOnlySetFirstName } from "./PayOnlySetFirstName";
import { getPayOnlyState } from "../selectors";
import { PaymentMethods } from "../../paymentMethods/components/PaymentMethods";
import { payOnlyPaymentWizard } from "../wizards";
import { PayOnlyReviewPayment } from "./PayOnlyReviewPayment";

export const PayOnlyPaymentWizard = () => {
    const dispatch = useDispatch();

    const onReset = useCallback(() => {
        dispatch(actionCreators.reset());
    }, [dispatch]);

    const { splitOption } = useSelector(getPayOnlyState);

    const title = useMemo(() => {
        switch (splitOption) {
            case "SelectAmount":
                return "Select amount";
            case "SplitEvenly":
                return "Split evenly";
            case "SelectItems":
                return "Select items";
            default:
                return undefined;
        }
    }, [splitOption]);

    return (
        <WizardModal
            wizard={payOnlyPaymentWizard}
            transition="slide-up"
            pages={{
                SetFirstName: {
                    header: PayOnlyHeaderProxy,
                    render: (onSuccess) => <PayOnlySetFirstName onSuccess={onSuccess} />,
                },
                SelectSplitOption: {
                    header: PayOnlyHeaderProxy,
                    render: (onSuccess) => <PayOnlySelectSplitOption onSuccess={onSuccess} />,
                },
                SplitOptionConfiguration: {
                    headerProps: { title },
                    header: PayOnlyHeaderProxy,
                    customPage: splitOption,
                    render: (onSuccess) => <PayOnlySplitOptionConfigure onSuccess={onSuccess} />,
                },
                SelectPaymentMethod: {
                    header: PayOnlyHeaderProxy,
                    render: (onSuccess, standalone) => (
                        <PaymentMethods onSuccess={onSuccess} select edit={standalone} title="Select payment" />
                    ),
                },
                ReviewPayment: {
                    header: PayOnlyHeaderProxy,
                    render: () => <PayOnlyReviewPayment />,
                },
            }}
            onReset={onReset}
        />
    );
};
