import "./PayOnlyPaymentsFeedWizard.scss";

import React, { Fragment, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FeedIcon, HeartIcon, RefundedIcon } from "src/sharedComponents/assets/icons";
import { Text } from "src/sharedComponents";
import { Price } from "../../menu/components/Price";
import {
    getPaidWithServerLabels,
    getPaidWithServerTotal,
    getPayOnlyBillPaid,
    getPayOnlyPaymentsFeed,
    getPayOnlyUnseenPayments,
} from "../selectors";
import { MenuDataLocaleContext } from "../../menudata/context/MenuDataLocaleContext";
import { WizardModal } from "../../wizards/components/WizardModal";
import { PayOnlyHeaderProxy } from "./PayOnlyHeader";
import { setPayOnlyPaymentsSeen } from "../actions/setPayOnlyPaymentsSeen";
import { payOnlyPaymentsFeedWizard } from "../wizards";
import { usePrevious } from "src/sharedComponents/common/shared";
import { evenRound } from "src/sharedComponents/common/utils";
import { getTableLabel } from "src/features/order/selectors";

const PayOnlyPaymentsFeed = () => {
    const menuDataLocale = useContext(MenuDataLocaleContext);
    const dispatch = useDispatch();

    const { payments, total, tipPercentage } = useSelector(getPayOnlyPaymentsFeed);
    const hasIncludedItems = payments.some((p) => p.includedItems?.length);
    const billPaid = useSelector(getPayOnlyBillPaid);
    const prevBillPaid = usePrevious(billPaid);
    const paidWithServerTotal = useSelector(getPaidWithServerTotal);
    const { paidWithServerLabel, totalOfAllServerPaymentsLabel } = useSelector(getPaidWithServerLabels);
    const unseenPayments = useSelector(getPayOnlyUnseenPayments);
    const tableLabel = useSelector(getTableLabel);

    useEffect(() => {
        if (unseenPayments > 0) {
            dispatch(setPayOnlyPaymentsSeen());
        }
    }, [dispatch, unseenPayments]);

    useEffect(() => {
        if (billPaid && !prevBillPaid) {
            dispatch(payOnlyPaymentsFeedWizard.actionCreators.done());
        }
    }, [dispatch, billPaid, prevBillPaid]);

    return (
        <div className="pay-only-payments-feed animated-child">
            {payments.length || paidWithServerTotal ? (
                <>
                    {[...payments]
                        .reverse()
                        .map(({ id, ownerName, includedItems, amount, tip, processingFee, amountRefunded }) => (
                            <>
                                {!!amountRefunded && (
                                    <div key={id} className="pay-only-payments-feed__payment">
                                        <div className="pay-only-payments-feed__payment-owner-items">
                                            <Text preset="g-14" mode={["block", "bold"]} value={ownerName!} />
                                        </div>
                                        <div className="pay-only-payments-feed__payment-details">
                                            <Price
                                                priceTextPreset="g-14"
                                                priceTextMode="block"
                                                price={-amountRefunded}
                                                prefix="Refunded "
                                            />
                                        </div>
                                    </div>
                                )}
                                <div key={id} className="pay-only-payments-feed__payment">
                                    <div className="pay-only-payments-feed__payment-owner-items">
                                        <Text preset="g-14" mode={["block", "bold"]} value={ownerName!} />
                                        {!!includedItems?.length ? (
                                            <div className="pay-only-payments-feed__payment-items">
                                                {includedItems.map(({ quantity, displayName, modifiers }, index) => (
                                                    <Fragment key={index}>
                                                        <Text
                                                            className="pay-only-payments-feed__payment-item-quantity"
                                                            preset="g-14"
                                                            mode="block"
                                                        >
                                                            {quantity}x&nbsp;
                                                        </Text>
                                                        <Text preset="g-14" mode="block" value={displayName!} />
                                                        {modifiers?.map(({ selectedOptions }, modifierIndex) =>
                                                            selectedOptions?.map(({ displayName }, optionIndex) => (
                                                                <Text
                                                                    key={`${modifierIndex}-${optionIndex}`}
                                                                    className="pay-only-payments-feed__payment-item-modifier"
                                                                    preset="g-14"
                                                                    mode="block"
                                                                    value={displayName}
                                                                />
                                                            ))
                                                        )}
                                                    </Fragment>
                                                ))}
                                            </div>
                                        ) : (
                                            hasIncludedItems && (
                                                <Text
                                                    className="pay-only-payments-feed__payment-no-items"
                                                    preset="g-14"
                                                    mode="block"
                                                    value="No items specified"
                                                />
                                            )
                                        )}
                                    </div>
                                    <div className="pay-only-payments-feed__payment-details">
                                        {!!amountRefunded && (
                                            <div className="pay-only-payments-feed__payment-details__refund-pill">
                                                <RefundedIcon />
                                                <Text
                                                    preset="g-12"
                                                    mode={["block", "bold"]}
                                                    value={
                                                        amountRefunded < evenRound(amount + tip, 2)
                                                            ? "Part refund"
                                                            : "Refunded"
                                                    }
                                                />
                                            </div>
                                        )}
                                        <Price
                                            priceTextPreset="g-14"
                                            priceTextMode="block"
                                            price={amount}
                                            prefix="Paid "
                                        />
                                        {tip > 0 && (
                                            <div className="pay-only-payments-feed__payment-details__tip">
                                                <Text preset="g-14" mode="block">
                                                    <Price price={tip} prefix="+ " suffix=" tip" />
                                                </Text>
                                                <HeartIcon />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </>
                        ))}
                    {paidWithServerTotal > 0 && (
                        <div className="pay-only-payments-feed__payment">
                            <div className="pay-only-payments-feed__payment-owner-items">
                                <Text preset="g-14" mode={["block", "bold"]} value={paidWithServerLabel} />
                                <Text
                                    className="pay-only-payments-feed__payment-no-items"
                                    preset="g-14"
                                    mode="block"
                                    value={totalOfAllServerPaymentsLabel}
                                />
                            </div>
                            <div className="pay-only-payments-feed__payment-details">
                                <Price priceTextPreset="g-14" priceTextMode="block" price={paidWithServerTotal} />
                                <div className="pay-only-payments-feed__payment-details__tip">
                                    <Text preset="g-14" mode="block" value="(Ex. tips)" />
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="pay-only-payments-feed__footer">
                        <Text preset="g-14" mode="block">
                            Payments totalling <Price price={evenRound(total + paidWithServerTotal, 2)} />
                        </Text>
                        <Text preset="g-14" mode="block">
                            Your {tableLabel} has tipped {menuDataLocale.formatPercent(tipPercentage)} of the bill
                        </Text>
                    </div>
                </>
            ) : (
                <div className="pay-only-payments-feed__no-payments">
                    <FeedIcon />
                    <Text preset="g-14" mode="block" value="No payments have been made yet" />
                </div>
            )}
        </div>
    );
};

interface Props {
    onOpenComplete?: () => void;
    onCloseComplete?: () => void;
}

export const PayOnlyPaymentsFeedWizard = ({ onOpenComplete, onCloseComplete }: Props) => (
    <WizardModal
        wizard={payOnlyPaymentsFeedWizard}
        transition="slide-down"
        pages={{
            PaymentsFeed: {
                header: PayOnlyHeaderProxy,
                render: () => <PayOnlyPaymentsFeed />,
            },
        }}
        onOpenComplete={onOpenComplete}
        onCloseComplete={onCloseComplete}
    />
);
