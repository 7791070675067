import { createSelector } from "reselect";
import { BillAdjustment, BillAdjustmentType } from "../../order";
import { getParty } from "src/features/order";
import { getCurrentMemberId } from "../../accounts/selectors";
import { PaymentType } from "src/common/payment";
import { calculateTipAmount, calculateTipPercentage, getCustomTip, getTipLevel } from "./tips";
import { getOpenTabPaymentMethod, getSelectedPaymentMethod } from "./paymentMethods";
import { combineAndRound } from "../util/combineAndRound";
import { calculateProcessingFee, getProcessingFeeBaseAmount, getProcessingFeePercentage } from "./processingFees";
import { evenRound } from "src/sharedComponents/common/utils";
import { getActiveGroupTabData } from "../../groupTabs/selectors/activeGroupTab";
import { getGroupTabMandatoryGratuity, getShowGratuity } from "./groupTabs";
import { getConnectedMembershipState } from "../../membership/selectors/getConnectedMembershipState";

const getMemberShare = createSelector(
    getParty,
    getCurrentMemberId,
    (party, memberId) => party?.activeOrder?.bill?.memberShares.find((m) => m.memberId === memberId) ?? null
);

const getMemberItems = createSelector(
    getParty,
    getCurrentMemberId,
    (party, memberId) => party?.activeOrder?.items?.filter((i) => i.memberId === memberId) ?? null
);

export const getMemberSurchargeAmount = createSelector(
    getMemberShare,
    (memberShare) => memberShare?.surchargeAmount || 0
);

export const getMemberMembershipDiscountAmount = createSelector(
    getMemberShare,
    (memberShare) => memberShare?.membershipDiscountAmount || 0
);

export const getMemberDiscountAmount = createSelector(
    getMemberShare,
    (memberShare) => memberShare?.discountAmount || 0
);

export const getMemberOrderTotal = createSelector(getMemberShare, (memberShare) => memberShare?.orderTotal ?? 0);
export const getMemberSubTotal = createSelector(getMemberShare, (memberShare) => memberShare?.subTotal || 0);

export const getMemberItemsHasPrice = createSelector(getMemberItems, (items) => items?.some((i) => !!i.price));

export const getMemberNonMemberSubTotal = createSelector(
    getMemberShare,
    (memberShare) => memberShare?.nonMemberSubTotal || 0
);

export const getMemberBillTotal = createSelector(getMemberShare, (memberShare) => memberShare?.total || 0);

export const getMemberBillAdjustments = createSelector(getMemberShare, (memberShare) => memberShare?.adjustments);

export const getMemberPromoCode = createSelector(
    getMemberBillAdjustments,
    (adjustments: BillAdjustment[] | undefined) =>
        adjustments?.find(({ type }) => type === BillAdjustmentType.PROMOCODE)
);

export const getVenueTrayCharge = createSelector(
    getMemberBillAdjustments,
    (adjustments: BillAdjustment[] | undefined) =>
        adjustments?.find(({ type }) => type === BillAdjustmentType.TRAY_CHARGE)
);

export const getMemberVenueCause = createSelector(
    getMemberBillAdjustments,
    (adjustments: BillAdjustment[] | undefined) =>
        adjustments?.find(({ type }) => type === BillAdjustmentType.VENUE_CAUSE)
);

export const getEngageOffer = createSelector(getMemberBillAdjustments, (adjustments?: BillAdjustment[]) =>
    adjustments?.find(({ type, value }) => value && type === BillAdjustmentType.ENGAGE_OFFER)
);

export const getPotentialMembershipPointsSpendTotal = createSelector(
    getMemberBillTotal,
    getConnectedMembershipState,
    (memberTotal, membershipState) => {
        if (!membershipState) return 0;
        if (membershipState.pointsSpendInFullBalance && memberTotal <= membershipState.pointsSpendInFullBalance) {
            return memberTotal;
        }
        if (membershipState.pointsSpendBalance) {
            return Math.min(memberTotal, membershipState.pointsSpendBalance);
        }
        return 0;
    }
);

export const getMembershipPointsSpendTotal = createSelector(
    getPotentialMembershipPointsSpendTotal,
    getConnectedMembershipState,
    (pointsSpendTotal, membershipState) => {
        if (!membershipState?.usePointsSpendBalance) return 0;
        return pointsSpendTotal;
    }
);

export const getMemberTotalBeforeTip = createSelector(
    getMemberBillTotal,
    getMembershipPointsSpendTotal,
    (memberTotal, membershipPointsSpendTotal) => Math.max(evenRound(memberTotal - membershipPointsSpendTotal, 2), 0)
);

export const getMemberTotalForTip = createSelector(
    getMemberBillTotal,
    getMemberTotalBeforeTip,
    getMemberVenueCause,
    (memberTotal, memberTotalBeforeTip, venueCause) =>
        memberTotalBeforeTip > 0 ? memberTotal - (venueCause?.value ?? 0) : 0
);

export const getMemberTipAmount = createSelector(
    getCustomTip,
    getTipLevel,
    getMemberTotalForTip,
    getSelectedPaymentMethod,
    (customTip, tipLevel, memberTotal, selectedPaymentMethod) => {
        if (selectedPaymentMethod?.paymentType === PaymentType.GROUPTAB) return 0;
        return calculateTipAmount(customTip, tipLevel, memberTotal);
    }
);

export const getMemberTipPercentage = createSelector(
    getCustomTip,
    getTipLevel,
    getMemberTotalForTip,
    getSelectedPaymentMethod,
    (customTip, tipLevel, memberTotal, selectedPaymentMethod) => {
        if (selectedPaymentMethod?.paymentType === PaymentType.GROUPTAB) return 0;
        return calculateTipPercentage(customTip, tipLevel, memberTotal);
    }
);

export const getMemberGratuityFactor = createSelector(
    getActiveGroupTabData,
    getSelectedPaymentMethod,
    getGroupTabMandatoryGratuity,
    getOpenTabPaymentMethod,
    (activeTab, selectedPaymentMethod, groupTabGratuityFactor, openTabPaymentMethod) => {
        if (selectedPaymentMethod?.paymentType === PaymentType.GROUPTAB) return activeTab?.gratuityFactor ?? 0;
        if (selectedPaymentMethod && selectedPaymentMethod === openTabPaymentMethod) return groupTabGratuityFactor ?? 0;
        return 0;
    }
);

export const getMemberGratuityAmount = createSelector(
    getMemberGratuityFactor,
    getMemberBillTotal,
    (gratuityFactor, memberTotal) => {
        if (!gratuityFactor) return 0;
        return calculateTipAmount(undefined, gratuityFactor, memberTotal);
    }
);

export const getMemberTipOrGratuityAmount = createSelector(
    getMemberTipAmount,
    getMemberGratuityAmount,
    getShowGratuity,
    (tipAmount, gratuityAmount, showGratuity) => (showGratuity ? gratuityAmount : tipAmount)
);

export const getMemberTipOrGratuityPercentage = createSelector(
    getMemberTipPercentage,
    getMemberGratuityFactor,
    getShowGratuity,
    (tipPercentage, gratuityFactor, showGratuity) => (showGratuity ? gratuityFactor : tipPercentage)
);

export const getMemberTotalBeforeProcessingFee = createSelector(
    getMemberTotalBeforeTip,
    getMemberTipOrGratuityAmount,
    combineAndRound
);

export const getMemberProcessingFee = createSelector(
    getProcessingFeePercentage,
    getProcessingFeeBaseAmount,
    getMemberTotalBeforeProcessingFee,
    calculateProcessingFee
);

export const getMemberTaxSummary = createSelector(getMemberShare, (memberShare) => memberShare?.taxSummary);

export const getMemberTotal = createSelector(
    getMemberTotalBeforeProcessingFee,
    getMemberProcessingFee,
    combineAndRound
);

export const getMemberProcessingFeeWithoutTip = createSelector(
    getProcessingFeePercentage,
    getProcessingFeeBaseAmount,
    getMemberTotalBeforeTip,
    calculateProcessingFee
);

export const getMemberTotalWithoutTip = createSelector(
    getMemberTotalBeforeTip,
    getMemberProcessingFeeWithoutTip,
    combineAndRound
);

export const getMemberOfferDiscountAmount = createSelector(
    getMemberShare,
    (memberShare) => memberShare?.offerDiscountAmount || 0
);
