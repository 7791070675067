import { device } from "./device";
import { AnyAction } from "redux";
import { GooglePayPaymentMethod } from "../../payment";
import { paymentGatewayBehaviours } from "./paymentGateways/behaviours";
import { InitializeDevicePaymentMethodButtonActions } from "src/common/experience/interface";

export const googlePay = {
    isAvailable: async (googlePayPaymentMethod: GooglePayPaymentMethod, trackEvent: (action: AnyAction) => void) => {
        if (!device.isAndroid) return false;

        const available = await paymentGatewayBehaviours[googlePayPaymentMethod.paymentGateway].googlePay?.getAvailable(
            googlePayPaymentMethod,
            trackEvent
        );

        return !!available;
    },
    initializeButton: (
        googlePayPaymentMethod: GooglePayPaymentMethod,
        total: number,
        actions: InitializeDevicePaymentMethodButtonActions,
        cancelPromise: Promise<void>
    ) =>
        paymentGatewayBehaviours[googlePayPaymentMethod.paymentGateway].googlePay!.initializeButton(
            googlePayPaymentMethod,
            total,
            actions,
            cancelPromise
        ),
    vaultPaymentMethod: (
        googlePayPaymentMethod: GooglePayPaymentMethod,
        actions: InitializeDevicePaymentMethodButtonActions,
        cancelPromise: Promise<void>
    ) =>
        paymentGatewayBehaviours[googlePayPaymentMethod.paymentGateway].googlePay!.vaultPaymentMethod?.(
            googlePayPaymentMethod,
            actions,
            cancelPromise
        ) ?? Promise.resolve(null),
    canVaultPaymentMethod: (googlePayPaymentMethod: GooglePayPaymentMethod) =>
        !!paymentGatewayBehaviours[googlePayPaymentMethod.paymentGateway].googlePay?.vaultPaymentMethod,
    getCustomButtonId: (googlePayPaymentMethod: GooglePayPaymentMethod) =>
        paymentGatewayBehaviours[googlePayPaymentMethod.paymentGateway].googlePay?.customButtonId ?? null,
};
