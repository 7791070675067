import { AppDispatch, AppState } from "../../index";
import {
    authorizePayPalPaymentOperation,
    initializePayPalPaymentBackgroundOperation,
    initializePayPalPaymentOperation,
} from "../operations";
import { normalizeError } from "src/common/error";
import { getPaymentTotal, getSelectedPaymentMethod } from "../selectors";
import { PayPalCheckoutPaymentMethod } from "src/common/payment";
import { getPaymentSessionToken } from "../util/getPaymentSessionToken";
import { getPayPalClient } from "src/common/payment/payPal";
import { paymentGatewayBehaviours } from "../../paymentGateways";
import { getParty } from "../../order";
import { CompletePaymentFunc } from "src/features/payment/types";

export function initializePayPalCheckout(
    payPalCheckoutPaymentMethod: PayPalCheckoutPaymentMethod,
    onSuccess?: () => void
) {
    return initializePayPalPaymentOperation.getThunk(async () => {
        await getPayPalClient(payPalCheckoutPaymentMethod);
        onSuccess && onSuccess();
    });
}

export const initializePayPalCheckoutAwaitable = (
    dispatch: AppDispatch,
    payPalCheckoutPaymentMethod: PayPalCheckoutPaymentMethod
) => initializePayPalPaymentBackgroundOperation.invoke(() => getPayPalClient(payPalCheckoutPaymentMethod), dispatch);

export function initializePayPalCheckoutButton(signal: AbortSignal, completePayment: CompletePaymentFunc) {
    return async (dispatch: AppDispatch, getState: () => AppState) => {
        await authorizePayPalPaymentOperation.invoke(async () => {
            try {
                const state = getState();
                const payPalCheckoutPaymentMethod = getSelectedPaymentMethod(state) as PayPalCheckoutPaymentMethod;
                const inParty = !!getParty(state);

                // IMPORTANT
                // this should always call getState() and get the latest copy
                // of the state so that the total is correct
                const getTotal = () => getPaymentTotal(getState());

                const { payPalBehaviour } = paymentGatewayBehaviours[payPalCheckoutPaymentMethod.paymentGateway];

                const authorizePaymentFunction = await payPalBehaviour!.initializeButton(
                    payPalCheckoutPaymentMethod,
                    getTotal,
                    getPaymentSessionToken(dispatch, getState),
                    inParty,
                    signal,
                    (action) => authorizePayPalPaymentOperation.trackEvent(action, dispatch),
                    dispatch
                );

                const result = await authorizePaymentFunction();
                completePayment(result);
            } catch (err) {
                completePayment(normalizeError(err));
                throw err;
            }
        }, dispatch);
    };
}
