import * as React from "react";
import { TappableLink } from "src/sharedComponents/common/tappable";
import { Category, Menu } from "../../menudata";
import "../assets/MenuCard.scss";
import { CategoryCard } from "./CategoryCard";
import { Text } from "src/sharedComponents";

export interface Props {
    menuId: string;
    menu: Menu;
    categories: Category[];
    onBeforeNavigate?: () => void;
}

export class MenuCard extends React.Component<Props> {
    render() {
        const { menu, onBeforeNavigate } = this.props;

        return (
            <div className="menu-card">
                <Text preset="title-28" mode="extra-bold" className="menu-card__title">
                    <span>{menu.displayName}</span>
                </Text>
                <ol className="menu-card__list">
                    {menu.categories.map((id, index) => this.renderItem(id, index, onBeforeNavigate))}
                </ol>
            </div>
        );
    }

    renderItem = (categoryId: string, index: number, onBeforeNavigate?: () => void) => (
        <li key={index} className="menu-card__item">
            <TappableLink to={`/menu/${this.props.menuId}/${categoryId}`} onBeforeNavigate={onBeforeNavigate}>
                <CategoryCard category={this.props.categories[index]} />
            </TappableLink>
        </li>
    );
}
