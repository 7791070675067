import { orderApi } from "../../order/orderApi";
import { AppDispatch, AppState } from "../..";
import {
    getAddedCard,
    getCustomTip,
    getIsAllowedZeroPayment,
    getIsOpeningOrPayingWithTab,
    getMembershipPointsSpendTotal,
    getOpenTabInfo,
    getPaymentTipAmount,
    getPaymentTipPercentage,
    getPaymentTotal,
    getSelectedPaymentMethod,
    getTipType,
} from "../selectors";
import { submitPaymentAndOrderOperation } from "../operations";
import { actionCreators as partyActionCreators, getParty, OrderStatus, Party } from "../../order";
import { AuthorizePaymentResult, isDevicePaymentMethod, PaymentType } from "../../../common/payment";
import { modalMessages } from "../../modalMessage/messages";
import { showModalMessage } from "../../modalMessage/actions/show";
import { BeginPaymentResult, NotRequired, PaymentFunc, PaymentInfo } from "../types";
import { paymentGatewayBehaviours } from "../../paymentGateways";
import { actionCreators as paymentActionCreators } from "src/features/payment";
import { getCurrentMemberId } from "../../accounts/selectors";
import { InvalidPromotionError } from "../../promotion";
import { MustUseExtendedValidationError, ReviewOrderError } from "../errors";
import { HandledError } from "../../../common/error";
import { invokeConnectionRetry } from "src/features/order/orderApi/serverOrderApi";
import { getIsOrderHeadCountEnabled } from "src/features/order/selectors/restaurantFlags";
import { patchOrderMetadata } from "src/features/order/actions/patchOrderMetadata";
import { showReviewOrderMessage } from "./showReviewOrderMessage";
import { MembershipInsufficientBalanceError } from "../../membership/errors";
import { actions as membershipActions } from "../../membership";

export const getPaymentInfo = async (
    dispatch: AppDispatch,
    getState: () => AppState,
    result?: AuthorizePaymentResult | Error
) => {
    const state = getState();
    const selectedPaymentMethod = getSelectedPaymentMethod(state)!;
    const paymentToken = selectedPaymentMethod.token;
    const paymentGateway = selectedPaymentMethod.paymentGateway;
    const includedMembers = [getCurrentMemberId(state)];
    const tip = getPaymentTipAmount(state);
    const addedCard = getAddedCard(state);
    const openTabInfo = getOpenTabInfo(state);
    const openTab = selectedPaymentMethod === openTabInfo?.paymentMethod;

    const paymentInfo: PaymentInfo = {
        paymentToken,
        includedMembers,
        tip,
        paymentGateway,
        openTab,
    };

    if (selectedPaymentMethod.paymentType === PaymentType.ADDEDCARD) {
        const { productId, remember } = selectedPaymentMethod;

        paymentInfo.addedCardPaymentInfo = {
            productId,
            remember,
        };

        if (openTab) {
            paymentInfo.additionalFraudProtectionData = openTabInfo?.additionalFraudProtectionData;
        } else {
            paymentInfo.additionalFraudProtectionData = addedCard?.additionalFraudProtectionData;
        }
    } else if (selectedPaymentMethod.paymentType === PaymentType.CARD && selectedPaymentMethod.cvv) {
        paymentInfo.cardPaymentInfo = {
            cvvToken: selectedPaymentMethod.cvv,
        };
    }

    if (result) {
        if (result instanceof Error) throw result;

        const { paymentToken, additionalFraudProtectionData } = result;

        if (isDevicePaymentMethod(selectedPaymentMethod)) {
            paymentInfo.devicePaymentInfo = {
                originalToken: paymentInfo.paymentToken,
            };

            paymentInfo.paymentToken = paymentToken;
        } else {
            // payment result is from a 3DS card verification
            paymentInfo.threeDSecurePaymentInfo = {
                paymentToken,
            };
        }

        paymentInfo.additionalFraudProtectionData = additionalFraudProtectionData;
    }

    const { paymentBehaviour } = paymentGatewayBehaviours[paymentInfo.paymentGateway];

    if (paymentBehaviour?.paymentInfoVisitor) {
        await paymentBehaviour.paymentInfoVisitor(dispatch, getState, paymentInfo);
    }

    return paymentInfo;
};

export async function handleExternalPayment(
    dispatch: AppDispatch,
    getState: () => AppState,
    paymentInfo: PaymentInfo,
    party: Party | null
) {
    if (paymentInfo.paymentGateway === NotRequired) return false;

    const { paymentBehaviour } = paymentGatewayBehaviours[paymentInfo.paymentGateway];

    if (!paymentBehaviour?.handleExternalPayment || party?.isDemo) return false;

    const beginPaymentResult = await orderApi.invoke<BeginPaymentResult>("beginPayment", paymentInfo);

    paymentInfo.externalPaymentInfo = {
        paymentId: beginPaymentResult.paymentId,
    };

    const externalPaymentResult = await paymentBehaviour.handleExternalPayment(dispatch, getState, beginPaymentResult);

    // Used to test scenarios where a Stripe payment completes but the connection
    // is interrupted before payAndSubmitOrderInSequence can be called
    if ("debugDelayExternalPayment" in window && window["debugDelayExternalPayment"] === true) {
        window.alert("External payment complete");
    }

    if (externalPaymentResult) {
        paymentInfo.externalPaymentInfo = {
            ...paymentInfo.externalPaymentInfo,
            ...externalPaymentResult,
        };
    } else {
        paymentInfo.externalPaymentInfo.error = {
            code: "app_error",
            isConsumerFriendly: false,
        };
    }

    return true;
}

function getZeroPaymentInfo(getState: () => AppState): PaymentInfo {
    return {
        paymentToken: NotRequired,
        paymentGateway: NotRequired,
        includedMembers: [getCurrentMemberId(getState())],
        tip: 0,
    };
}

export const handlePayment: PaymentFunc = (result, completePayment, validate) => {
    return submitPaymentAndOrderOperation.getThunk(async (dispatch: AppDispatch, getState: () => AppState) => {
        try {
            const state = getState();
            const party = getParty(state)!;
            const isOpeningOrPayingWithTab = getIsOpeningOrPayingWithTab(state);
            const orderHeadCountEnabled = getIsOrderHeadCountEnabled(state);

            if (!isOpeningOrPayingWithTab) {
                trackTip(state, dispatch);
            }

            dispatch(showModalMessage(modalMessages.orderSending()));

            result = await validate(result);

            dispatch(partyActionCreators.overrideActiveOrderStatus(OrderStatus.SUBMITTING));

            if (window.navigator.onLine === false) {
                dispatch(partyActionCreators.overrideActiveOrderStatus(OrderStatus.SUBMITFAILED));
                throw new HandledError("Offline");
            }

            if (orderHeadCountEnabled) {
                await patchOrderMetadata(dispatch, getState);
            }

            const getPaymentInfoLocal = async () => {
                try {
                    const state = getState();
                    const total = getPaymentTotal(state);
                    const isAllowedZeroPayment = getIsAllowedZeroPayment(state);
                    const membershipPointsSpendTotal = getMembershipPointsSpendTotal(state);

                    const paymentInfo =
                        total === 0 && isAllowedZeroPayment
                            ? getZeroPaymentInfo(getState)
                            : await getPaymentInfo(dispatch, getState, result);

                    if (membershipPointsSpendTotal > 0) {
                        paymentInfo.membershipPaymentInfo = {
                            pointsSpendAmount: membershipPointsSpendTotal,
                        };
                    }

                    return paymentInfo;
                } catch (err) {
                    dispatch(partyActionCreators.overrideActiveOrderStatus(OrderStatus.PAYMENTFAILED));
                    throw err;
                }
            };

            let paymentInfo = await getPaymentInfoLocal();

            const isExternalPayment = await handleExternalPayment(dispatch, getState, paymentInfo, party);

            // We only want to retry 'external' payments (where payment completes _before_
            // payAndSubmitOrderInSequence) otherwise we might create multiple payments
            const invokePayAndSubmitOrder = () =>
                isExternalPayment
                    ? orderApi.invokeWithRetry(
                          invokeConnectionRetry(10),
                          "payAndSubmitOrderInSequence",
                          party.id,
                          paymentInfo,
                          party.sequenceId
                      )
                    : orderApi.invoke("payAndSubmitOrderInSequence", party.id, paymentInfo, party.sequenceId);

            try {
                await invokePayAndSubmitOrder();
            } catch (err) {
                if (!(err instanceof MustUseExtendedValidationError)) throw err;

                result = await validate(result, true);
                paymentInfo = await getPaymentInfoLocal();
                await invokePayAndSubmitOrder();
            }
        } catch (err) {
            if (err instanceof InvalidPromotionError) {
                dispatch(showModalMessage(modalMessages.promotionNoLongerValid()));
            } else if (err instanceof ReviewOrderError) {
                dispatch(showReviewOrderMessage(err.reasons));
            } else if (err instanceof MembershipInsufficientBalanceError) {
                dispatch(showMembershipInsufficientBalance());
            }
            throw err;
        } finally {
            await completePayment?.();
        }
    });
};

export function trackTip(state: AppState, dispatch: AppDispatch) {
    const customTipOn = !!getCustomTip(state);
    const tipAmount = getPaymentTipAmount(state);
    const tipPercentage = getPaymentTipPercentage(state) * 100;
    const tipType = getTipType(state);

    dispatch(paymentActionCreators.trackTipSelected(tipAmount, tipPercentage, customTipOn, tipType));
}

const showMembershipInsufficientBalance = () => {
    return (dispatch: AppDispatch, getState: () => AppState) => {
        const party = getParty(getState());
        if (party) {
            membershipActions.fetchActive(party.restaurantId, dispatch).catch();
        }
        dispatch(showModalMessage(modalMessages.insufficientPointsSpendBalance()));
    };
};
