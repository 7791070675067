import { AppDispatch } from "../../index";
import { NewCardPaymentMethod } from "../../../common/payment";
import { getAddCardAvailableOperation } from "../operations";
import { paymentGatewayBehaviours } from "../../paymentGateways";

export const getAddCardAvailable = (dispatch: AppDispatch, { paymentGateway }: NewCardPaymentMethod) =>
    getAddCardAvailableOperation.invoke(
        () => Promise.resolve(!!paymentGatewayBehaviours[paymentGateway].addCardBehaviour),
        dispatch
    );
