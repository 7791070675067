import "../assets/CategoryCard.scss";

import * as React from "react";
import { Category } from "../../menudata";
import { MenuItemImage } from "../../menuitem/components/MenuItemImage";
import { SpecialIcon } from "../../../sharedComponents/assets/icons";
import { Text } from "src/sharedComponents";
import { WaitTimeBadge } from "./WaitTimeBadge";

export interface Props {
    category: Category;
}

export const CategoryCard = ({ category }: Props) => (
    <div className={`category-card ${category.special ? "category-card--specials" : ""}`}>
        <div className="img-div">
            <WaitTimeBadge waitTime={category.waitTime} />
            <MenuItemImage className="catcard__thumb" images={category.images} name="thumb" />
        </div>
        <Text preset="g-18" mode="bold" className="text-div">
            {category.special && <SpecialIcon />}
            {category.displayName}
        </Text>
    </div>
);
