import {
    ApplePayPaymentMethod,
    getStripe,
    GooglePayPaymentMethod,
    paymentOptions,
    PaymentType,
} from "src/common/payment";
import { StripeApplePayPaymentMethod, StripeGooglePayPaymentMethod } from "src/features/paymentGateways/stripe/types";
import { canMakePayment } from "./getStripeDevicePaymentAvailable";
import { InitializeDevicePaymentMethodButtonActions, VaultDevicePaymentMethodFunc } from "../../../interface";
import { customGooglePayButtonId } from "src/common/experience/web/paymentGateways/stripe/index";
import { hideKeyboard } from "src/common/keyboard";

const vaultStripeDevicePaymentMethod = async (
    paymentMethod: StripeApplePayPaymentMethod | StripeGooglePayPaymentMethod,
    { getPaymentSessionToken, spinnerActions }: InitializeDevicePaymentMethodButtonActions,
    cancelPromise: Promise<void>
) => {
    const { apiKey, countryCode, currency } = paymentMethod;
    const stripe = await getStripe(apiKey);

    let showObserver: MutationObserver | undefined;
    let cancelled = false;

    if (paymentMethod.paymentType === PaymentType.GOOGLEPAY) {
        const buttonContainer = document.getElementById(customGooglePayButtonId)!;

        showObserver = new MutationObserver(() => {
            if (!buttonContainer.classList.contains(`${customGooglePayButtonId}--focus`)) return;
            if (cancelled) {
                hideKeyboard(); // If cancel was called prior to this then just unfocus the button
                cancelled = false;
                return;
            }
            spinnerActions.start();
        });

        showObserver.observe(buttonContainer, {
            attributes: true,
            attributeFilter: ["class"],
        });

        cancelPromise.then(() => showObserver?.disconnect());
    }

    return new Promise<string | null>(async (resolve, reject) => {
        try {
            const paymentRequest = stripe.paymentRequest({
                country: countryCode.toUpperCase(),
                currency: currency.toLowerCase(),
                total: {
                    label: paymentOptions.label,
                    amount: 0,
                    pending: true,
                },
            });

            paymentRequest.on("paymentmethod", async ({ paymentMethod: { id }, complete }) => {
                complete("success");
                try {
                    const sessionToken = await getPaymentSessionToken();
                    const result = await stripe.confirmCardSetup(sessionToken, { payment_method: id });

                    if (result.error) {
                        throw new Error(result.error.message);
                    }

                    if (!result.setupIntent.payment_method) {
                        throw new Error("No payment method");
                    }

                    resolve(result.setupIntent.payment_method);
                } catch (err) {
                    reject(err);
                } finally {
                    if (paymentMethod.paymentType === PaymentType.GOOGLEPAY) {
                        spinnerActions.stop();
                        showObserver?.disconnect();
                    }
                }
            });

            paymentRequest.on("cancel", () => {
                if (paymentMethod.paymentType === PaymentType.GOOGLEPAY) {
                    spinnerActions.stop();
                    cancelled = true;
                    return;
                }
                resolve(null);
            });

            const { available } = await canMakePayment(paymentMethod, paymentRequest);

            if (!available) {
                throw new Error("Can't make payment");
            }

            if (paymentMethod.paymentType === PaymentType.APPLEPAY) {
                paymentRequest.show();
                return;
            }

            // paymentRequest.show(); no longer works for Google Pay for some reason
            // but using the paymentRequestButton from elements does
            const paymentRequestButton = stripe.elements().create("paymentRequestButton", {
                paymentRequest,
                style: {
                    paymentRequestButton: {
                        height: "54px",
                    },
                },
                classes: {
                    focus: `${customGooglePayButtonId}--focus`,
                },
            });

            paymentRequestButton.mount(`#${customGooglePayButtonId}`);
        } catch (err) {
            reject(err);
        }
    });
};

export const vaultStripeApplePayPaymentMethod: VaultDevicePaymentMethodFunc<ApplePayPaymentMethod> = (
    applePayPaymentMethod,
    actions: InitializeDevicePaymentMethodButtonActions,
    cancelPromise: Promise<void>
) => vaultStripeDevicePaymentMethod(applePayPaymentMethod as StripeApplePayPaymentMethod, actions, cancelPromise);

export const vaultStripeGooglePayPaymentMethod: VaultDevicePaymentMethodFunc<GooglePayPaymentMethod> = (
    googlePayPaymentMethod,
    actions: InitializeDevicePaymentMethodButtonActions,
    cancelPromise: Promise<void>
) => vaultStripeDevicePaymentMethod(googlePayPaymentMethod as StripeGooglePayPaymentMethod, actions, cancelPromise);
