import { showModalMessage } from "../../modalMessage/actions/show";
import { modalMessages } from "../../modalMessage/messages";
import { getSelectedPaymentMethod } from "src/features/payment/selectors";
import { AppDispatch, AppState } from "src/features";
import { orderApi } from "src/features/order/orderApi";
import { saveSecuredPaymentMethodOperation } from "../operations/saveSecuredPaymentMethod";
import { SecuredPaymentInfo } from "../types";

export const saveSecuredPaymentMethod = () => {
    return saveSecuredPaymentMethodOperation.getThunk(async (dispatch: AppDispatch, getState: () => AppState) => {
        const errorMessage = "cannot save securedPaymentMethod because it's null";
        try {
            const state = getState();
            const paymentMethod = getSelectedPaymentMethod(state)!;

            if (!paymentMethod) {
                throw new Error(errorMessage);
            }

            const securedPaymentInfo: SecuredPaymentInfo = {
                gateway: paymentMethod.paymentGateway,
                token: paymentMethod.token,
            };
            await orderApi.invoke("saveSecuredPaymentMethod", securedPaymentInfo);
        } catch (e) {
            // TODO: LION-1066 Use more specific message on error for payment security
            dispatch(showModalMessage(modalMessages.failedToSubmit()));

            throw e; // For app insights
        }
    });
};
