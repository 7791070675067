import { loadExternalScript, timeoutAndError } from "../../../../common/shared";
import { AddedCardResult, GetAddedCardFunc, ProductFees } from "../../../payment";
import { AddedCardPaymentMethod, PaymentType } from "../../../../common/payment";
import { registerKeyboardClosed, registerKeyboardOpen } from "../../../../common/keyboard";
import { HostedField, hostedFieldOptions } from "../../../paymentMethods/types";
import moment from "moment";
import { getCardDisplayName } from "../../../payment/util/getCardDisplayName";
import { fetchProductFees } from "../../../payment/api/fetchProductFees";
import { fetchPaymentSessionToken } from "../../../payment/api/fetchPaymentSessionToken";
import {
    TyroHostedField,
    TyroNewCardPaymentMethod,
    TyroPaymentSessionInitializedResponse,
    TyroPaymentSessionUpdateResponse,
} from "../types";
import {
    getTyroAddedCardForPaymentOperation,
    getTyroAddedCardOperation,
    showTyroAddCardOperation,
} from "../operations";
import { ShowAddCardFunc } from "../../types";
import { hostedFieldsActionCreators } from "../../../paymentMethods";

const getHostedField = (selector: string) => selector.replace(/^#/, "");

const allFields = [
    TyroHostedField.Number,
    TyroHostedField.ExpiryMonth,
    TyroHostedField.ExpiryYear,
    TyroHostedField.CVV,
];

let scopeId = 0;

export const showTyroAddCard: ShowAddCardFunc = (dispatch, newCardPaymentMethod, inParty) => {
    return showTyroAddCardOperation.invoke(async () => {
        let innerResolve: (result: AddedCardResult | null) => void = () => {};
        let innerReject: (err: Error) => void = () => {};
        let innerIsPayment = false;

        const { paymentGateway, baseUrl, apiVersion, merchantId } = newCardPaymentMethod as TyroNewCardPaymentMethod;

        const sessionId = await fetchPaymentSessionToken(dispatch, paymentGateway, inParty);

        if (!sessionId) {
            throw new Error("No session ID");
        }

        const initializeHostedFields = () =>
            new Promise<any>(async (resolve, reject) => {
                const PaymentSession = await loadExternalScript(
                    "PaymentSession",
                    `${baseUrl}/form/version/${apiVersion}/merchant/${merchantId}/session.js`
                );

                PaymentSession.configure(
                    {
                        sessionId,
                        fields: {
                            card: {
                                number: `#${HostedField.Number}`,
                                expiryMonth: `#${HostedField.ExpiryMonth}`,
                                expiryYear: `#${HostedField.ExpiryYear}`,
                                securityCode: `#${HostedField.CVV}`,
                            },
                        },
                        frameEmbeddingMitigation: ["x-frame-options"],
                        callbacks: {
                            initialized: (response: TyroPaymentSessionInitializedResponse) => {
                                switch (response.status) {
                                    case "ok":
                                        resolve(PaymentSession);
                                        break;
                                    default:
                                        reject(new Error(response.message));
                                }
                            },
                            formSessionUpdate: async (response: TyroPaymentSessionUpdateResponse) => {
                                switch (response.status) {
                                    case "ok":
                                        const {
                                            session: { id: token },
                                            sourceOfFunds: {
                                                provided: {
                                                    card: {
                                                        scheme,
                                                        fundingMethod,
                                                        number,
                                                        securityCode,
                                                        expiry: { month, year },
                                                    },
                                                },
                                            },
                                        } = response;

                                        if (cvvEmpty || !securityCode) {
                                            // MPGS treats an empty CVV as valid so we have to check whether it is in the session ourselves
                                            // If a CVV has been previously entered but then deleted it is still retained in the session so
                                            // we need to check for CVV emptiness locally too
                                            innerResolve(null);
                                            return;
                                        }

                                        // moment YYYY handles both 2 and 4-digit years
                                        const expiry = moment(`${month}-${year}`, "MM-YYYY").endOf("month");
                                        const now = moment();

                                        if (expiry.isBefore(now)) {
                                            // MPGS don't validate whether the expiry date is in the past so we have to do it ourselves
                                            innerResolve(null);
                                            return;
                                        }

                                        // Convert scheme/fundingMethod to the me&u internal product id so we can retrieve processing fees
                                        let productId = scheme;
                                        if (fundingMethod?.toUpperCase() === "DEBIT") {
                                            productId += "-DEBIT";
                                        }

                                        const lastFour = number.substring(number.length - 4);
                                        const displayName = getCardDisplayName(scheme);
                                        const verificationMinAmount =
                                            newCardPaymentMethod.cardTypeVerificationMinAmounts?.[displayName] ??
                                            newCardPaymentMethod.verificationMinAmount;

                                        let productFees: ProductFees | undefined;
                                        if (innerIsPayment) {
                                            productFees = await fetchProductFees(
                                                dispatch,
                                                newCardPaymentMethod.paymentGateway,
                                                productId,
                                                newCardPaymentMethod.currency,
                                                null,
                                                inParty
                                            );
                                        }

                                        const paymentMethod: AddedCardPaymentMethod = {
                                            ...newCardPaymentMethod,
                                            paymentType: PaymentType.ADDEDCARD,
                                            displayName,
                                            token,
                                            maskedNumber: `•••• ${lastFour}`,
                                            expirationDate: expiry.format("MM/YY"),
                                            productId,
                                            processingFee: productFees?.feePercentage ?? 0,
                                            processingFeeBaseAmount: productFees?.feeBaseAmount ?? 0,
                                            remember: false,
                                            verificationMinAmount,
                                        };

                                        innerResolve({ paymentMethod });
                                        break;
                                    case "fields_in_error":
                                        innerResolve(null);
                                        break;
                                    case "request_timeout":
                                        innerReject(
                                            new Error(
                                                "Session update failed with request timeout: " + response.errors.message
                                            )
                                        );
                                        break;
                                    case "system_error":
                                        innerReject(
                                            new Error(
                                                "Session update failed with system error: " + response.errors.message
                                            )
                                        );
                                        break;
                                    default:
                                        innerReject(
                                            new Error(
                                                "Session update failed with unknown error: " + JSON.stringify(response)
                                            )
                                        );
                                        break;
                                }
                            },
                        },
                        interaction: {
                            displayControl: {
                                formatCard: "EMBOSSED", // Auto-format card number
                                invalidFieldCharacters: "REJECT", // Don't allow invalid characters to be entered
                            },
                        },
                    },
                    ++scopeId
                );

                PaymentSession.setPlaceholderStyle(
                    allFields,
                    {
                        color: "#999999",
                    },
                    scopeId
                );

                PaymentSession.onFocus(allFields, registerKeyboardOpen, scopeId);

                PaymentSession.onBlur(allFields, registerKeyboardClosed, scopeId); // Doesn't trigger in Chrome device emulator

                let cvvEmpty = true;

                PaymentSession.onEmptinessChange(
                    allFields,
                    (selector: string, { isEmpty }: { isEmpty: boolean }) => {
                        const field = getHostedField(selector);

                        switch (field) {
                            case HostedField.CVV:
                                cvvEmpty = isEmpty;
                                break;
                            case HostedField.ExpiryMonth:
                            case HostedField.ExpiryYear:
                                dispatch(hostedFieldsActionCreators.setEmpty(field, isEmpty));
                                break;
                        }
                    },
                    scopeId
                );

                PaymentSession.onCardTypeChange((_: any, { status, scheme }: { status: string; scheme: string }) => {
                    if (status === "SUPPORTED") {
                        // Changing placeholder doesn't update until we click into the field, but it's better than nothing
                        document
                            .getElementById(HostedField.CVV)
                            ?.setAttribute(
                                "placeholder",
                                scheme === "AMEX"
                                    ? hostedFieldOptions.placeholders.cvvAmex
                                    : hostedFieldOptions.placeholders.cvv
                            );
                    }
                }, scopeId);
            });

        const PaymentSession = await Promise.race([
            initializeHostedFields(),
            timeoutAndError(hostedFieldOptions.initializeTimeout, "Timed out initializing Tyro hosted fields"),
        ]);

        if (!PaymentSession) {
            throw new Error("Failed to initialize Tyro Hosted Fields");
        }

        /*
        {
          "session": {
            "id": "...",
            "updateStatus": "SUCCESS",
            "version": "cf47f31302"
          },
          "sourceOfFunds": {
            "provided": {
              "card": {
                "brand": "VISA",
                "expiry": {
                  "month": "1",
                  "year": "23"
                },
                "fundingMethod": "CREDIT",
                "number": "411111xxxxxx1111",
                "scheme": "VISA",
                "securityCode": "xxx"
              }
            },
            "type": "CARD"
          },
          "status": "ok",
          "version": "58"
        }
        */

        const getAddedCard: GetAddedCardFunc = (isPayment: boolean) =>
            (isPayment ? getTyroAddedCardForPaymentOperation : getTyroAddedCardOperation).invoke(
                () =>
                    new Promise<AddedCardResult | null>((resolve, reject) => {
                        innerResolve = resolve;
                        innerReject = reject;
                        innerIsPayment = isPayment;
                        PaymentSession.updateSessionFromForm("card", undefined, scopeId);
                    }),
                dispatch
            );

        // Set all fields to valid for Tyro as we can't use real-time validation
        dispatch(hostedFieldsActionCreators.setValid(HostedField.Number, true));
        dispatch(hostedFieldsActionCreators.setValid(HostedField.ExpiryMonth, true));
        dispatch(hostedFieldsActionCreators.setValid(HostedField.ExpiryYear, true));
        dispatch(hostedFieldsActionCreators.setValid(HostedField.CVV, true));

        return getAddedCard;
    }, dispatch);
};
